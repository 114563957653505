import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'


import wiseEstimateWatermark from '@/assets/img/logo/wiseEstimateWatermark.png'

export default {
	data() {
		return {


		}
	},
	mounted() {


	},
	methods: {
		addPageWatermark(el, data) { //admin  1:后台管理打开无水印可下载
			if (data == 1 && this.$route.query.admin != 1) {
				$(el).append('<div class="watermark"><img src="' + wiseEstimateWatermark + '" alt="水印" /></div>')
			}

		}


	}

}