<template>
    <!-- 总结页咨询框组件 -->
    <div>
        <div class="anomalyIndex">
            <div class="anomalyIndexTitle">
                <div>
                    <img src="../assets/img/summaryPages/zhibiao-3.svg" alt="" class="icon_2">
                    <p>主要异常指标</p>
                </div>
                <ul>
                    <li v-for="(item,index) in pageInfo.questionArray" :key="index + 1">{{formatteIndex(item.softName)}}</li>
                </ul>
            </div>
            <div class="anomalyIndexInfo">
                <div class="anomalyIndexInfoTitle">
                    <img src="../assets/img/summaryPages/zhibiaochaxun.svg" alt="" class="icon_3">
                    <p>异常指标解读</p>
                </div>
                <div class="anomalyIndexInfoCont">
                    <div class="anomalyIndexInfoItem"  v-for="(item,index) in pageInfo.questionArray" :key="index + 2">
                        <div class="anomalyIndexInfoItemTitle">
                            <div>{{item.softName}}</div>
                            <p>企业目前<span class="industryName">{{item.softName}}</span>低于<span class="industryRank">{{Number(item.proportion).toFixed(2) + '%'}}</span>同行</p>
                        </div>
                        <ul class="anomalyIndexInfoItemCont">
                            <li>
                                <p class="causes">原因</p>
                                <p class="causesDes">{{item.questionContent.split("为")[0] + item.content.substring(2) + "。" + item.description}}</p>
                                <p class="suggestion" v-if="item.type == 1">相关建议</p>
                                <p class="suggestionDes" v-if="item.type == 1">{{item.suggestion}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- 查看完整报告 -->
        <div class="toReportBox">
            <div class="content">
                <div class="toReport" @click="toReport()">查看完整报告</div>
            </div>
        </div>
        <div class="immediatelyConsult">
            <div class="immediatelyConsultTitle">
                <img src="../assets/img/summaryPages/liyonggongdifangan.svg" alt="" class="icon_4">
                <p>解决方案</p>
            </div>
            <div>
                <div class="consulting">
                    <p>心流专家咨询服务</p>
                    <p>为企业走进资本市场提供定制化解决方案</p>
                    <ul>
                        <li v-for="(item,index) in itemsInfo" :key="index">
                            <img :src="item.checkStatus ? require('../assets/img/summaryPages/fuxuankuang.svg') : require('../assets/img/summaryPages/fuxuankuang2.svg')" alt="" @click="item.checkStatus = !item.checkStatus">
                            <div>
                                <h6>{{item.itemText}}</h6>
                            </div>
                        </li>
                    </ul>
                </div>
                <img src="../assets/img/specialization/immediatelyConsult.jpg" class="consultFigure" alt="">
            </div>
            <div class="consultBtn">
                <Button id="consultBtn" @click="instance()">立即咨询</Button>
            </div>
            
        </div>
        <Modal v-model="modalStatus"><img :src="modalSrc" alt=""><p v-text="modalText"></p></Modal>
    </div>
</template>
<script>
import qs from 'qs'
export default {
    props:[
        'pageInfo', 'type', 'versions', 'reportId'
    ],
    name:"consultationService",
    data() {
        return {
            itemsInfo: [
                {
                    itemText:"1、交易估值：标的股权估值，交易合理定价",
                    checkStatus:false
                },
                {
                    itemText:"2、财务规范：合规框架下的财务体系建设及流程优化",
                    checkStatus:false
                },
                {
                    itemText:"3、融资顾问：融资方案总体规划及解决方案",
                    checkStatus:false
                },
                {
                    itemText:"4、上市辅导：提供上市前辅导服务及相关资本运作服务",
                    checkStatus:false
                },
                {
                    itemText:"5、股权设计：VIE结构搭建及拆分、股权结构与股权激励",
                    checkStatus:false
                },
                {
                    itemText:"6、并购重组：提供并购重组业务全方位的资本运作咨询服务",
                    checkStatus:false
                }
            ],
            modalStatus:false,
            modalText: "",
            modalSrc: "",
        }
    },
    mounted(){
        console.log(this.pageInfo);
        console.log(this)
    },
    methods:{
        toReport: function(){
            var that = this;
            if(that.type == 2){
                // 价值量化
                that.$router.push({
                    name:"valuationReport",
                    path:"/valuationReport",
                    query:{
                        reportId: that.reportId
                    }
                })
            } else if (that.type == 7){
                // 价值诊断
                if(that.versions == 2){
                    that.$router.push({
                        name:"diagnoseReport2v",
                        path:"/diagnoseReport2v",
                        query: {
                            reportId: that.reportId
                        }
                    })
                } else {
                    that.$router.push({
                        name:"diagnoseReport",
                        path:"/diagnoseReport",
                        query: {
                            reportId: that.reportId
                        }
                    })
                }
            } else if (that.type == 5){
                // 价值追踪
                that.$router.push({
                    name:"traceValuationReport",
                    path:"/traceValuationReport",
                    query: {
                        reportId: that.reportId
                    }
                })
            }
        },
        formatteIndex: function(str){
            var index = "";
            switch (str) {
                case "偿债能力":
                    index = "偿债能力较低";
                    break;
                case "盈利能力":
                    index = "盈利能力较低";
                    break;
                case "成长能力":
                    index = "成长能力较低";
                    break;
                case "营运能力":
                    index = "营运能力较低";
                    break;
                case "专业化（主营业务专业专注）":
                    index = "专业化得分较低";
                    break;
                case "精细化（经营管理精细高效）":
                    index = "精细化得分较低";
                    break;
                case "特色化（产品服务独具特色）":
                    index = "特色化得分较低";
                    break;
                case "新颖化（创新能力成果显著）":
                    index = "新颖化得分较低";
                    break;
            }
            return index;
        },
        instance: function() {
            // const title = '对话框的标题';
            // const content = ;
            var checkStr = "";
            this.itemsInfo.map((item) => {
                if(item.checkStatus) {
                    checkStr += item.itemText;
                }
            })
            if(checkStr == ""){
                this.modalStatus = true;
                this.modalText = "未选择解决方案";
                this.modalSrc = require("../assets/img/error@2x.png");
                // this.$Modal.error({
                //     title: "",
                //     content: '<img src="img/error@2x.png" alt="" style="width:30px;height:30px;"><p>未选择解决方案</p>'
                // })
                return;
            }
            var params = {
                reportId: this.reportId,
                advisory: checkStr,
                phoneNum: localStorage.userName,
                reportType: this.$route.reportType ? this.$route.reportType : "0",
                reportStage: 7,
                channel: sessionStorage.channel ? sessionStorage.channel : "0000"
            }
            this.$post("flow/consult/insertConsult",qs.stringify(params)).then((res) => {
                console.log(res);
                var data = res.data;
                if(data.code == 200){
                    this.modalStatus = true;
                    this.modalText = "您的需求提交成功！心流慧估的工作人员将在1个工作日内和您联系。";
                    this.modalSrc = require("../assets/img/success@2x.png");
                    // this.$Modal.success({
                    //     title: "",
                    //     content: '<img src="require(../assets/img/success@2x.png)" alt="" style="width:30px;height:30px;"><p>您的需求提交成功！心流慧估的工作人员将在1个工作日内和您联系。</p>'
                    // })
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
    .anomalyIndexInfoTitle,
    .anomalyIndexTitle,
    .flowDiagnoseTitle,
    .financialAnalysisTitle,
    .synthesisScoreTitle {
        margin: 34px 0 29px 28px;
    }
    .anomalyIndexInfoTitle img,
    .anomalyIndexTitle img,
    .flowDiagnoseTitle img,
    .financialAnalysisTitle img,
    .synthesisScoreTitle img {
        width: 28px;
        height: 34px;
        margin-right: 17px;
        float: left;
    }
    .anomalyIndexInfoTitle p,
    .anomalyIndexTitle p,
    .flowDiagnoseTitle p,
    .financialAnalysisTitle p,
    .synthesisScoreTitle p {
        font-size: 22px;
        font-weight: 400;
        color: #282828;
        line-height: 34px;
    }
    .anomalyIndex {
        width: 100%;
        box-shadow: 0 0 24px -10px #6f6f6f;
        overflow: hidden;
        margin-bottom: 32px;
    }

    .anomalyIndexTitle p {
        display: inline-block;
    }

    .anomalyIndexTitle>div {
        float: left;
        margin-right: 60px;
    }

    .anomalyIndexTitle ul {
        overflow: hidden;
    }

    .anomalyIndexTitle ul li {
        width: 222px;
        height: 36px;
        border: 1px solid #FF0000;
        border-radius: 18px;
        float: left;
        margin-right: 46px;
        font-size: 14px;
        font-weight: 400;
        color: #FF0000;
        line-height: 36px;
        text-align: center;
    }

    .anomalyIndexTitle ul li:nth-child(3) {
        border: 1px solid #FF7E00;
        color: #FF7E00;
    }

    .anomalyIndexInfoItem {
        margin: 0 0 26px 25px;
    }

    .anomalyIndexInfoItemTitle {
        width: calc(100% - 15px);
        height: 51px;
        background: #E7F0FF;
        /* margin-left: 25px; */
    }

    .anomalyIndexInfoItemTitle>div {
        width: 265px;
        height: 51px;
        background: #005BFF;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 51px;
        text-align: center;
        float: left;
        margin-right: 20px;
    }

    .anomalyIndexInfoItemTitle p {
        font-size: 16px;
        font-weight: 400;
        color: #0478FC;
        line-height: 51px;
    }

    .anomalyIndexInfoItemTitle p span {
        margin-top: -4px;
    }

    .anomalyIndexInfoItemTitle p span.industryRank {
        color: #FC3F3F;
    }

    .anomalyIndexInfoItemCont li p {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
        padding: 0 15px 0 0;
    }

    .anomalyIndexInfoItemCont li .causes {
        padding: 0;
        width: 50px;
        height: 24px;
        background: #E7F0FF;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #0478fc;
        line-height: 24px;
        text-align: center;
        margin: 20px 0 12px;
    }

    .anomalyIndexInfoItemCont li .suggestion {
        padding: 0;
        width: 74px;
        height: 24px;
        background: #FFE6B0;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #FF6600;
        line-height: 24px;
        text-align: center;
        margin: 14px 0;
    }
    .immediatelyConsult {
        width: 100%;
        box-shadow: 0 0 24px -10px #6f6f6f;
        overflow: hidden;
        margin-bottom: 40px;
        padding-bottom: 24px;
    }

    .immediatelyConsult>p {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 48px;
        margin: 23px 0 0 32px;
    }
    .immediatelyConsult ul {
        margin-left: 26px;
    }

    .immediatelyConsult ul {
        margin-top: 20px;
    }

    .immediatelyConsult ul li img {
        width: 30PX;
        height: 27px;
        float: left;
        margin-right: 10px;
        cursor: pointer;
        margin-top: -2PX;
    }

    .immediatelyConsult ul li h6 {
        font-size: 16PX;
        font-weight: 400;
        color: #282828;
        margin: 0 0 12px 0;
    }

    .immediatelyConsult ul li p {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        text-indent: 65px;
    }
    .immediatelyConsultTitle {
        margin: 24px 0 24px 26px;
    }

    .immediatelyConsultTitle img{
        width: 28px;
        height: 34px;
        margin-right: 17px;
        float: left;
    }

    .immediatelyConsultTitle p {
        font-size: 22px;
        font-weight: 400;
        color: #282828;
        line-height: 34px;
    }
    .consulting>p {
        margin-left: 26px;
    }
    .immediatelyConsult>div {
        overflow: hidden;
    }

    .consulting {
        width: 60%;
        float: left;
    }

    .consulting>p:nth-of-type(1) {
        font-size: 32px;
        font-weight: bold;
        color: #282828;
        /* margin-top: 26px; */
        margin-bottom: 10px;
    }

    .consulting>p:nth-of-type(2) {
        font-size: 20px;
        font-weight: 400;
        color: #282828;
    }
    .consultBtn {
        margin: 0 auto;
        width: 365px;
    }
    #consultBtn {
        width: 365px;
        height: 50px;   
        background: #005BFF;
        border-radius: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        margin: 30px auto 0;
        cursor: pointer;
    }
    /deep/ #consultBtn span {
        margin-top: -12px;
        line-height: 50px;
    }
    .toReportBox .toReport {
        width: 500px;
        background: #005BFF;
        height: 50px;
        margin: 0 auto 32px;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        cursor: pointer;
        border-radius: 25px;
    }
    /deep/ .ivu-modal-body {
        text-align: center;
        font-size: 14px;
    }
    /deep/ .ivu-modal-body img{
        margin: 20px auto;
    }
    /deep/ .ivu-btn-text {
        border-color: #515a6e;
    }
    @media screen and (max-width:765px) {
        .anomalyIndex {
            box-shadow: none;
        }
        .anomalyIndexInfoTitle,
        .anomalyIndexTitle {
            margin-left: 0;
        }
        .anomalyIndexTitle>div {
            float: inherit;
        }
        .anomalyIndexTitle ul li {
            float: left;
            width: 45%;
            height: 40px;
            line-height: 40px;
            margin:20px 5% 10px 0;
        }
        .anomalyIndexInfoItem {
            margin-left: 0;
        }
        .anomalyIndexInfoItemTitle>div {
            width: 180px;
        }
        .anomalyIndexInfoItemTitle p span {
            margin-top: -5px;
        }
        .anomalyIndexInfoItemCont li .causes {
            width: 80px;
            height: 36px;
            line-height: 36px;
            border-radius: 8px;
        }
        .immediatelyConsult {
            box-shadow: none;
        }
        .consulting {
            width: 100%;
        }
        .immediatelyConsultTitle  {
            font-size: 14PX;
            margin-left: 0;
        }
        .consulting>p:nth-of-type(1) {
            font-size: 14PX;
            text-align: center;
            margin-left: 0;
        }
        .immediatelyConsult ul li h6 {
            font-size: 14PX;
            text-align: justify;
            margin-left: 24px;
        }
        .immediatelyConsult ul li img {
            margin: 3px 10px 0 0;
            width: auto;
        }
        .consulting>p:nth-of-type(2),
        .immediatelyConsult ul {
            margin-left: 0;
        }
        .consultFigure {
            display: none;
        }
    }
</style>