<template>
    <div>
        <Header />
        <div class="content">
            <div id="title">
                <p>企业价值诊断</p>
                <div class="toReport" @click="toReportPage()">查看完整报告</div>
            </div>
            <div class="synthesisScore">
                <div class="synthesisScoreTitle">
                    <img src="../../assets/img/summaryPages/caiwutongji.svg" alt="">
                    <p>健康体检综合得分</p>
                </div>
                <div class="synthesisScoreCont">
                    <div class="score">
                        <img src="../../assets/img/specialization/specializationZongdefen.jpg" alt="">
                        <p class="synthesisTitle">健康体检综合得分</p>
                        <p class="synthesisScores" v-text="pageInfo.totalScore">0.00</p>
                        <p class="scoreRank"><span v-text="'高于' + pageInfo.queryQuestionProportion + '%'"></span>同行</p>
                    </div>
                    <div class="companyInfo">
                        <ul class="companyBaseInfo">
                            <li>
                                <span>企业名称：</span>
                                <span class="companyName" v-text="pageInfo.name"></span>
                            </li>
                            <li>
                                <span>所属行业：</span>
                                <span class="industry" v-text="pageInfo.industryName + '-' + pageInfo.industryChildName"></span>
                            </li>
                            <li>
                                <span>诊断时间：</span>
                                <span class="createDate" v-text="formatteDate(pageInfo.createTime)"></span>
                            </li>
                        </ul>
                        <p class="scoreCabinet">五大指标得分：</p>
                        <ul class="softScore">
                            <li v-for="(item,index) in pageInfo.question" :key="index">
                                <div>{{item.name}}</div>
                                <p>{{Number(item.value).toFixed(2)}}<span>分</span></p>
                            </li>
                            <li class="financialPro">
                                <div>财务指标</div>
                                <p style="margin-top: 26px;"><em v-text="Number(pageInfo.financialValue).toFixed(2)"></em><span>分</span></p>
                            </li>
                        </ul>
                        <!-- <div class="synthesisScores">00.00</div>
                        <p class="scoreRank">健康体检综合得分<span>高于0%</span>同行</p> -->
                    </div>
                </div>
            </div>
            <!-- 心流诊断图谱 + 财务分析 -->
            <div class="flowRanderPic">
                <div class="flowDiagnose">
                    <div class="flowDiagnoseTitle">
                        <img src="../../assets/img/summaryPages/bingtu.svg" alt="" class="icon_0">
                        <p>诊断图谱</p>
                    </div>
                    <DrawRadarCanvas2v :chartData="pageInfo.question" :id="'flowDiagnosePic'" v-if="pageInfo.name"/>
                </div>
                <!--  -->
                <div class="financialAnalysis">
                    <div class="financialAnalysisTitle">
                        <img src="../../assets/img/summaryPages/caiwuguanli.svg" alt="" class="icon_1">
                        <p>财务分析</p>
                    </div>
                    <DrawRadarCanvas2v :chartData="pageInfo.financial" :id="'financialAnalysisPic'" v-if="pageInfo.name"/>
                </div>
            </div>
            <!-- 异常指标 -->
            <ConsultationService :pageInfo="pageInfo" v-if="pageInfo.name" :reportId="reportId"  :type="type" />
        </div>
        <!-- <router-link/> -->
        <Footer />
    </div>
</template>
<script>
import ConsultationService from '../../common/consultationService.vue';
import DrawRadarCanvas2v from '../../common/echarts/drawRadarCanvas2v.vue';
import qs from 'qs'
import Header from '../../common/header/flow_header.vue'
import Footer from '../../common/footer/flow_footer.vue'
export default {
    name:"diagnoseSummary2v",
    data(){
        return {
             projectName:''
        }
    },
    components: {
        Header,
        Footer,
        ConsultationService,
        DrawRadarCanvas2v
    },
    mounted(){
        this.projectName=this.domain.diagnoseProject
        this.initPage();
    },
    data() {
        return {
            reportId:this.$route.query.reportId ? this.$route.query.reportId : "",
            type: this.$route.query.type ? this.$route.query.type : "7",
            versions: this.$route.query.versions ? this.$route.query.versions : "3",
            pageInfo:{},
            execute:false,
        }  
    },
    methods: {
        initPage: function(){
            var obj = {
                reportId: this.reportId
            }
            this.$post(this.projectName+"valuationReport/qryDiagnosticSummaryReport",qs.stringify(obj)).then((res) => {
                var data = res.data;
                if(data.code == 200 && data.content){
                    this.pageInfo = data.content;
                    this.execute = true;
                }
            })
        },
        formatteDate: function(date){
            if(date){
                var dateArr = date.split("-");
                var dateStr = dateArr[0] + "年" + dateArr[1] + "月" + dateArr[2] + "日";
                return dateStr;
            }
        },
        toReportPage: function(){
            // 价值量化
            this.$router.push({
                name:"diagnoseReport2v",
                path:"/diagnoseReport2v",
                query:{
                    reportId: this.reportId
                }
            })
        }
    }
}
</script>
<style scoped>
    .content {
        width: 1200px;
        margin: 0 auto;
    }

    #title {
        margin: 30px 0;
    }

    #title p {
        font-size: 26px;
        font-weight: 400;
        color: #282828;
        line-height: 40px;
        display: inline-block;
    }

    #title .toReport {
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: #005BFF;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        float: right;
        text-align: center;
    }

    .synthesisScore {
        width: 100%;
        border: 1px solid #fff;
        margin-bottom: 25px;
        box-shadow: 0 0 24px -10px #6f6f6f;
        padding-bottom: 20px;
    }

    .anomalyIndexInfoTitle,
    .anomalyIndexTitle,
    .flowDiagnoseTitle,
    .financialAnalysisTitle,
    .synthesisScoreTitle {
        margin: 34px 0 29px 28px;
    }
    .anomalyIndexInfoTitle img,
    .anomalyIndexTitle img,
    .flowDiagnoseTitle img,
    .financialAnalysisTitle img,
    .synthesisScoreTitle img {
        width: 28px;
        height: 34px;
        margin-right: 17px;
        float: left;
    }
    .anomalyIndexInfoTitle p,
    .anomalyIndexTitle p,
    .flowDiagnoseTitle p,
    .financialAnalysisTitle p,
    .synthesisScoreTitle p {
        font-size: 22px;
        font-weight: 400;
        color: #282828;
        line-height: 34px;
    }

    .synthesisScoreCont {
        overflow: hidden;
    }

    .synthesisScoreCont .score {
        float: left;
        position: relative;
    }

    .synthesisScoreCont .score img {
        margin: 0 79px 0 122px;
        width: 465px;
    }

    .synthesisScoreCont .score p.synthesisScores {
        position: absolute;
        top: 145px;
        left: 276px;
        width: 157px;
        height: 54px;
        font-size: 36px;
        font-weight: bold;
        color: #005BFF;
        text-align: center;
        line-height: 54px;
    }

    .synthesisScoreCont .score p.synthesisTitle {
        position: absolute;
        top: 112px;
        left: 280px;
        color: #282828;
        font-size: 19px;
        font-weight: bold;
    }

    .synthesisScoreCont .score p.scoreRank {
        position: absolute;
        top: 204px;
        left: 313px;
        color: #282828;
        font-size: 15px;
        font-weight: bold;
    }

    .synthesisScoreCont .score p.scoreRank span {
        color: #f00;
        font-weight: bold;
        margin-top: -4px;
    }

    .synthesisScoreCont .companyInfo .softScore li {
        border: 1px solid #a7caf2;
        width: 150px;
        float: left;
        margin: 0 20px 20px 0;
        border-radius: 3px;
        padding-bottom: 6px;
        padding-right: 16px;
        height: 100px;
    }

    .synthesisScoreCont .companyInfo .softScore li div {
        font-size: 15px;
        font-weight: bold;
        color: #282828;
        margin: 10px 0 4px 16px;
    }

    .synthesisScoreCont .companyInfo .softScore li p {
        color: #005BFF;
        font-size: 24px;
        font-weight: bold;
        margin: 0px 0 0 16px;
    }

    .synthesisScoreCont .companyInfo .softScore li p span {
        color: #005BFF;
        font-size: 12px;
        font-weight: bold;
        margin: 0px 0 0 1px;
    }
    .synthesisScoreCont .companyInfo .softScore li p em {
        font-weight: bold;
        margin-top: -3px;
    }

    .synthesisScoreCont .companyInfo {
        padding: 44px 0 0 0;
        width: calc(100% - 687px);
        float: left;
    }

    .synthesisScoreCont .companyInfo ul.companyBaseInfo>li span {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 100%;
        margin-bottom: 21px;
    }

    .synthesisScoreCont .companyInfo ul.companyBaseInfo>li:nth-child(1) span {
        font-size: 24px;
        font-weight: bold;
        color: #282828;
        line-height: 30px;
    }

    .synthesisScoreCont .companyInfo ul.companyBaseInfo>li span {
        vertical-align: top;
    }

    .companyName {
        width: 335px;
    }

    .industry {
        width: 394px;
        line-height: 20px !important;
    }

    .synthesisScoreCont .companyInfo .scoreCabinet {
        font-size: 18px;
        font-weight: bold;
        color: #282828;
        line-height: 100%;
        margin-bottom: 21px;
        margin-top: 15px;
    }

    .synthesisScoreCont .companyInfo .synthesisScores {
        width: 454px;
        height: 78px;
        background: #FFFFFF;
        border: 3px solid #005BFF;
        border-radius: 10px;
        font-size: 44px;
        font-weight: bold;
        color: #005BFF;
        line-height: 78px;
        margin-bottom: 32px;
        text-align: center;
    }

    .synthesisScoreCont .companyInfo .scoreRank {
        font-size: 18px;
        font-weight: bold;
        color: #282828;
        line-height: 30px;
    }

    .synthesisScoreCont .companyInfo .scoreRank span {
        color: #f00;
        font-weight: bold;
        margin-top: -4px;
    }

    .flowRanderPic {
        width: 100%;
        height: 569px;
        /* display: flex; */
        /* justify-content: space-between; */
        margin-bottom: 32px;
    }

    .flowDiagnose,
    .financialAnalysis {
        width: 580px;
        height: 100%;
        box-shadow: 0 0 24px -10px #6f6f6f;
        float: left;
    }

    .financialAnalysis {
        float: right;
    }

    .anomalyIndexInfoItem {
        margin: 0 0 26px 25px;
    }

    .anomalyIndexInfoItemTitle {
        width: calc(100% - 15px);
        height: 51px;
        background: #E7F0FF;
        /* margin-left: 25px; */
    }

    .anomalyIndexInfoItemTitle>div {
        width: 265px;
        height: 51px;
        background: #005BFF;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 51px;
        text-align: center;
        float: left;
        margin-right: 20px;
    }

    .required {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 23px;
        margin-left: 33px;
        margin-top: 35px;
    }

    #hint {
        margin-right: 5px;
        position: relative;
        top: -8px;
    }

    .icon_0 {
        width: 29px;
        height: 29px;
    }

    .icon_1 {
        width: 31px;
        height: 30px;
    }

    .icon_2 {
        width: 30px;
        height: 30px;
    }

    .icon_3 {
        width: 34px;
        height: 34px;
    }

    .icon_4 {
        width: 29px;
        height: 32px;
    }

    .toReportBox .toReport {
        width: 500px;
        background: #005BFF;
        height: 50px;
        margin: 0 auto 32px;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        cursor: pointer;
        border-radius: 25px;
    }

    .immediatelyConsult>div {
        overflow: hidden;
    }

    .consulting {
        width: 60%;
        float: left;
    }

    .consulting>p:nth-of-type(1) {
        font-size: 32px;
        font-weight: bold;
        color: #282828;
        margin-top: 26px;
        margin-bottom: 10px;
    }

    .consulting>p:nth-of-type(2) {
        font-size: 20px;
        font-weight: 400;
        color: #282828;
    }
    @media screen and (max-width: 765px) {
        .content {
            width: 100%;
            padding: 0 20px;
            padding-top: 0;
            overflow: hidden;
        }
        .synthesisScoreCont .score {
            float: inherit;
        }
        .synthesisScoreCont .score img {
            width: 100%;
            margin: 0 0 20px 0;
        }
        .synthesisScore {
            box-shadow: none;
        }
        .synthesisScoreTitle img {
            width: 19PX;
            height: 23PX;
        }
        .synthesisScoreCont .score p.synthesisTitle {
            top: 128px;
            left:0;
            font-size: 14PX;
            width: 100%;
            text-align: center;
        }
        .synthesisScoreCont .score p.synthesisScores {
            top: 168px;
            left:0;
            font-size: 22PX;
            width: 100%;
        }
        .synthesisScoreCont .score p.scoreRank {
            top: 226px;
            left: 0;
            font-size: 14PX;
            width: 100%;
            text-align: center;
        }
        .synthesisScoreCont .companyInfo {
            padding: 0;
            width: 100%;
            float: inherit;
        }
        .synthesisScoreCont .companyInfo ul.companyBaseInfo>li span{
            line-height: 28px !important;
        }
        .synthesisScoreCont .companyInfo ul.companyBaseInfo>li:nth-child(1) span,
        .synthesisScoreCont .companyInfo .scoreCabinet {
            font-size: 14PX;
        }
        .synthesisScoreCont .companyInfo .softScore li {
            width: 45%;
            margin-right: 5%;
            height: 120px;
        }


        .flowRanderPic {
            height: auto;
        }
        
        .financialAnalysis,
        .flowDiagnose {
            width: 100%;
            float: inherit;
            margin: 0 0 20px 0;
            overflow: hidden;
            box-shadow: none;
        }
        .flowDiagnoseTitle,
        .financialAnalysisTitle {
            margin-left: 0;
        }
        #financialAnalysisPic,
        #flowDiagnosePic {
            width: 100%;
            height: 560px;
            margin: 10px auto;
        }
        
    }

</style>