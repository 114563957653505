<template>
    <div>
        <Header />
        <div id="banner">
            <div class="content">
                <h2>精选服务</h2>
                <p>助力专精特新企业价值管理，让企业茁壮成长</p>
            </div>
        </div>
        <div class="productLists">
            <div class="context">
                <div class="productItem">
                    <div class="itemTitle">
                        <img src="../../assets/img/specialization/zhenduan-2_pitchon.svg" alt="">
                        <div>
                            <h3>企业价值诊断</h3>
                            <p>您的企业健康状况如何?</p>
                        </div>
                    </div>
                    <div class="itemContent">
                        <img src="../../assets/img/specialization/service_diagnose.svg" alt="">
                        <h3>企业价值诊断
                            <!-- <span>
                                <em>￥</em>9800
                            </span> -->
                        </h3>
                        <p>全面体检、发现价值</p>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>全方位解析企业经营管理状况</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>发现影响企业价值的内外部因素</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>提出专业改进发展建议</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>针对性提高企业核心竞争力</p>
                                </li>
                            </ul>
                            <ul class="core">
                                <li>七维价值诊断</li>
                                <li>专业价值诊断和建议</li>
                                <li>企业成长经营的全面分析</li>
                                <li>财务状况全方位体检</li>
                                <li>未来年度业绩及财务预测</li>
                            </ul>
                        </div>
                        <div class="btnBoies valuation-container">
                            <a class="toReportBtn" data-index="1" data-flag="false" data-stage="7" data-type="7" data-versions="2" @click="information(7,7,3)">立即诊断</a>
                            <a class="exam toExampleBtn" @click="pdf(7)" target="_blank">查看诊断报告示例</a>
                        </div>
                    </div>
                </div>
                <div class="productItem">
                    <div class="itemTitle">
                        <img src="../../assets/img/specialization/zhenduan_pitchon.svg" alt="">
                        <div>
                            <h3>企业价值量化</h3>
                            <p>您的企业值多少钱?</p>
                        </div>
                    </div>
                    <div class="itemContent">
                        <img src="../../assets/img/specialization/service_valuation.svg" alt="">
                        <h3>企业价值量化
                            <!-- <span>
                                <em>￥</em>9800
                            </span> -->
                        </h3>
                        <p>七维量化、提升价值</p>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>客观分析企业发展实力</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>量身定制企业估值建议</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>融资谈判占据主导地位</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>便于合理制定融资、股权激励计划</p>
                                </li>
                            </ul>
                            <ul class="core">
                                <li>经营能力分析</li>
                                <li>企业多维评价</li>
                                <li>估值建议</li>
                                <li>多种估值模型</li>
                                <li>未来年度业绩及财务预测</li>
                            </ul>
                        </div>
                        <div class="btnBoies valuation-container">
                            <a class="toReportBtn" data-index="1" data-flag="false" data-stage="7" data-type="2" data-versions="2" @click="information(7,2,2)">立即估值</a>
                            <a class="exam toExampleBtn" @click="pdf(2)" target="_blank">查看估值报告示例</a>
                        </div>
                    </div>
                </div>
                <div class="productItem">
                    <div class="itemTitle">
                        <img src="../../assets/img/specialization/jixiaoziping_pitchon.svg" alt="">
                        <div>
                            <h3>企业价值追踪</h3>
                            <p>您的企业近三年价值变化如何?</p>
                        </div>
                    </div>
                    <div class="itemContent">
                        <img src="../../assets/img/specialization/service_track.svg" alt="">
                        <h3>企业价值追踪
                            <!-- <span>
                                <em>￥</em>9800
                            </span> -->
                        </h3>
                        <p>跟踪价值、保价护航</p>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>股权价值动态追踪</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>专精特新各个维度变化综合展示</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>综合竞争力跟踪对比</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>财务状况可视化解析</p>
                                </li>
                            </ul>
                            <ul class="core">
                                <li>多期估值结果对比分析</li>
                                <li>多期二级市场的价值比率变化</li>
                                <li>企业综合竞争力多期变化分析</li>
                                <li>财务状况多期对比</li>
                                <li>杜邦分析多期对比</li>
                            </ul>
                        </div>
                        <div class="btnBoies valuation-container">
                            <a class="toReportBtn" data-index="6" data-type="5" data-stage="7" @click="information(7,5,2)">立即追踪</a>
                            <a class="exam toExampleBtn" @click="pdf(5)" target="_blank">查看追踪报告示例</a>
                        </div>
                    </div>
                </div>
                <div class="mask hide">
                    <!--价值追踪-->
                    <!-- 公司名称选择 -->
                    <Modal v-model="selectCompany" class-name="vertical-center-modal selectCompany"
                        @on-visible-change="modelSaveclose">
                        <p>请选择公司名称</p>
                        <ul class="company-contain">
                            <li v-for="(item, i) in companyList" @click="companyName(i)" :class="{blue:i==currentCompany}" :key="i">
                                {{item.company}}
                            </li>
                        </ul>
                        <div slot="footer" class="btncenter">
                            <span class="btn-trace" @click="trace_add">添加公司</span>
                            <span class="btn-trace" @click="trace_confirm">确定</span>
                        </div>
                    </Modal>

                    <Modal v-model="noCompany" class-name="vertical-center-modal">
                        <img class='warning' src="../../assets/img/remind-01@2x.png">
                        <span>请选择公司名称</span>
                        <div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
                    </Modal>

                    <!-- 勾选价值追踪年份弹窗 -->
                    <Modal v-model="valuationYear" class-name="vertical-center-modal select-valuation-year">
                        <p>请勾选价值追踪的年份</p>
                        <div class="years-info">
                            <p class="top-tip" v-if="traceyear.length>0">{{selectYear}}</p>
                            <ul>
                                <li v-for="(item, i) in yearList" :key="(i + 1)">
                                    <div :class="['img-status', item.checked?'selectedYes':'selectedNo']">
                                        <img :src="item.checked?require('../../assets/img/trace/year_selected.png'):require('../../assets/img/trace/year_selected_no.png')"
                                            alt="icon" @click="checkcDate($event,i)">
                                    </div>
                                    <div class="checkboxes">
                                        <input type="checkbox" name="" :id="`year${i}`"
                                            :class="[item.checked?'checkselected':'checkselectedno']"
                                            :checked="item.checked?'checked':''" @change="checkcDate($event,i)">
                                        <label :for="`year${i}`">{{item.valYear}}年</label>
                                    </div>
                                    <p class="report-type">{{item.reportId?'成长期专业估值':''}}</p>
                                    <p class="valuation-result">{{item.reportId?item.valResult:'暂无估值结果'}}</p>
                                    <p class="view-report" data-reportid="">{{item.reportId?'查看估值报告':'暂无估值结果'}}</p>
                                </li>
                            </ul>
                        </div>
                        <div slot="footer" :class="['ivu-sure',!createTrace?'grey':'']" @click="trace">价值追踪</div>
                    </Modal>

			    </div>
            </div>
        </div>
        <div class="mobileProductLists">
            <div class="context">
                <div class="productItem">
                    <div class="itemContent">
                        <h3>企业价值诊断</h3>
                        <p>您的企业健康状况如何？</p>
                        <img src="../../assets/img/specialization/service_diagnose.svg" alt="">
                        <div>
                            <p>全面体检、发现价值</p>
                            <a class="exam" @click="pdf(7)" target="_blank">查看诊断报告示例</a>
                        </div>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>全方位解析企业经营管理状况</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>发现影响企业价值的内外部因素</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>提出专业改进发展建议</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>针对性提高企业核心竞争力</p>
                                </li>
                            </ul>
                            <!-- <ul class="core">
                                <li>七维价值诊断</li>
                                <li>专业价值诊断和建议</li>
                                <li>企业成长经营的全面分析</li>
                                <li>财务状况全方位体检</li>
                                <li>未来年度业绩及财务预测</li>
                            </ul> -->
                        </div>
                        <!-- <div class="priceBox">
                            <span>￥<em>9800</em></span> / 份
                        </div> -->
                        <div class="btnBoies">
                            <a class="toReportBtn" data-index="1" data-flag="false" data-stage="7" data-type="7" data-versions="2" @click="information(7,7,3)">立即诊断</a>
                        </div>
                    </div>
                </div>
                <div class="productItem">
                    <div class="itemContent">
                        <h3>企业价值量化</h3>
                        <p>您的企业值多少钱？</p>
                        <img src="../../assets/img/specialization/service_valuation.svg" alt="">
                        <div>
                            <p>七维量化、提升价值</p>
                            <a class="exam" @click="pdf(2)" target="_blank">查看估值报告示例</a>
                        </div>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>客观分析企业发展实力</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>量身定制企业估值建议</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>融资谈判占据主导地位</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>便于合理制定融资、股权激励计划</p>
                                </li>
                            </ul>
                            <!-- <ul class="core">
                                <li>经营能力分析</li>
                                <li>企业多维评价</li>
                                <li>估值建议</li>
                                <li>多种估值模型</li>
                                <li>未来年度业绩及财务预测</li>
                            </ul> -->
                        </div>
                        <!-- <div class="priceBox">
                            <span>￥<em>9800</em></span> / 份
                        </div> -->
                        <div class="btnBoies">
                            <a class="toReportBtn" data-index="1" data-flag="false" data-stage="7" data-type="2" data-versions="2" @click="information(7,2,2)">立即估值</a>
                        </div>
                    </div>
                </div>
                <div class="productItem">
                    <div class="itemContent">
                        <h3>企业价值追踪</h3>
                        <p>您的企业近三年价值变化如何？</p>
                        <img src="../../assets/img/specialization/service_track.svg" alt="">
                        <div>
                            <p>跟踪价值、保价护航</p>
                            <a class="exam" @click="pdf(5)" target="_blank">查看追踪报告示例</a>
                        </div>
                        <div class="ulList">
                            <ul class="effect">
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>股权价值动态追踪</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>专精特新各个维度变化综合展示</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>综合竞争力跟踪对比</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/specialization/duigou.svg" alt="">
                                    <p>财务状况可视化解析</p>
                                </li>
                            </ul>
                            <!-- <ul class="core">
                                <li>多期估值结果对比分析</li>
                                <li>多期二级市场的价值比率变化</li>
                                <li>企业综合竞争力多期变化分析</li>
                                <li>财务状况多期对比</li>
                                <li>杜邦分析多期对比</li>
                            </ul> -->
                        </div>
                        <!-- <div class="priceBox">
                            <span>￥<em>9800</em></span> / 份
                        </div> -->
                        <div class="btnBoies">
                            <a class="toReportBtn" data-index="6" data-type="5" data-stage="7" @click="information(7,5,2)">立即追踪</a>
                        </div>
                    </div>
                </div>
                <div class="mask hide">
                    <!--价值追踪-->
                    <!-- 公司名称选择 -->
                    <Modal v-model="selectCompany" class-name="vertical-center-modal selectCompany"
                        @on-visible-change="modelSaveclose">
                        <p>请选择公司名称</p>
                        <ul class="company-contain">
                            <li v-for="(item, i) in companyList" @click="companyName(i)" :class="{blue:i==currentCompany}" :key="i">
                                {{item.company}}
                            </li>
                        </ul>
                        <div slot="footer" class="btncenter">
                            <span class="btn-trace" @click="trace_add">添加公司</span>
                            <span class="btn-trace" @click="trace_confirm">确定</span>
                        </div>
                    </Modal>

                    <Modal v-model="noCompany" class-name="vertical-center-modal">
                        <img class='warning' src="../../assets/img/remind-01@2x.png">
                        <span>请选择公司名称</span>
                        <div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
                    </Modal>

                    <!-- 勾选价值追踪年份弹窗 -->
                    <Modal v-model="valuationYear" class-name="vertical-center-modal select-valuation-year">
                        <p>请勾选价值追踪的年份</p>
                        <div class="years-info">
                            <p class="top-tip" v-if="traceyear.length>0">{{selectYear}}</p>
                            <ul>
                                <li v-for="(item, i) in yearList" :key="(i + 1)">
                                    <div :class="['img-status', item.checked?'selectedYes':'selectedNo']">
                                        <img :src="item.checked?require('../../assets/img/trace/year_selected.png'):require('../../assets/img/trace/year_selected_no.png')"
                                            alt="icon" @click="checkcDate($event,i)">
                                    </div>
                                    <div class="checkboxes">
                                        <input type="checkbox" name="" :id="`year${i}`"
                                            :class="[item.checked?'checkselected':'checkselectedno']"
                                            :checked="item.checked?'checked':''" @change="checkcDate($event,i)">
                                        <label :for="`year${i}`">{{item.valYear}}年</label>
                                    </div>
                                    <p class="report-type">{{item.reportId?'成长期专业估值':''}}</p>
                                    <p class="valuation-result">{{item.reportId?item.valResult:'暂无估值结果'}}</p>
                                    <p class="view-report" data-reportid="">{{item.reportId?'查看估值报告':'暂无估值结果'}}</p>
                                </li>
                            </ul>
                        </div>
                        <div slot="footer" :class="['ivu-sure',!createTrace?'grey':'']" @click="trace">价值追踪</div>
                    </Modal>

			    </div>
            </div>
        </div>
        <router-view/>
        <Footer /> 
    </div>
</template>
<script>
    import '../../assets/libs/css/myAlert.min.css';
	import qs from 'qs';
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';
    import {
		mapState,
		mapMutations
	} from 'vuex'
export default {
    name:"productDes",
    components: {
        Header,
        Footer
    },
    data(){
        return {
            selectCompany: false,
            companyList: [],
            yearList: [],
            traceyear: [], //价值追踪已选的年份
            currentCompany: null, //价值追踪选择第几个公司
            currentCompanyName: '', //值追踪当前选择哪个公司的名称
            noCompany: false,
            valuationYear: false, //价值追踪年份选择框的显示隐藏，默认隐藏
            createTrace: false,
            itemStatus: 1,
        }
    },
    mounted(){
        sessionStorage.removeItem("isHistoryCache")
    },
    methods: {
        ...mapMutations(['changeTraceYear']),
        //点击立即填写的跳转功能
        information: function(stage, type, versions) { 
            sessionStorage.stage = stage;
            sessionStorage.reportType = type;
            sessionStorage.versions = versions;
            if (type == 5) {
                this.selectCompany = true;
                this.firm(stage)
            } else {
                this.$router.push({
                    name: 'basicInformation',
                    path: '/basicInformation',
                    query: {
                        stage: stage,
                        type: type
                    }
                });
            }
        },
        //价值追踪估值公司的列表
        firm: function(stage, company) {
            this.$Spin.show(); //全局加载中
            this.$post('trace/valuationReport/qryCandidateCompanies', qs.stringify({
                stage: stage
            })).then((res) => {
                this.$Spin.hide();
                if (res.data.content.companyAndValYears && res.data.content.companyAndValYears.length > 0) {
                    var companyAndValYears = res.data.content.companyAndValYears;
                    this.companyList = res.data.content.companyAndValYears;
                    for (var i = 0; i < companyAndValYears.length; i++) {
                        if (company == companyAndValYears[i].company) {
                            for (var j = 0; j < companyAndValYears[i].yearAndValuations.length; j++) {
                                for (var y = 0; y < this.yearList.length; y++) {
                                    if (companyAndValYears[i].yearAndValuations[j].valYear == this.yearList[y].valYear) {
                                        this.$set(this.yearList, y, {
                                            valYear: companyAndValYears[i].yearAndValuations[j].valYear,
                                            valResult: companyAndValYears[i].yearAndValuations[j].valResult,
                                            reportId: companyAndValYears[i].yearAndValuations[j].reportId,
                                            checked: false
                                        })
                                    }
                                }
                            }
                        }

                    }

                }
            });
        },
        companyName: function(i) {
            this.currentCompany = i;
            this.currentCompanyName = this.companyList[i].company;
        },
        //监听弹框关闭事件
        modelSaveclose: function() { 
            var date = new Date;
            var currentYear = date.getFullYear();
            this.currentCompany = null;
            this.yearList = [{
                valYear: (currentYear - 2).toString(),
                valResult: '',
                reportId: '',
                checked: false
            }, {
                valYear: (currentYear - 1).toString(),
                valResult: '',
                reportId: '',
                checked: false
            }, {
                valYear: currentYear.toString(),
                valResult: '',
                reportId: '',
                checked: false
            }];
        },
        //价值追踪估值公司的列表确定按钮
        trace_confirm: function() { 
            if (this.currentCompany != null) { //说明选中了公司名称
                this.valuationYear = true;
                this.selectCompany = false;
                this.firm(sessionStorage.stage, this.currentCompanyName)
            } else {
                this.noCompany = true;
            }
        },
        submitDialog: function() {
            this.noCompany = false;
        },
        trace_add: function() {
            this.valuationYear = true;
            this.selectCompany = false;
            this.firm(sessionStorage.stage)
        },
        trace: function() {
            if (this.createTrace && !this.isClickTrace) {
                this.isClickTrace = true;
                this.$post('trace/valuationReport/createValuationReport', qs.stringify({
                    companyName: this.currentCompanyName,
                    yearStr: this.traceyear.toString(),
                    stage: sessionStorage.stage
                })).then((res) => {
                    this.changeTraceYear(res.data.content.yearAndvalIds[0].year)
                    this.$router.push({
                        path: '/basicInformation',
                        query: {
                            stage: sessionStorage.stage,
                            type: 5,
                            reportId: res.data.content.yearAndvalIds[0].reportId,
                            traceReportId: res.data.content.traceId,
                            year: res.data.content.yearAndvalIds[0].year
                        }
                    })
                });
            }

        },
        checkcDate: function(e, i) {
            this.$set(this.yearList[i], 'checked', !this.yearList[i].checked)
            this.traceyear = [];
            for (var j = 0; j < this.yearList.length; j++) {
                if (this.yearList[j].checked) {
                    this.traceyear.push(this.yearList[j].valYear)
                }
            }
            if (this.traceyear.length == 1) {
                this.createTrace = false;
                this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年，请再选择一年或两年';
            } else if (this.traceyear.length == 2) {
                this.createTrace = true;
                this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年、' + this.traceyear[1] + '年两年价值变化';
            } else if (this.traceyear.length == 3) {
                this.createTrace = true;
                this.selectYear = '本次价值追踪为：' + this.traceyear[0] + '年、' + this.traceyear[1] + '年、' + this
                    .traceyear[2] + '年三年价值变化';
            }

        },
        pdf: function(e) {
            if (e == 7) {
                window.open('/data/specialization_diagnose.pdf')
            } else if (e == 2) {
                window.open('/data/specialization_profession.pdf')
            } else {
                window.open('/data/specialization_trace.pdf')
            }
        },
    }
}
</script>
<style scoped>
    #banner {
        width: 100%;
        height: 400px;
        background: url(../../assets/img/specialization/selectServiceBanner.jpg)no-repeat center center;
        background-size: 100% 100%;
        margin: 0;
    }
    #banner h2 {
        font-size: 48px;
        font-weight: bold;
        color: #F0F2F5;
        text-align: center;
        line-height: 100%;
        margin:138px 0 25px 0;
    }
    #banner p {
        font-size: 22px;
        font-weight: 400;
        color: #F0F2F5;
        text-align: center;
    }
    .context {
        width: 1200px;
        margin: 0 auto;
    }
    .productItem {
        width: 1200px;
        margin: 46px 0 53px 0;
    }
    .productItem .itemTitle {
        width: 400px;
        height: 90px;
        background: #0478fc;
        overflow: hidden;
    }
    .productItem .itemTitle img {
        width: 43px;
        height: 45px;
        float: left;
        margin: 22px 20px 0 96px;
    }
    .productItem .itemTitle h3 {
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 100%;
        margin: 23px 0 11px;
    }
    .productItem .itemTitle p {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 100%;
    }
    .productItem .itemContent {
        width: 100%;
        height: 370px;
        overflow: hidden;
        background: #fff;
        /* border-radius: 0.133333rem; */
        -webkit-box-shadow: 5px 5px 24px 15px rgb(198 215 239 / 18%);
        padding: 0 0 0 20px;
    }
    .productItem .itemContent .effect {
        float: left;
        overflow: hidden;
        width: 336px;
    }
    .productItem .itemContent .effect li {
        margin: 0 0 17px 0;
    }
    .productItem .itemContent .effect li img {
        width: 22px;
        height: 21px;
        float: left;
        margin: 0 15px 0 0;
    }
    .productItem .itemContent h3 {
        /* width: 50%; */
        font-size: 20px;
        font-weight: bold;
        color: #282828;
        line-height: 100%;
        margin: 41px 0 13px 0;
    }
    .productItem .itemContent>p {
        /* width: 50%; */
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 100%;
        margin: 0 0 10px 0;
    }
    .productItem .itemContent .effect li p {
        font-size: 16px;
        font-weight: 400;
        color: #484848;
        line-height: 21px;
    }
    .productItem .itemContent .core {
        /* float: left; */
        overflow: hidden;
    }
    .productItem .itemContent .core li {
        font-size: 14px;
        font-weight: 400;
        color: #484848;
        line-height: 30px;
    }
    .productItem .itemContent .core li:nth-child(1){
        margin-top: -6px;
    }
    .productItem .itemContent .btnBoies {
        width: auto;
    }
    .productItem .itemContent .btnBoies>a {
        width: 160px;
        height: 40px;
        border: 1px solid #0478fc;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-weight: 400;
        float: left;
        margin: 19px 52px 0 4px;
        
    }
    .toReportBtn {
        background: #0478fc;
        color: #FFFFFF;
    }
    .toReportBtn:hover {
        background: #1D71E3;
    }
    .toExampleBtn {
        color: #0478fc;
        background: #C8E2FF;
    }
    .toExampleBtn:hover {
        background: #a9d2ff;
    }
    .productItem .itemContent>img {
        float: right;
        width: 348px;
        height: 280px;
        margin: 40px 146px 0 0;
    }
    .ulList {
        overflow: hidden;
    }

    .productItem .itemContent h3 span {
        font-size: 26px;
        color: #0478FC;
        margin-left: 15px;
    }

    .productItem .itemContent h3 span em {
        font-size: 18px;
        color: #0478FC;
    }

    .productItem .itemContent h3 span img {
        width: 25px;
        position: relative;
        top: -15px;
        left: -5px;
    }
    .mobileProductLists {
        display: none;
        
    }
    @media screen and (max-width:765px) {
        .productLists {
            display: none;
            widows: 100%;
            padding: 0 20px;
        }
        .mobileProductLists {
            display: block;
            width: 100%;
            padding: 0 0 20PX;
            
        }
        .context {
            width: 100%;
        }
        .context .productItem {
            width: 100%;
            height: auto;
            margin: 40px 0 0 0;
            padding: 0 40px;
            
        }
        .context .productItem .itemTitle>div h3 {
            font-size: 16PX;
            line-height: 1;
        }
        .context .productItem .itemContent {
            height: auto;
            padding: 0 0 30px 0;
            overflow: hidden;
            background: #fff;
            border-radius: 0.133333rem;
            box-shadow: 0 0.053333rem 0.106667rem 0.133333rem rgb(0 0 0 / 8%);
        }
        .context .productItem .itemContent>img {
            float: inherit;
            width: 80%;
            margin: 0px 10% 12px;
            height: auto;
            padding: 0 40px;

        }
        .context .productItem .itemContent div {
            padding: 0 40px;
        }
        .context .productItem .itemContent h3 {
            font-size: 18PX;
            margin-top: 30px;
            text-align: center;
        }
        .context .productItem .itemContent>p {
            font-size: 14PX;
            text-align: center;
            color: #515a6e;
        }
        .context .productItem .itemContent div>p {
            font-size: 16PX;
            color: #000;
            display: inline-block;
        }
        .context .productItem .itemContent div>a.exam {
            /* float: left; */
            display: inline-block;
            line-height: 16PX;
            margin: 0px 0 0 20px;
        }
        .context .productItem .btnBoies {
            margin: 0;
        }
        .context .productItem .btnBoies a{
            width: 100%;
            height: 60px;
            font-size: 16PX;
            line-height: 60px;
            margin: 0;
            float:inherit;
        }
        .context .productItem .itemContent>div.ulList {
            margin: 20px 0;
            border-bottom: 1px solid #ccc;
        }
        .context .productItem .itemContent>div.ulList ul {
            float: inherit;
            width: auto;
        }
        .context .productItem .itemContent>div.ulList ul.effect li p{
            font-size: 14PX;
        }
        .context .productItem .itemContent>div.ulList ul.core li {
            float: left;
            width: 50%;
            margin:0;
        }
        .mobileActive {
            background: #0478fc !important;
            color: #fff !important;
        }
        .context .productItem .itemContent>p:first-child {
            font-size: 14PX;
        }
        .context .productItem .itemContent>div.priceBox {
            font-size: 15PX;
            color: #000;
        }
        .context .productItem .itemContent>div.priceBox span {
            color: #0478fc;
        }
        .context .productItem .itemContent>div.priceBox span em {
            font-size: 26PX;
            /* font-weight: bold; */
            margin: -10px 0 0 0;
        }
    }
</style>