import Vue from 'vue'
import Router from 'vue-router'
import home from '@/components/home.vue'
import myValuation from '@/components/myValuation.vue'
import login from '@/components/logo/login.vue'
import register from '@/components/logo/register.vue'  // 注册
import registration from '@/components/logo/registration.vue'  // 注册协议
import disclaimer from '@/components/logo/disclaimer.vue'	// 免责声明
import confidentiality from '@/components/logo/confidentiality.vue'	// 隐私政策
import forgetPassword from '@/components/logo/forgetPassword.vue'  // 忘记密码
import notFound from '@/components/status/notFound.vue'
import serverError from '@/components/status/serverError.vue'
import header from '@/common/header.vue'
import flowHeader from '@/common/header/flow_header.vue'
import specializationUs from '@/components/about/specializationUs.vue'
import policyService from '@/components/policyService/policyService.vue'
import product from '@/components/product/productDes.vue'
import consultation from '@/components/consultation/consultationDes.vue'
import enterpriseTrain from '@/components/enterpriseTrain/enterpriseTrain.vue'
import enterpriseTrainDes from '@/components/enterpriseTrain/enterpriseTrainDes.vue'
import policyDetails from '@/components/policyService/details.vue'
import basicInformation from '@/common/functionality/basicInformation.vue' //基本信息
import softInformation from '@/common/functionality/softInformation.vue'  //软性指标
import submitReport from '@/common/functionality/submitReport.vue'  //估值报告（流程最后一步）
import comprehensive from '@/common/functionality/comprehensive.vue'  //企业综合展示
import balanceSheet from '@/common/functionality/balanceSheet.vue'  //资产负债表
import profitStatement from '@/common/functionality/profitStatement.vue'  //利润表
import diagnoseSummary from '@/components/summaryPages/diagnoseSummary.vue'  // 价值诊断总结页
import diagnoseSummary2v from '@/components/summaryPages/diagnoseSummary2v.vue'  // 价值诊断总结页2v
import valuationSummary from '@/components/summaryPages/valuationSummary.vue'  // 价值量化总结页
import traceSummary from '@/components/summaryPages/traceSummary.vue'  // 价值追踪总结页
import diagnoseReport from '@/components/reportPage/diagnoseReport.vue'  // 价值诊断
import diagnoseReport2v from '@/components/reportPage/diagnoseReport2v.vue'  // 价值诊断2v
import valuationReport from '@/components/reportPage/valuationReport.vue'  // 价值量化
import traceValuationReport from '@/components/reportPage/traceValuationReport.vue'  // 价值追踪
import majorIncomeAnalysis from '@/common/functionality/majorIncomeAnalysis.vue'  //营业收入分析预测表(选填)
import majorPolicyStatis from '@/common/functionality/majorPolicyStatis.vue'  //会计政策统计表(选填)
import finanAnalisis from '@/common/functionality/finanAnalisis.vue'  //财务分析
import examineInformation from '@/common/functionality/examineInformation.vue'  //查看信息的中的基本信息
import examineSoftInformation from '@/common/functionality/examineSoftInformation.vue'  //查看信息的中的软性指标
import valuationProfessionationHinanQuato from '@/common/functionality/valuationProfessionationHinanQuato.vue'  //查看信息的中的财务指标
import examineFinancialAnalysis from '@/common/functionality/examineFinancialAnalysis.vue'  //查看信息的中的财务分析
import auditStatus from '@/common/functionality/auditStatus.vue'  //查看信息的审核状态

import refresh from '@/common/refresh.vue'  //中转的空白页面

// 点击相同的路由报错（解决 NavigationDuplicated: Avoided redundant navigation to current location 报错）
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


Vue.use(Router)


export default new Router({
	// mode: 'history', //打包时要注释掉避免路由失效  (要去掉路径上的#号时需设置为history这个模式，上线时不需要注释，可以去掉路径上的#，还需要配置nginx )
	// base: process.env.BASE_URL,
	routes: [{ //带"/"就不带父，不带"/"就带父
		path: '/',
		name: 'home',
		component: home,
		meta: {
			title: '专精特新',
			content: {
				keywords: "心流,心流慧估,企业价值评估,股权评估,数据估值,重组并购,公司估值,大数据",
				description: "心流网，心流慧估金融科技提供企业价值评估、股权评估、公司估值、数据估值、重组并购大数据查询。心流自主研发曼殊估值专家系统，依托互联网、运用大数据和人工智能技术为企业股权估值。"
			}
		},
		children: [{
			path: 'header',
			name: 'header',
			component: header,
			children: [{
				path: 'flowHeader',
				name: 'flowHeader',
				component: flowHeader
			}]
		}]
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '登录'
		}
	},{
		path: '/register',
		name: 'register',
		component: register,
		meta:{
			title:"注册"
		}
	},{
		path: '/registration',
		name: 'registration',
		component: registration,
		meta:{
			title:"心流网注册协议"
		}
	},{
		path: '/disclaimer',
		name: 'disclaimer',
		component: disclaimer,
		meta:{
			title:"免责声明"
		}
	},{
		path: '/confidentiality',
		name: 'confidentiality',
		component: confidentiality,
		meta:{
			title:"隐私政策"
		}
	},{
		path: '/forgetPassword',
		name: 'forgetPassword',
		component: forgetPassword,
		meta:{
			title:"忘记密码"
		}
	}, {
		path: '/notFound',
		name: 'notFound',
		component: notFound,
		meta: {
			title: '404错误'
		}
	}, {
		path: '/serverError',
		name: 'serverError',
		component: serverError,
		meta: {
			title: '500错误'
		}
	}, {
		path: '/myValuation',
		name: 'myValuation',
		component: myValuation,
		meta: {
			title: '我的报告'
		}
	}, {
		path: '/specializationUs',
		name: 'specializationUs',
		component: specializationUs,
		meta: {
			title: '关于我们'
		}
	}, {
		path: '/policyService',
		name: 'policyService',
		component: policyService,
		meta: {
			title: '政策服务'
		}
	},{
		path: '/product',
		name: 'productDes',
		component: product,
		meta: {
			title: '精选服务'
		}
	},{
		path: '/consultation',
		name: 'consultationDes',
		component: consultation,
		meta: {
			title: '咨询服务'
		}
	},{
		path: '/enterpriseTrain',
		name: 'enterpriseTrain',
		component: enterpriseTrain,
		meta: {
			title: '助企培训'
		}
	},{
		path: '/enterpriseTrainDes',
		name: 'enterpriseTrainDes',
		component: enterpriseTrainDes,
		meta: {
			title: ''
		}
	},{
		path: '/policyDetails',
		name: 'policyDetails',
		component: policyDetails,
		meta: {
			title: ''
		}
	}, {
		path: '/refresh',
		name: 'refresh',
		component: refresh,
		meta: {
			title: ''
		}
	}, {
		path: '/basicInformation',
		name: 'basicInformation',
		component: basicInformation,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	}, {
		path: '/softInformation',
		name: 'softInformation',
		component: softInformation,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	}, {
		path: '/submitReport',
		name: 'submitReport',
		component: submitReport,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/balanceSheet',
		name: 'balanceSheet',
		component: balanceSheet,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/profitStatement',
		name: 'profitStatement',
		component: profitStatement,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/majorIncomeAnalysis',
		name: 'majorIncomeAnalysis',
		component: majorIncomeAnalysis,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/majorPolicyStatis',
		name: 'majorPolicyStatis',
		component: majorPolicyStatis,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/finanAnalisis',
		name: 'finanAnalisis',
		component: finanAnalisis,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/auditStatus',
		name: 'auditStatus',
		component: auditStatus,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估'
		}
	},{
		path: '/examineInformation',
		name: 'examineInformation',
		component: examineInformation,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/examineSoftInformation',
		name: 'examineSoftInformation',
		component: examineSoftInformation,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/valuationProfessionationHinanQuato',
		name: 'valuationProfessionationHinanQuato',
		component: valuationProfessionationHinanQuato,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	},{
		path: '/examineFinancialAnalysis',
		name: 'examineFinancialAnalysis',
		component: examineFinancialAnalysis,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	}, {
		path: '/comprehensive',
		name: 'comprehensive',
		component: comprehensive,
		meta: {
			title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
			content: {
				keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
				description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
			}
		}
	}, {
		path: '/diagnoseSummary',
		name: 'diagnoseSummary',
		component: diagnoseSummary,
		meta: {
			title: '价值诊断_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/diagnoseSummary2v',
		name: 'diagnoseSummary2v',
		component: diagnoseSummary2v,
		meta: {
			title: '价值诊断_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/valuationSummary',
		name: 'valuationSummary',
		component: valuationSummary,
		meta: {
			title: '价值量化_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/traceSummary',
		name: 'traceSummary',
		component: traceSummary,
		meta: {
			title: '价值追踪_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/diagnoseReport',
		name: 'diagnoseReport',
		component: diagnoseReport,
		meta: {
			title: '价值诊断_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/diagnoseReport2v',
		name: 'diagnoseReport2v',
		component: diagnoseReport2v,
		meta: {
			title: '价值诊断_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/valuationReport',
		name: 'valuationReport',
		component: valuationReport,
		meta: {
			title: '价值量化_公司股权估值,北京在线估值公司-心流慧估'
		}
	}, {
		path: '/traceValuationReport',
		name: 'traceValuationReport',
		component: traceValuationReport,
		meta: {
			title: '价值追踪_公司股权估值,北京在线估值公司-心流慧估'
		}
	}]
})
