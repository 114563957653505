<template>
    <div>
        <Header />
        <div class="content">
            <div id="titleValation">
                <p>企业价值追踪</p>
                <div class="toReport" @click="toReportPage()">查看完整报告</div>
            </div>
            <div class="valuationInformation">
                <img src="../../assets/img/summaryPages/startup1.png" alt="">
                <ul class="information">
                    <li>
                        <span>企业名称：</span>
                        <span class="companyName" v-text="pageInfo.name"></span>
                    </li>
                    <li>
                        <span>所属行业：</span>
                        <span class="industry" v-text="pageInfo.industryName + '-' + pageInfo.industryChildName"> </span>
                    </li>
                    <li>
                        <span>估值时间：</span>
                        <span class="valationtime" v-text="formatteDate(pageInfo.createTime)"></span>
                    </li>
                    <li>
                        <span>贵公司在估值基准日的股权价值为：</span>
                    </li>
                    <li>
                        <p class="valuation-result-main" v-text="pageInfo.minValue + '~' + pageInfo.maxValue"></p>
                    </li>
                    <li>
                        <span>估值结果</span><span>高于</span><span class="exceed" v-text="Number(pageInfo.queryQuestionProportion).toFixed(2) + '%'"></span><span>同行</span>
                    </li>
                </ul>
            </div>
            <div class="valuation_result">
                <div class="atlasIcon">
                    <img src="../../assets/img/specialization/result.svg" alt="" class="icon_0">
                    <span>估值结果</span>
                </div>
                <TraceLineCanvas :chartData="pageInfo.valuationDesc" :id="'trackingResults'" v-if="pageInfo.name"/>
            </div>
            <!-- 心流诊断图谱 + 财务分析 -->
            <div class="flowRanderPic">
                <div class="flowDiagnose">
                    <div class="flowDiagnoseTitle">
                        <img src="../../assets/img/summaryPages/bingtu.svg" alt="" class="icon_0">
                        <p>估值图谱</p>
                    </div>
                    <TraceRadarCanvas :chartData="pageInfo.traceQuestion" :id="'flowDiagnosePic'" v-if="pageInfo.name"/>
                </div>
                <!--  -->
                <div class="financialAnalysis">
                    <div class="financialAnalysisTitle">
                        <img src="../../assets/img/summaryPages/caiwuguanli.svg" alt="" class="icon_1">
                        <p>财务分析</p>
                    </div>
                    <TraceRadarCanvas :chartData="pageInfo.traceFinancial" :id="'financialAnalysisPic'" v-if="pageInfo.name"/>
                </div>
            </div>
            <!-- 异常指标 -->
            <ConsultationService :pageInfo="pageInfo" v-if="pageInfo.name" :reportId="reportId"  :type="type" />
        </div>
        <!-- <router-link/> -->
        <Footer />
    </div>
</template>
<script>
import ConsultationService from '../../common/consultationService.vue';
import TraceLineCanvas from '../../common/echarts/traceLineCanvas.vue';
import TraceRadarCanvas from '../../common/echarts/traceRadarCanvas.vue';
import qs from 'qs'
import Header from '../../common/header/flow_header.vue'
import Footer from '../../common/footer/flow_footer.vue'
export default {
    name:"traceSummary",
    data(){
        return {
            projectName:''
        }
    },
    components: {
        Header,
        Footer,
        ConsultationService,
        TraceLineCanvas,
        TraceRadarCanvas
    },
    mounted(){
        this.projectName=this.domain.traceProject
        this.initPage();
    },
    data() {
        return {
            reportId:this.$route.query.reportId ? this.$route.query.reportId : "",
            type: this.$route.query.type ? this.$route.query.type : "2",
            versions: this.$route.query.versions ? this.$route.query.versions : "2",
            pageInfo:{},
            execute:false,
        }  
    },
    methods: {
        initPage: function(){
            var that = this;
            var obj = {
                traceReportId: that.reportId
            }
            that.$post(this.projectName+"valuationReport/qryValuationSummaryReport",qs.stringify(obj)).then((res) => {
                console.log(res)
                var data = res.data;
                if(data.code == 200 && data.content){
                    that.pageInfo = data.content;
                    that.execute = true;
                    that.pageInfo.traceFinancial = JSON.parse(that.pageInfo.traceFinancial);
                    that.pageInfo.traceQuestion = JSON.parse(that.pageInfo.traceQuestion);
                    that.pageInfo.valuationDesc = JSON.parse(that.pageInfo.valuationDesc);
					that.pageInfo.minValue = this.method.resultConversion(that.pageInfo.minValue);
					that.pageInfo.maxValue = this.method.resultConversion(that.pageInfo.maxValue);
                    // if(that.pageInfo.minValue > 10000){
                    //     that.pageInfo.minValue = (that.pageInfo.minValue / 10000).toFixed(2) + "亿元";
                    //     that.pageInfo.maxValue = (that.pageInfo.maxValue / 10000).toFixed(2) + "亿元";
                    // } else {
                    //     that.pageInfo.minValue = that.pageInfo.minValue.toFixed(2) + "万元";
                    //     that.pageInfo.maxValue = that.pageInfo.maxValue.toFixed(2) + "万元";
                    // }
                }
            })
        },
        formatteDate: function(date){
            if(date){
                var dateArr = date.split("-");
                var dateStr = dateArr[0] + "年" + dateArr[1] + "月" + dateArr[2] + "日";
                return dateStr;
            }
        },
        toReportPage: function(){
            // 价值量化
            this.$router.push({
                name:"traceValuationReport",
                path:"/traceValuationReport",
                query:{
                    reportId: this.reportId
                }
            })
        }
    }
}
</script>
<style scoped>
    .content {
        width: 1200px;
        margin: 0 auto;
    }

    .synthesisScore {
        width: 100%;
        border: 1px solid #fff;
        margin-bottom: 25px;
        box-shadow: 0 0 24px -10px #6f6f6f;
        padding-bottom: 20px;
    }

    .anomalyIndexInfoTitle,
    .anomalyIndexTitle,
    .flowDiagnoseTitle,
    .financialAnalysisTitle,
    .synthesisScoreTitle {
        margin: 34px 0 29px 28px;
    }
    .anomalyIndexInfoTitle img,
    .anomalyIndexTitle img,
    .flowDiagnoseTitle img,
    .financialAnalysisTitle img,
    .synthesisScoreTitle img {
        width: 28px;
        height: 34px;
        margin-right: 17px;
        float: left;
    }
    .anomalyIndexInfoTitle p,
    .anomalyIndexTitle p,
    .flowDiagnoseTitle p,
    .financialAnalysisTitle p,
    .synthesisScoreTitle p {
        font-size: 22px;
        font-weight: 400;
        color: #282828;
        line-height: 34px;
    }

    .companyName {
        width: 335px;
    }

    .industry {
        width: 394px;
        line-height: 20px !important;
    }

    .flowRanderPic {
        width: 100%;
        height: 569px;
        /* display: flex; */
        /* justify-content: space-between; */
        margin-bottom: 32px;
    }

    .flowDiagnose,
    .financialAnalysis {
        width: 580px;
        height: 100%;
        box-shadow: 0 0 24px -10px #6f6f6f;
        float: left;
    }

    .financialAnalysis {
        float: right;
    }

    .anomalyIndexInfoItem {
        margin: 0 0 26px 25px;
    }

    .anomalyIndexInfoItemTitle {
        width: calc(100% - 15px);
        height: 51px;
        background: #E7F0FF;
        /* margin-left: 25px; */
    }

    .anomalyIndexInfoItemTitle>div {
        width: 265px;
        height: 51px;
        background: #005BFF;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 51px;
        text-align: center;
        float: left;
        margin-right: 20px;
    }

    .required {
        font-size: 14px;
        font-weight: 400;
        color: #282828;
        line-height: 23px;
        margin-left: 33px;
        margin-top: 35px;
    }

    #hint {
        margin-right: 5px;
        position: relative;
        top: -8px;
    }

    .icon_0 {
        width: 29px;
        height: 29px;
    }

    .icon_1 {
        width: 31px;
        height: 30px;
    }

    .icon_2 {
        width: 30px;
        height: 30px;
    }

    .icon_3 {
        width: 34px;
        height: 34px;
    }

    .icon_4 {
        width: 29px;
        height: 32px;
    }

    .toReportBox .toReport {
        width: 500px;
        background: #005BFF;
        height: 50px;
        margin: 0 auto 32px;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        cursor: pointer;
        border-radius: 25px;
    }

    .immediatelyConsult>div {
        overflow: hidden;
    }

    .consulting {
        width: 60%;
        float: left;
    }

    .consulting>p:nth-of-type(1) {
        font-size: 32px;
        font-weight: bold;
        color: #282828;
        margin-top: 26px;
        margin-bottom: 10px;
    }

    .consulting>p:nth-of-type(2) {
        font-size: 20px;
        font-weight: 400;
        color: #282828;
    }
    #titleValation {
        margin: 30px 0;
    }

    #titleValation p {
        font-size: 26px;
        font-weight: 400;
        color: #282828;
        line-height: 40px;
        display: inline-block;
    }

    #titleValation .toReport {
        width: 120px;
        height: 40px;
        background: #005BFF;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        float: right;
        text-align: center;
        cursor: pointer;
    }

    .valuationInformation {
        margin-bottom: 46px;
        overflow: hidden;
        box-shadow: 0 0 24px -10px #6f6f6f;
    }

    .valuationInformation img {
        float: left;
        margin: 39px 73px 33px 102px;
    }

    .valuationInformation .information {
        width: 40%;
        float: left;
        margin-top: 88px;
        margin-bottom: 35px;
    }
    .information li span {
        color: #282828;
    }

    .companyName {
        width: 335px;
    }

    .information li span:first-child {
        vertical-align: top;
    }

    .information li:first-child span {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
    }

    .industry {
        width: 394px;
    }

    .information li:nth-of-type(2) span {
        font-size: 14px;
        font-weight: 400;
        margin: 27px 0 13px 0;
    }

    .information li:nth-of-type(3) span {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 27px;
    }

    .information li:nth-of-type(4) span {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 17px;
    }
    .valuation-result-main {
        width: 480px;
        height: 78px;
        line-height: 78px;
        background: #FFFFFF;
        border: 3px solid #005BFF;
        border-radius: 10px;
        text-align: center;
        color: #005BFF;
        font-size: 36px;
        margin-bottom: 18px;
    }

    .information li:last-child span {
        font-size: 18px;
    }

    .information li:last-child span:nth-child(2),
    .information li:last-child span:nth-child(3) {
        color: red;
    }
    .valuation_result {
        padding: 32px 20px 19px 20px;
        box-shadow: 0 0 24px -10px #6f6f6f;
        margin-bottom: 30px;
    }
    .atlasIcon img {
        width: 29px;
        height: 29px;
        display: inline-block;
        vertical-align: middle;
    }
    #trackingResults {
        width: 60%;
        height: 400px;
        margin: 30px auto;
    }

    .atlasIcon span {
        font-size: 22px;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
        margin-left: 13px;
    }
    @media screen and (max-width: 765px) {
        .content {
            width: 100%;
            padding: 0 20px;
            padding-top: 0;
        }
        .synthesisScore {
            box-shadow: none;
        }
        .synthesisScoreTitle img {
            width: 19PX;
            height: 23PX;
        }
        .flowRanderPic {
            height: auto;
        }
        
        .financialAnalysis,
        .flowDiagnose {
            width: 100%;
            float: inherit;
            margin: 0 0 20px 0;
            overflow: hidden;
            box-shadow: none;
        }
        .flowDiagnoseTitle,
        .financialAnalysisTitle {
            margin-left: 0;
        }
        #financialAnalysisPic,
        #flowDiagnosePic {
            width: 100%;
            height: 500px;
            margin: 10px auto;
        }
        .valuationInformation {
            box-shadow: none;
        }
        .valuationInformation img {
            float: inherit;
            width: 100%;
            margin: 0;
        }
        .valuationInformation .information {
            width: 100%;
            float: inherit;
        }
        .valuation_result {
            box-shadow: none;
            padding: 0;
        }
        #trackingResults {
            width: 100%;
        }
    }

</style>