<template>
    <!-- 助企培训 -->
    <div>
        <Header />
        <div id="banner">
            <div class="content">
                <h2>助企培训</h2>
                <p>深度定制企业级人才发展解决方案，助力企业实现数字化人才战略</p>
            </div>
        </div>
        <div class="planLists">
            <div class="context">
                <div class="planItem" v-for="(item,index) in items" :key="index" @click="toDetail(item.id,item.caseName)">
                    <img :src="item.imagePath ? item.imagePath : require('../../assets/img/specialization/helpCompanies.jpg')" alt="">
                    <h3>{{item.caseName}}</h3>
                    <p>{{item.caseSummary}}</p>
                </div>
            </div>
            
        </div>
        <div class="pageContent">
            <div class="pageBox">
                <Page :current="pageNum" :total="total" @on-change="handleChange"></Page>
            </div>
        </div>
        <router-view/>
        <Footer /> 
    </div>
</template>
<script>
	import qs from 'qs';
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';

export default {
    name:"enterpriseTrain",
    components: {
        Header,
        Footer
    },
    data(){
        return {
            items:[],
            pageNum:1,
            pageNumSize:8,
            total:0,
            projectName:''
        }
    },
    mounted(){
        this.projectName=this.domain.serviceTraining
        this.initPage();
    },
    methods: {
        initPage: function(){
            var obj = {
                type:"3",
                pageNum:this.pageNum,
                pageNumSize:this.pageNumSize,
            }
            this.$post(this.projectName+"policyService/getPolicyService",qs.stringify(obj)).then((res) => {
                console.log(res);
                var data = res.data;
                if(data.code == 200 && data.content){
                    this.items = data.content.policyServiceList;
                    this.total = data.content.policyServiceTotal;
                }
            })
        },
        toDetail: function(id,title){
            this.$router.push({
                name:"enterpriseTrainDes",
                path:"/enterpriseTrainDes",
                meta:{
                    title:title
                },
                query:{id:id}
            })
        },
        handleChange: function(page){
            this.pageNum = page;
            this.initPage(page, this.type)
        }
    }
}
</script>
<style scoped lang="less">
    #banner {
        width: 100%;
        height: 400px;
        background: url(../../assets/img/specialization/enterpriseTrainBanner.jpg)no-repeat center center;
        background-size: 100% 100%;
        margin: 0;
    }
    #banner h2 {
        font-size: 48px;
        font-weight: bold;
        color: #F0F2F5;
        text-align: center;
        line-height: 100%;
        margin:138px 0 25px 0;
    }
    #banner p {
        font-size: 22px;
        font-weight: 400;
        color: #F0F2F5;
    }
    .context {
        width: 1200px;
        margin: 60px auto;
        /* text-align: center; */
    }
    .planLists {
        overflow: hidden;
    }
    .planItem {
        width: 580px;
        height: 387px;
        float: left;
        background: #fff;
        margin: 0 0 40px 0;
        -webkit-box-shadow: 5px 5px 24px 15px rgb(198 215 239 / 18%);
        cursor: pointer;
    }
    .planItem:nth-child(odd) {
        margin: 0 40px 40px 0;
    }
    .planItem img {
        width: 580px;
        height: 240px;
    }
    .planItem h3 {
        font-size: 20px;
        font-weight: bold;
        color: #282828;
        line-height: 100%;
        margin: 34px 0 13px 31px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow:ellipsis;
        /* white-space:nowrap; */
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .planItem p {
        font-size: 14px;    
        font-weight: 400;
        color: #282828;
        line-height: 26px;
        margin: 0 42px 0 31px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow:ellipsis;
        /* white-space:nowrap; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .pageBox {
		text-align: center;
		margin-top: 40px;
	}
    .pageContent {
        width: 1200px;
        margin: 0 auto 50px;
    }
    @media screen and (max-width: 765px) {
        .pageContent {
            width: 100%;
            padding: 0 20px;
        }
        .planItem {
            width: 100%;
            float: inherit;
        }
        .planLists {
            padding: 0 20px;
        }
        .planLists .context {
            width: 100%;
        }
        .planItem img {
            width: 100%;
        }
        /deep/  .ivu-page-disabled,
        /deep/  .ivu-page-item {
            width: 24PX;
            height: 24PX;
        }
    }
</style>