<template>
	<div>
		<Header />
		<uploadData />
		<navigation />
		<div id="main" class="information baseInformation">
			<p class="moduleName">
				<img src="../../assets/img/navIcon/baseSele.png" alt="线上估值_基本信息-心流">
				<span>{{this.$route.query.type==5?this.$route.query.year+'年基本信息':'基本信息'}}</span>
				<span class="tooltipState" v-if="autoDodge">
					<i>您于{{toDate}}提交了{{toType}}，本次填报部分字段数据被自动调入！</i>
					<img class="cuowu" src="../../assets/img/guzhi/cuowu_0.svg" alt="" @click="close">
				</span>
			</p>
			<div class="moduleMain">
				<p>注: <span class="reRed">*</span>为必填项</p>
				<ul class="baseContent">
					<li class="basedata" v-if="this.$route.query.type==7&&versions==3">
						<label>诊断基准日<span class="reRed">*</span></label>
						<div class="baseRight empty" id="diagnoseBaseDate">
							<Select v-model="basicInformation.diagnoseBaseDate" @on-change="diagnoseChange">
								<Option v-for="(item,i) in diagnoseBaseDate" :value="item" :key="i">{{ item }}</Option>
							</Select>
						</div>
					</li>
					<li>
						<label for="companyName">企业名称<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="companyName" class="required" data-value='false' autocomplete="off"
								v-model="basicInformation.companyName" @blur="searchMatch()" @focus="focus($event)" @input="fuzzyQuery"
								:disabled="traceStatus">
							<!-- class="normal"   -->
							<div id="getCompanyNameList" data-area="">
								<p v-for="(item,i) in getCompanyNameList" :key="i" :data-area="item.base"
									@click="checkedName(item.name,item.base)">
									{{item.name}}
								</p>
							</div>
						</div>

					</li>
					<li>
						<label for="companyShort">简称<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="companyShort" class="required" data-value='false'
								v-model="basicInformation.companyShort" :disabled="traceStatus" @blur="blur($event)"
								@focus="focus($event)">
						</div>
					</li>
					<li>
						<label for="email">邮箱<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="email" class="required" data-value='false' v-model="basicInformation.email"
								:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)">
						</div>
					</li>
					<li>
						<label for="time">成立日期<span class="reRed">*</span></label>
						<div class="baseRight empty" id="time-value">
							<Date-picker size="small" value-format="yyyy-MM-dd" type="date" confirm :editable="false"
								placeholder="选择成立日期" id="time" @on-change="timeDate" v-model="basicInformation.establishDate"
								:disabled="traceStatus">
							</Date-picker>
						</div>
					</li>
					<li>
						<label for="legalRepresentative">法定代表人</label>
						<div class="baseRight">
							<input type="text" id="legalPeople" maxlength="20" v-model="basicInformation.legalPeople"
								:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)">
						</div>
					</li>
					<li>
						<label>创始人学历</label>
						<div class="baseRight founderDegree">
							<Select @on-change="founderDegree" v-model="basicInformation.founderEdu" :disabled="traceStatus">
								<Option v-for="(item,i) in education" :value="item.label" :key="i">{{ item.label }}
								</Option>
							</Select>
						</div>
					</li>
					<li>
						<label for="registeredCapital">注册资本
						</label>
						<div class="baseRight">
							<input type="number" id="regCapital" v-model="basicInformation.regCapital"
								@blur="displayThousandths($event,basicInformation.regCapital,'regCapital')" @focus="focus($event)"
								:disabled="traceStatus">
							<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
								{{this.formatCurrency.formateNum(basicInformation.regCapital,2)}}
							</div>
							<span class="measure">万元</span>
						</div>
					</li>
					<li class="location">
						<label for="locationEnterprise">企业所在地</label>
						<div class="baseRight">
							<Select v-model="basicInformation.area" :disabled="traceStatus">
								<Option v-for="(item,i) in location" :value="item.value" :key="i">{{ item.value }}
								</Option>
							</Select>
						</div>
					</li>
					<li>
						<label for="address">详细地址</label>
						<div class="baseRight">
							<input type="text" id="address" v-model="basicInformation.address" :disabled="traceStatus"
								@blur="blur($event)" @focus="focus($event)">
						</div>
					</li>
					<li>
						<label for="registrationAuthority">登记机关</label>
						<div class="baseRight">
							<input type="text" id="regDepart" v-model="basicInformation.regDepart" :disabled="traceStatus"
								@blur="blur($event)" @focus="focus($event)">
						</div>
					</li>
					<li>
						<label for="socialCode">统一社会信用代码<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="text" id="creditCode" class="required" data-value='false' maxlength="18"
								v-model="basicInformation.creditCode" :disabled="traceStatus" @blur="blur($event)"
								@focus="focus($event)">
						</div>
					</li>
					<li class="promoteMarket" v-if="diagnoseShow">
						<label>产品是否已推广到市场<span class="reRed">*</span></label>
						<div class="baseRight promoteMarketOption empty" id="extendMarketFlag">
							<Select v-model="basicInformation.extendMarketFlag" :disabled="traceStatus" @on-change="extendMarketFlag">
								<Option value="是">是</Option>
								<Option value="否">否</Option>
							</Select>
						</div>
					</li>
					<li class="mainBusinessCompany">
						<label>公司主营业务概述<span class="reRed">*</span></label>
						<div class="baseRight bussinessDescBox empty" style="border: none;">
							<textarea name="" id="bussinessDesc" :class="['required','normal',traceStatus?'ash':'']" cols="20"
								rows="4" maxlength="100" placeholder="最多输入100个字" v-model="basicInformation.bussinessDesc"
								:disabled="traceStatus" @blur="blur($event)" @focus="focus($event)"></textarea>
						</div>
					</li>
					<li class="industry">
						<label>所属行业<span class="reRed">*</span></label>
						<div class="baseRight ">
							<div class="firstIndustry empty">
								<Select v-model="basicInformation.industryName" @on-change="industryChange" :disabled="traceStatus"
									id="firstIndustry">
									<Option v-for="(item,i) in firstIndustry" :value="item.name" :key="i">
										{{ item.name }}
									</Option>
								</Select>
							</div>
							<div class="secondIndustry empty">
								<Select v-model="basicInformation.industryChildName" @on-change="secondIndustryChange"
									:disabled="traceStatus" id="secondIndustry">
									<Option v-for="(item,i) in secondIndustry" :value="item.name" :key="i">
										{{ item.name }}
									</Option>
								</Select>
							</div>
						</div>
					</li>
					<li v-if="diagnoseShow">
						<label for="financingQuota">拟融资额度</label>
						<div class="baseRight">
							<input type="number" id="finance" v-model="basicInformation.finance"
								@blur="displayThousandths($event,basicInformation.finance,'finance')" @focus="focus($event)"
								:disabled="traceStatus">
							<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
								{{this.formatCurrency.formateNum(basicInformation.finance,2)}}
							</div>
							<span class="measure">万元</span>
						</div>
					</li>
					<li v-if="diagnoseShow">
						<label for="shareProportion">计划出让股权比例</label>
						<div class="baseRight">
							<input type="number" id="ratio" v-model="basicInformation.ratio"
								@blur="((ev)=>{percentage(ev,basicInformation,'ratio')})" @focus="focus($event)"
								:disabled="traceStatus">
							<span class="measure">%</span>
						</div>
					</li>
					<li v-if="diagnoseShow">
						<label for="enterpriseValue">企业价值（自评）<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="number" id="selfValue" class="required" data-value='false'
								v-model="basicInformation.selfValue"
								@blur="displayThousandths($event,basicInformation.selfValue,'selfValue')" @focus="focus($event)"
								:disabled="traceStatus">
							<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
								{{this.formatCurrency.formateNum(basicInformation.selfValue,2)}}
							</div>
							<span class="measure">万元</span>
						</div>
					</li>
					<li class="companyRevenue" v-if="diagnoseShow">
						<label for="companyRevenue">
							<span class="companyRevenueDate">{{forecastYear}}</span>
							<span>公司营业收入(预测)</span>
							<div class="desc-container">
								<i class="explain">?</i>
								<div class="desc-main">
									<span></span>
									<p>公司未来十二个月的营业收入</p>
								</div>
							</div>
							<span class="reRed">*</span>
							<!--<div class="explainContent">
				        这是说明这是说明这是说明这是说明这是说明这是说明
				    </div>-->
						</label>
						<div class="baseRight">
							<div class="companyRevenueRight empty">
								<input type="number" id="income" class="required" data-value='false' v-model="basicInformation.income"
									@blur="displayThousandths($event,basicInformation.income,'income')" @focus="focus($event)"
									:disabled="traceStatus">
								<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
									{{this.formatCurrency.formateNum(basicInformation.income,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</div>
					</li>
					<li v-if="diagnoseShow">
						<label for="companyNetAssets">
							<span class="companyRevenueDate">{{forecastYear}}</span>
							<span>公司净资产(预测)</span>
							<div class="desc-container">
								<i class="explain">?</i>
								<div class="desc-main">
									<span></span>
									<p>在没有其他综合收益的税后净额、分红、原始股东实缴资本、历史年度增资款到账或减资等变动情况下，公司净资产（预测）=估值基准日当年公司净资产（即资产负债表中所有者权益合计数）+公司净利润（预测）。
									</p>
								</div>
							</div>
							<span class="reRed">*</span>
						</label>
						<div class="baseRight">
							<div class="companyRevenueRight empty">
								<input type="number" id="netAsset" class="required" data-value='false'
									v-model="basicInformation.netAsset"
									@blur="displayThousandths($event,basicInformation.netAsset,'netAsset')" @focus="focus($event)"
									:disabled="traceStatus">
								<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
									{{this.formatCurrency.formateNum(basicInformation.netAsset,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</div>
					</li>
					<li v-if="diagnoseShow">
						<label for="netProfit">
							<span class="companyRevenueDate">{{forecastYear}}</span>
							<span>公司净利润（预测）</span>
							<div class="desc-container">
								<i class="explain">?</i>
								<div class="desc-main">
									<span></span>
									<p>公司未来十二个月的净利润</p>
								</div>
							</div>
							<span class="reRed">*</span>
						</label>
						<div class="baseRight">
							<div class="companyRevenueRight empty">
								<input type="number" id="netProfit" class="required" data-value='false'
									v-model="basicInformation.netProfit"
									@blur="displayThousandths($event,basicInformation.netProfit,'netProfit')" @focus="focus($event)"
									:disabled="traceStatus">
								<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
									{{this.formatCurrency.formateNum(basicInformation.netProfit,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</div>
					</li>
					<li v-if="(reportType==7&&versions!=3)||reportType==5">
						<label for="oldNetProfit">
							<span class="companyRevenueDate">{{forecastYear}}</span>
							<span>公司息税折旧摊销前利润(预测)</span>
							<div class="desc-container">
								<i class="explain">?</i>
								<div class="desc-main">
									<span></span>
									<p>息税折旧摊销前利润=净利润+所得税+折旧及摊销+财务利息费用</p>
								</div>
							</div>
							<span class="reRed">*</span>
						</label>
						<div class="baseRight">
							<div class="companyRevenueRight empty">
								<input type="number" id="amortizeProfit" class="required" data-value='false'
									v-model="basicInformation.amortizeProfit"
									@blur="displayThousandths($event,basicInformation.amortizeProfit,'amortizeProfit')"
									@focus="focus($event)" :disabled="traceStatus">
								<div :class="['showInput',traceStatus?'ash':'']" @click="inputFocus($event)">
									{{this.formatCurrency.formateNum(basicInformation.amortizeProfit,2)}}
								</div>
								<span class="measure">万元</span>
							</div>
						</div>
					</li>
					<li class="valuationPurpose" v-if="diagnoseShow">
						<label>估值目的</label>
						<div class="baseRight valuationPurposeOption">
							<Select v-model="basicInformation.valuationPurpose" :disabled="traceStatus">
								<Option value="股权改制">股权改制</Option>
								<Option value="股权激励">股权激励</Option>
								<Option value="股权融资">股权融资</Option>
								<Option value="股权质押">股权质押</Option>
								<Option value="股权转让">股权转让</Option>
								<Option value="了解自身企业价值">了解自身企业价值</Option>
								<Option value="企业并购">企业并购</Option>
								<Option value="企业征信">企业征信</Option>
								<Option value="其他">其他</Option>
							</Select>
						</div>
					</li>
					<li class="relationshipWithEnterprise" v-if="diagnoseShow">
						<label>填写人与受评企业关系</label>
						<div class="baseRight relationshipWithEnterpriseOption">
							<Select v-model="basicInformation.writerEnterpriseRelative" :disabled="traceStatus">
								<Option value="公司创始人">公司创始人</Option>
								<Option value="公司管理层">公司管理层</Option>
								<Option value="公司财务人员">公司财务人员</Option>
								<Option value="咨询方">咨询方</Option>
								<Option value="投资方">投资方</Option>
								<Option value="其他">其他</Option>
							</Select>
						</div>
					</li>
					<li>
						<label for="firstHolderRatio">第一股东持股比例<span class="reRed">*</span></label>
						<div class="baseRight empty">
							<input type="number" id="firstHolderRatio" class="required holder" data-value='false'
								v-model="basicInformation.firstHolderRatio"
								@blur="((ev)=>{percentage(ev,basicInformation,'firstHolderRatio')})" @focus="focus($event)"
								:disabled="traceStatus">
							<span class="measure">%</span>
						</div>
					</li>
					<li>
						<label for="secHolderRatio">第二股东持股比例</label>
						<div class="baseRight">
							<input type="number" id="secHolderRatio" class="relation holder" v-model="basicInformation.secHolderRatio"
								@blur="((ev)=>{percentage(ev,basicInformation,'secHolderRatio')})" @focus="focus($event)"
								:disabled="traceStatus">
							<span class="measure">%</span>
						</div>
					</li>
					<li>
						<label for="thirdHolderRatio">第三股东持股比例</label>
						<div class="baseRight">
							<input type="number" id="thirdHolderRatio" class="relation holder"
								v-model="basicInformation.thirdHolderRatio"
								@blur="((ev)=>{percentage(ev,basicInformation,'thirdHolderRatio')})" @focus="focus($event)"
								:disabled="traceStatus">
							<span class="measure">%</span>
						</div>
					</li>
					<li>
						<label for="fourHolderRatio">第四股东持股比例</label>
						<div class="baseRight">
							<input type="number" id="fourHolderRatio" class="relation holder"
								v-model="basicInformation.fourHolderRatio"
								@blur="((ev)=>{percentage(ev,basicInformation,'fourHolderRatio')})" @focus="focus($event)"
								:disabled="traceStatus">
							<span class="measure">%</span>
						</div>
					</li>
					<li>
						<label for="otherHolderRatio">其他股东持股比例</label>
						<div class="baseRight">
							<input type="number" id="otherHolderRatio" readonly="readonly" class="readonColor"
								v-model="basicInformation.otherHolderRatio">
							<span class="measure">%</span>
						</div>
					</li>
				</ul>
			</div>

			<div class="saveBox">
				<span v-if="!traceStatus" class="save" id="saveButton" data-isClick="false" @click="save">保 存</span>
				<span v-if="traceStatus" class="save" id="saveButton" @click="nextStep">下一步</span>
			</div>

			<Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>{{markedWords}}</span>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal>

			<!-- 缓存提示框 -->
			<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"
				@on-cancel="clickCacheMaskJump">
				<img class='warning-cache' src="../../assets/img/caution.png">
				<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
				<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
				<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
			</Modal>

		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import tsconfig from '../../assets/js/tsconfig.json';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import qs from 'qs'
	import axios from 'axios';
	import '../../assets/libs/js/jquery-1.11.1.min.js';

	export default {
		data() {
			return {
				navText: '估值报告',
				autoDodge: false,
				toDate: 'XXX年X月X日',
				toType: 'XXXX',
				markedWords: '', //填写错误的提示语
				myAlert: false,
				inputTimer: null,
				getCompanyNameList: [], //模糊查询公司列表
				checkedCompanyName: '', //模糊查询列表中选中的公司名称
				education: [{ //创始人学历
					label: '大专及以下'
				}, {
					label: '本科'
				}, {
					label: '硕士'
				}, {
					label: '博士'
				}],
				location: [], //企业所在地
				firstIndustry: [],
				secondIndustry: [],
				forecastYear: '',
				diagnoseBaseDate: [], //诊断基准日
				basicInformation: {
					companyName: '',
					companyShort: '', // 公司简称
					email: '', // 邮件
					establishDate: '', //成立时间
					legalPeople: '', //法定代表人
					founderEdu: '', //创始人学历
					regCapital: '', //注册资本
					area: '', //企业所在地
					address: '', // 详细地址
					regDepart: '', // 登记机关
					creditCode: '', // 统一社会信用代码
					bussinessDesc: '', // 公司主营业务概述
					extendMarketFlag: '', //产品是否已推广到市场
					industry: '', // 一级行业标识码
					industryChild: '', //二级行业标识码
					industryName: '', //一级行业名称
					industryChildName: '', //二级行业名称
					finance: '', // 拟融资额度
					ratio: '', // 计划出让股权比例
					selfValue: '', //企业价值（自评）
					income: '', // 公司营业收入(预测)
					netAsset: '', // 公司净资产(预测)
					netProfit: '', //公司净利润（预测）
					amortizeProfit: '', // 公司息税折旧摊销前利润(预测)
					valuationPurpose: '', // 估值目的
					writerEnterpriseRelative: '', // 填写人与受评企业关系
					firstHolderRatio: '', // 第一股东持股比例
					secHolderRatio: '', // 第二股东持股比例
					thirdHolderRatio: '', // 第三股东持股比例
					fourHolderRatio: '', // 第四股东持股比例
					otherHolderRatio: '', // 其他股东持股比例
					diagnoseBaseDate: '',
					position: '4',
					valuationReportId: '',
					id: ''
				},
				reportMatchId: '',
				traceStatus: false, //价值追踪input 禁用状态，默认不禁用
				versions: sessionStorage.versions,
				reportType: sessionStorage.reportType,
				diagnoseShow: false, //新版诊断要显示的数据 默认不展示
				projectName: '',
				cacheAlert: false
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData
		},
		mounted() {
			sessionStorage.stage = this.$route.query.stage;
			sessionStorage.reportType = this.$route.query.type;
			if (sessionStorage.reportType == 7) {
				sessionStorage.versions = 3;
			} else {
				sessionStorage.versions = 2;
			}
			if (this.$route.query.type == 7) {
				this.diagnosticBasedate();
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			this.location = tsconfig.base.location;
			this.oneIndustry();

			// if (this.$route.query.reportId) {
			if (this.$route.query.type == 5) {
				this.traceBaseInfo()
			} else {
				this.getBaseInfo()
			}
			// } else {
			if (!this.$route.query.reportId) {
				var nowYear = new Date();
				this.forecastYear = nowYear.getFullYear() + '年';
			} else {
				this.judgeYear()
			}
			// }


			if ((this.$route.query.type == 7 && sessionStorage.versions != 3) || this.$route.query.type == 2 || this.$route
				.query.type == 5) {
				this.diagnoseShow = true;
			}
			console.log(this.projectName)
			// this.cacheAlert = true
		},
		methods: {
			...mapMutations(['pageModify']),
			timeDate: function(val) {
				console.log(val)
				this.pageModify('true');
				$("#time-value").css("border", "none");
				this.basicInformation.establishDate = val;
				//调用缓存共用方法

				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}

			},
			founderDegree: function(val) {
				this.pageModify('true');
				// console.log(val)
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
			},
			percentage: function(e, val, key) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #CACACA";
				this.fixed(val, key)
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
			},
			focus: function(e) {
				e.currentTarget.style.border = "1px solid #1459F4";
				this.checkedCompanyName = ''
			},
			blur: function(e) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #CACACA";
				if (this.method.filterSensitiveWords(e.target, this.basicInformation)) {
					e.currentTarget.style.borderColor = "red";
					this.myAlert = true;
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					e.currentTarget.style.borderColor = "#CACACA";
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
			},
			fixed: function(data, key) {
				if (data[key] > 100) {
					this.myAlert = true;
					this.markedWords = '请输入不大于100的数字';
					data[key] = '';
				} else {
					data[key] = Number(data[key]).toFixed(2)
				}

				if (key == 'firstHolderRatio') {
					this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
						Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation.thirdHolderRatio) -
						Number(this.basicInformation.fourHolderRatio);
				} else if (key == 'secHolderRatio') {
					if (this.basicInformation.secHolderRatio > this.basicInformation.firstHolderRatio || this
						.basicInformation.secHolderRatio < 0 || (Number(this.basicInformation.secHolderRatio) + Number(
							this.basicInformation.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.markedWords = '请输入正确的持股比例';
						data['secHolderRatio'] = '';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				} else if (key == 'thirdHolderRatio') {
					if (this.basicInformation.thirdHolderRatio > this.basicInformation.secHolderRatio || this
						.basicInformation.thirdHolderRatio < 0 || (Number(this.basicInformation.thirdHolderRatio) +
							Number(this.basicInformation.secHolderRatio) + Number(this.basicInformation
								.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.markedWords = '请输入正确的持股比例';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				} else if (key == 'fourHolderRatio') {
					if (Number(this.basicInformation.fourHolderRatio) > Number(this.basicInformation
							.thirdHolderRatio) || this
						.basicInformation.fourHolderRatio < 0 || (Number(this.basicInformation.fourHolderRatio) +
							Number(this.basicInformation.thirdHolderRatio) + Number(this.basicInformation
								.secHolderRatio) + Number(this.basicInformation.firstHolderRatio)) > 100) {
						this.myAlert = true;
						this.markedWords = '请输入正确的持股比例';
					} else {
						this.basicInformation.otherHolderRatio = 100 - Number(this.basicInformation.firstHolderRatio) -
							Number(this.basicInformation.secHolderRatio) - Number(this.basicInformation
								.thirdHolderRatio) - Number(this.basicInformation.fourHolderRatio);
					}
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}


			},
			// 是否推广市场
			extendMarketFlag: function() {
				$("#extendMarketFlag").css("border", "none");
			},
			// 诊断基准日
			diagnoseChange: function() {
				$("#diagnoseBaseDate").css("border", "none");
			},
			submitDialog: function() {
				this.myAlert = false;

				if (this.markedWords == '请选择诊断基准日') {
					$("#diagnoseBaseDate").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#diagnoseBaseDate").offset().top - "200";
				}
				if (this.markedWords == '请输入企业名称') {
					$("#companyName").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#companyName").offset().top - "200";
				}
				if (this.markedWords == '请输入简称') {
					$("#companyShort").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#companyShort").offset().top - "200";
				}
				if (this.markedWords == '请输入邮箱' || this.markedWords == '请输入正确的邮箱') {
					$("#email").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#email").offset().top - "200";
				}
				if (this.markedWords == '请选择成立日期') {
					$("#time-value").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#time-value").offset().top - "200";
				}
				if (this.markedWords == '请输入统一社会信用代码' || this.markedWords == '信用代码输入有误') {
					$("#creditCode").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#creditCode").offset().top - "200";
				}
				if (this.markedWords == '请输入公司主营业务概述') {
					$("#bussinessDesc").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#bussinessDesc").offset().top - "200";
				}
				if (this.markedWords == '请选择产品是否已推广到市场') {
					$("#extendMarketFlag").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#extendMarketFlag").offset().top - "200";
				}
				if (this.markedWords == '请选择所属一级行业') {
					$("#firstIndustry").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#firstIndustry").offset().top - "200";
				}
				if (this.markedWords == '请选择所属二级行业') {
					$("#secondIndustry").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#secondIndustry").offset().top - "200";
				}
				if (this.markedWords == '请输入企业价值（自评）') {
					$("#selfValue").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#selfValue").offset().top - "200";
				}

				if (this.markedWords == '请输入公司营业收入(预测)') {
					$("#income").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#income").offset().top - "200";
				}
				if (this.markedWords == '请输入公司净资产(预测)') {
					$("#netAsset").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#netAsset").offset().top - "200";
				}
				if (this.markedWords == '请输入公司净利润(预测)') {
					$("#netProfit").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#netProfit").offset().top - "200";
				}
				if (this.markedWords == '请输入公司息税折旧摊销前利润(预测)') {
					$("#amortizeProfit").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#amortizeProfit").offset().top - "200";
				}
				if (this.markedWords == '请输入第一股东持股比例') {
					$("#firstHolderRatio").css("border", "1px solid red");
					document.documentElement.scrollTop = $("#firstHolderRatio").offset().top - "200";
				}

			},

			judgeYear: function() {
				this.$post(this.projectName + 'valuationReport/qryValuationCurrentYear', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					}))
					.then((res) => {
						this.forecastYear = res.data.content.reportCreateTime + '年';
					});
			},
			getCompanyName: function(name) {
				this.$post(this.projectName + 'baseInfo/getCompanyName', qs.stringify({
						companyName: name
					}))
					.then((res) => {
						this.getCompanyNameList = res.data.content
					});
			},
			escapeRegExp: function(string) { //转义特殊字符
				return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
			},
			fuzzyQuery: function() {
				var this_ = this;
				var text = this_.escapeRegExp(this_.basicInformation.companyName);
				var regex = new RegExp(text)

				if (regex.test(this_.basicInformation.companyName)) {
					clearTimeout(this_.inputTimer);
					this_.inputTimer = setTimeout(function() { //阻止input方法执行两次
						console.log('中文输入已完成');

						if (this_.basicInformation.companyName.length >= 4 && this_.basicInformation.companyName.length < 20) {
							this_.getCompanyName(this_.basicInformation.companyName)
						}

					}, 100); // 设置一个适当的延迟时间

				} else {
					// 中文输入未完成
					console.log('中文输入未完成');
					if (this_.basicInformation.companyName.length == 0) {
						$('#getCompanyNameList').html('');
					}
				}
			},
			searchMatch: function() { //部分字段自动调入功能
				this.pageModify('true');
				var target = document.getElementById('companyName')
				var this_ = this

				// console.log(this.method.filterSensitiveWords(target))
				target.style.border = "1px solid #CACACA";
				if (this.method.filterSensitiveWords(target, this.basicInformation)) {
					target.style.borderColor = "red";
					this.myAlert = true;
					this.markedWords = '您输入的内容存在敏感词';
				} else {
					target.style.borderColor = "#CACACA";
				}


				if ((this.$route.query.type == 5 && !this.basicInformation.id) || (this.basicInformation.companyName != '' && !
						this.$route.query.reportId)) {
					setTimeout(function() {

						this_.fetchData(this_.checkedCompanyName)
					}, 100)
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
			},
			checkedName: function(name, base) {
				this.checkedCompanyName = name
				this.basicInformation.companyName = name
				this.basicInformation.area = base
				$('#getCompanyNameList').html('');
			},
			fetchData: function(name) {
				this.$post(this.projectName + 'valuationReport/getBaseInfoByCompanyName', qs.stringify({
						name: name ? name : this.basicInformation.companyName,
						stage: this.$route.query.stage,
						type: this.$route.query.type
					}))
					.then((res) => {
						var defaultDataKey = Object.keys(this.basicInformation)
						if (res.data.code == 200 && res.data.content != null) {
							// dataSource ：0官网   1用友
							this.autoDodge = res.data.content.dataSource == '0' ? true : false;

							if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
								$('.baseContent').css('margin-top', res.data.content.dataSource == '0' ? '100px' : '0');
							}

							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							this.reportMatchId = content.valuationReportId;
							if (content.createTime) {
								this.toDate = content.createTime.split("-")[0] + "年" + content.createTime
									.split(
										"-")[1] + "月" + content.createTime.split("-")[2] + "日";
							}

							if (content.reportType == 1 && content.stage == 2) {
								this.toType = "成长期快速估值";
							} else if (content.reportType == 1 && content.stage == 3) {
								this.toType = "成熟期快速估值";
							} else if (content.reportType == 2 && content.stage == 2) {
								this.toType = "成长期专业估值";
							} else if (content.reportType == 2 && content.stage == 3) {
								this.toType = "成熟期专业估值";
							} else if (content.reportType == 7 && content.stage == 1) {
								this.toType = "初创期价值诊断";
							} else if (content.reportType == 7 && content.stage == 2) {
								this.toType = "成长期价值诊断";
							} else if (content.reportType == 7 && content.stage == 3) {
								this.toType = "成熟期价值诊断";
							} else if (content.reportType == 8 && content.stage == 1) {
								this.toType = "初创期价值演示";
							} else if (content.reportType == 8 && content.stage == 2) {
								this.toType = "成长期价值演示";
							} else if (content.reportType == 8 && content.stage == 3) {
								this.toType = "成熟期价值演示";
							} else if (content.reportType == 7 && content.stage == 7) {
								this.toType = "达尔文诊断";
							} else if (content.reportType == 2 && content.stage == 7) {
								this.toType = "达尔文估值";
							} else if (content.reportType == 5 && content.stage == 7) {
								this.toType = "达尔文追踪";
							} else if (content.stage == 1) {
								this.toType = "初创期估值";
							} else if (content.stage == 4) {
								this.toType = "并购估值";
							} else if (content.reportType == 11) {
								this.toType = "财务预警";
							} else if (content.reportType == 12) {
								this.toType = "图灵估值";
							} else if (content.reportType == 100) {
								this.toType = "在线尽调";
							} else if (content.reportType == 5) {
								this.toType = "价值追踪";
							}

							this.assignment(dataKey, defaultDataKey, content, 'searchMatch')
						}
						// else {
						// 	defaultDataKey.forEach((item, idx) => {
						// 		if (item != 'companyName') {
						// 			this.basicInformation[item] = '';
						// 		}
						// 	})
						// }
					});
			},
			save: function() {
				var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/; //邮箱
				var creditCodeReg = /^[0-9A-Z]{18}$/; //统一社会信用代码
				var url = '';
				if (this.$route.query.type == 7 && this.versions == 3 && !this.basicInformation.diagnoseBaseDate) {
					this.myAlert = true;
					this.markedWords = '请选择诊断基准日';
					return false;
				}
				if (!this.basicInformation.companyName) {
					this.myAlert = true;
					this.markedWords = '请输入企业名称';
					return false;
				}
				if (!this.basicInformation.companyShort) {
					this.myAlert = true;
					this.markedWords = '请输入简称';
					return false;
				}
				if (!this.basicInformation.email) {
					this.myAlert = true;
					this.markedWords = '请输入邮箱';
					return false;
				} else {
					if (!emailReg.test(this.basicInformation.email)) {
						this.myAlert = true;
						this.markedWords = '请输入正确的邮箱';
						return false;
					}
				}
				if (!this.basicInformation.establishDate) {
					this.myAlert = true;
					this.markedWords = '请选择成立日期';
					return false;
				}
				if (!this.basicInformation.creditCode) {
					this.myAlert = true;
					this.markedWords = '请输入统一社会信用代码';
					return false;
				} else {
					if (!creditCodeReg.test(this.basicInformation.creditCode)) {
						this.myAlert = true;
						this.markedWords = '信用代码输入有误';
						return false;
					}
				}
				if (!this.basicInformation.bussinessDesc) {
					this.myAlert = true;
					this.markedWords = '请输入公司主营业务概述';
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.extendMarketFlag) {
					this.myAlert = true;
					this.markedWords = '请选择产品是否已推广到市场';
					return false;
				}
				if (!this.basicInformation.industryName) {
					this.myAlert = true;
					this.markedWords = '请选择所属一级行业';
					return false;
				}
				if (!this.basicInformation.industryChildName) {
					this.myAlert = true;
					this.markedWords = '请选择所属二级行业';
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.selfValue) {
					this.myAlert = true;
					this.markedWords = '请输入企业价值（自评）';
					return false;
				}

				if (this.diagnoseShow && !this.basicInformation.income) {
					this.myAlert = true;
					this.markedWords = '请输入公司营业收入(预测)';
					return false;
				} else if (this.diagnoseShow && this.basicInformation.income == 0) {
					this.myAlert = true;
					this.markedWords = '营业收入(预测)不能为零';
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.netAsset) {
					this.myAlert = true;
					this.markedWords = '请输入公司净资产(预测)';
					return false;
				}
				if (this.diagnoseShow && !this.basicInformation.netProfit) {
					this.myAlert = true;
					this.markedWords = '请输入公司净利润(预测)';
					return false;
				}
				if (this.reportType != 2) {
					if (this.diagnoseShow && !this.basicInformation.amortizeProfit) {
						this.myAlert = true;
						this.markedWords = '请输入公司息税折旧摊销前利润(预测)';
						return false;
					}
				}

				if (!this.basicInformation.firstHolderRatio) {
					this.myAlert = true;
					this.markedWords = '请输入第一股东持股比例';
					return false;
				}

				if (sessionStorage.reportType == 7 && sessionStorage.versions == 3) {
					url = this.projectName + 'baseInfo/saveDiagnoseBaseInfo3';
				} else {
					url = this.projectName + 'baseInfo/save';
				}

				console.log(this.basicInformation)

				this.$post(url, qs.stringify({
						original: this.reportMatchId,
						baseInfo: JSON.stringify(this.basicInformation),
						stage: sessionStorage.stage,
						reportType: sessionStorage.reportType,
						versions: sessionStorage.versions,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						if (this.$route.query.type == 5) {
							this.$router.push({
								path: '/softInformation',
								query: {
									stage: this.$route.query.stage,
									type: this.$route.query.type,
									reportId: this.$route.query.reportId,
									traceReportId: this.$route.query.traceReportId,
									year: this.$route.query.year
								}
							})
						} else {
							this.$router.push({
								path: '/softInformation',
								query: {
									stage: sessionStorage.stage,
									type: sessionStorage.reportType,
									reportId: res.data.content.valuationReportId
								}
							});
						}
					});
			},
			nextStep: function() {
				this.$router.push({
					path: '/softInformation',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}
				})
			},
			getBaseInfo: function(close) {
				this.$Spin.show(); //全局加载中
				// console.log(typeof(this.$route.query.type))
				this.$post(this.projectName + 'baseInfo/getBaseInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						versions: sessionStorage.versions,
						stage: this.$route.query.stage,
						reportType: this.$route.query.type,
					}))
					.then((res) => {
						this.$Spin.hide();
						this.basicInformation.valuationReportId = this.$route.query.reportId;
						this.contentData = res.data.content

						if (res.data.content) {
							this.isCacheValue = res.data.content.isCache
							// 是否清楚缓存数据
							if (this.isCacheValue == '0' && close == '0') {
								this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
							}
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
							} else {
								var content = res.data.content;
								var dataKey = Object.keys(content); //获取对象的 key
								var defaultDataKey = Object.keys(this.basicInformation)
								this.basicInformation.valuationReportId = this.$route.query.reportId;
								this.basicInformation.id = content.id;
								this.assignment(dataKey, defaultDataKey, content, 'getBaseInfo')
							}
						}
					});
			},
			traceBaseInfo: function() {
				this.$post(this.projectName + 'baseInfo/getBaseInfo', qs.stringify({
						traceReportId: this.$route.query.traceReportId,
						majorReportId: this.$route.query.reportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						if (res.data.content) {
							var content = res.data.content;
							var dataKey = Object.keys(content); //获取对象的 key
							var defaultDataKey = Object.keys(this.basicInformation)
							this.basicInformation.valuationReportId = this.$route.query.reportId;
							this.basicInformation.id = content.id;
							this.forecastYear = content.year + '年';
							if (content.traceStatus == 1) { //1 审核通过  2 未填写完成  3 填写完成未支付
								this.traceStatus = true;
								document.querySelector('.uploading').setAttribute("class", "forbidden"); //禁止上传模板
							} else {
								this.traceStatus = false;
							}
							this.assignment(dataKey, defaultDataKey, content, 'getBaseInfo')
						}
					});
			},
			assignment: function(param, data, content, describe) {
				//param:参数key的数组   data：主要数据key的数组  content：接口返回的数据      describe：表示是填充调入的数据还是回显的数据
				data.forEach((elem, index) => {
					param.forEach((item, idx) => {
						if (elem == item) {
							if (item == 'regCapital' || item == 'finance' ||
								item == 'income' || item == 'netAsset' || item ==
								'netProfit' || item == 'amortizeProfit') {
								if (document.getElementById(item)) {
									document.getElementById(item).nextSibling.style
										.display = 'block';
								}
							}
							if (item == 'ratio' || item == 'firstHolderRatio' ||
								item == 'secHolderRatio' || item ==
								'thirdHolderRatio' || item == 'fourHolderRatio' ||
								item == 'otherHolderRatio') {
								this.basicInformation[item] = content[item] ?
									Number(content[item]).toFixed(2) : '';
							} else if (item == 'extendMarketFlag') {
								if (content[item] == '0') {
									this.basicInformation[item] = '否';
								} else {
									this.basicInformation[item] = '是';
								}
							} else if (item == 'diagnoseBaseDate') {
								for (var i = 0; i < this.diagnoseBaseDate
									.length; i++) {
									if (content[item] == this.diagnoseBaseDate[i]) {
										this.basicInformation[item] = content[item];
										break;
									} else {
										this.basicInformation[item] = '';
									}
								}
							} else if (item == 'area') { //企业所在地
								if (content[item]) this.basicInformation[item] = content[item]
							} else if (item == 'bussinessDesc') { //主营业务超100字省略号展示
								this.basicInformation[item] = content[item] && content[item].length > 100 ? content[item]
									.substring(0, 100) + '...' : content[item];
							} else {
								if (describe == 'searchMatch') {
									if (item == 'valuationReportId' || item == 'id') {
										this.basicInformation[item] = '';
									} else {
										this.basicInformation[item] = content[item] ? content[item] :
											'';
									}
								} else {
									this.basicInformation[item] = content[item] ? content[item] : '';
								}
								if (item == 'industry') {
									var params = {
										listedCode: 6
									}
									if (this.$route.query.type == 5) {
										params.year = this.$route.query.year
									}
									this.$post(this.projectName + 'industry/qryOneIndustry', qs
											.stringify(params))
										.then((res) => {
											var codeList = res.data.content;
											codeList.forEach((item, idx) => {
												if (item.code == content['industry']) {
													this.industryGroup(content.industry,
														content
														.industryChildName); //调用二级行业
												}
											})
										});
								}
							}

						}
					});
				});

			},
			close: function() {
				this.autoDodge = false;
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					$('.baseContent').css('margin-top', '0');
				}
			},
			displayThousandths: function(e, val, key) {
				this.pageModify('true');
				e.currentTarget.style.border = "1px solid #CACACA";
				var y = String(val).indexOf(".") + 1; //获取小数点的位置
				var count = String(val).length - y; //获取小数点后的个数

				if (!val) {
					this.basicInformation[key] = "";
				} else {
					if (count > 2) {
						this.$set(this.basicInformation, key, Math.round(Number(val) * 100) / 100)
					}
				}
				if (e.currentTarget.parentNode.lastChild.innerHTML == '万元') {
					if (val <= 2147483647 && val >= -2147483648) {
						// console.log('符合')
					} else {
						this.basicInformation[key] = "";
						this.myAlert = true;
						this.markedWords = '请输入合理数值';
						return false;
					}
					e.currentTarget.nextSibling.style.display = 'block';
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			oneIndustry: function() { //一级行业
				var params = {
					listedCode: 6
				}
				if (this.$route.query.type == 5) {
					params.year = this.$route.query.year
				}
				this.$post(this.projectName + 'industry/qryOneIndustry', qs.stringify(params))
					.then((res) => {
						this.firstIndustry = res.data.content;
					});
			},
			industryGroup: function(code, name) {
				var params = {
					industryCode: code
				}
				if (this.$route.query.type == 5) {
					params.year = this.$route.query.year
				}
				this.$post(this.projectName + 'industry/qryTwoIndustry', qs.stringify(params))
					.then((res) => {
						this.secondIndustry = res.data.content;
						this.basicInformation.industryChildName = name;
					});

			},
			industryChange: function(val) {
				this.pageModify('true');
				for (var i = 0; i < this.firstIndustry.length; i++) {
					if (val == this.firstIndustry[i].name) {
						this.basicInformation.industry = this.firstIndustry[i].code;
						this.industryGroup(this.firstIndustry[i].code, '')
						break;
					}
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
				$("#firstIndustry").css("border", "none");

			},
			secondIndustryChange: function(val) {
				this.pageModify('true');
				for (var i = 0; i < this.secondIndustry.length; i++) {
					if (val == this.secondIndustry[i].name) {
						this.basicInformation.industryChild = this.secondIndustry[i].code;
						break;
					}
				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentData) || (this
							.$route.query.reportId && this.contentData && this.isCacheValue == '0')) {
						this.method.caching("baseInfo", JSON.stringify(this.basicInformation), this.$route.query.type,
							this.$route.query.stage, this)
					}
				}
				$("#secondIndustry").css("border", "none");

			},
			diagnosticBasedate: function() {
				var basedate = [];
				var currentMonth = new Date().getMonth() + 1;
				var currentDate = new Date().getDate();

				if (currentMonth <= 3 && currentDate < 31) { //去年12月31日之后到当年3月31日之前
					basedate = [new Date().getFullYear() - 1 + "年3月31日", new Date().getFullYear() - 1 + "年6月30日",
						new Date().getFullYear() - 1 + "年9月30日", new Date().getFullYear() - 1 + "年12月31日"
					];
				} else if (currentMonth > 3 && currentMonth <= 6) { //当年3月31日以后
					basedate = [new Date().getFullYear() - 1 + "年6月30日", new Date().getFullYear() - 1 + "年9月30日",
						new Date().getFullYear() - 1 + "年12月31日", new Date().getFullYear() + "年3月31日"
					];
				} else if (currentMonth > 6 && currentMonth <= 9) { //当年6月30日以后
					basedate = [new Date().getFullYear() - 1 + "年9月30日", new Date().getFullYear() - 1 + "年12月31日",
						new Date().getFullYear() + "年3月31日", new Date().getFullYear() + "年6月30日"
					];
				} else if (currentMonth > 9 && currentMonth <= 12) { //当年9月30日以后
					basedate = [new Date().getFullYear() - 1 + "年12月31日", new Date().getFullYear() + "年3月31日",
						new Date().getFullYear() + "年6月30日", new Date().getFullYear() + "年9月30日"
					];
				}
				this.diagnoseBaseDate = basedate;
			},



			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getBaseInfo();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getBaseInfo')
				// this.getBaseInfo();
			}


		}


	}
</script>

<style lang="less" scoped>
	.ash {
		background: rgb(250, 250, 250) !important;
		pointer-events: none;
	}

	.baseInformation .baseContent {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.reRed {
		color: #FF0000;
	}

	.baseInformation ul>p {
		color: #282828;
		padding-top: 19px;
		height: 14px;
		font-size: 14px;
	}

	.baseInformation .baseContent>p span {
		margin: 0 5px;
	}

	.baseInformation .baseContent>li {
		width: 476px;
		color: #282828;
		margin-bottom: 19px;
	}

	.baseInformation .baseContent>li label {
		width: 180px;
		line-height: 34px;
		vertical-align: top;
		font-size: 14px;
		position: relative;
	}

	.baseInformation .baseContent>li label span.reRed {
		margin-left: 8px;
	}

	.baseInformation ul.baseContent li .baseRight .measure,
	.baseInformation ul.baseContent li .baseRight .person,
	.baseInformation ul.baseContent li .baseRight .number {
		position: absolute;
		right: 2%;
		top: 14px;
	}

	.baseInformation ul.baseContent li .baseRight {
		position: relative;
		border-radius: 4px;
	}

	.baseInformation ul.baseContent li .baseRight input {
		width: calc(100% - 40px);
		height: 38px;
		padding: 0 20px;
		border: 1px solid #CACACA;
		font-size: 14px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		box-sizing: content-box;
	}

	.baseInformation ul.baseContent li .baseRight input:focus {
		border-color: #1459F4 !important;
	}

	.baseRight div.showInput {
		width: 400px;
		height: 37.5px;
		line-height: 38px;
		background: #fff;
		padding: 0 20px;
		font-size: 14px;
		position: absolute;
		top: 1px;
		left: 1px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		display: none;
	}

	.baseInformation .readonColor {
		background: #f1f1f1;
	}

	.baseInformation .save {
		width: 160px;
		height: 50px;
		line-height: 50px;
		background: #1459F4;
		font-size: 16px;
		color: #fff;
		text-align: center;
		cursor: pointer;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
		margin: 0 auto 100px;
		display: block;
	}

	.baseInformation ul.baseContent li .baseRight .normal {
		border: 1px solid #CACACA;
		background: #FFF;
	}

	.baseInformation ul.baseContent li .baseRight .normalChange {
		border: 1px solid #1459F4;
		background: #FFF;
	}

	.baseInformation ul.baseContent li .baseRight .abnormal {
		border: 1px solid #E60012 !important;
		background: #FEF6F9;
	}

	.baseInformation ul.baseContent .combo-select {
		margin-bottom: 0;
		width: 100%;
		max-width: none;
	}

	.baseInformation ul.baseContent li .combo-select input {
		border: none;
		width: 100%;
	}

	.baseInformation .firstIndustry {
		width: 476px;
		display: inline-block;
	}

	.baseInformation .secondIndustry {
		width: 476px;
		display: inline-block;
		float: right;
	}

	.baseInformation .companyRevenue label {
		position: relative;
	}

	.baseInformation li label i {
		display: inline-block;
		width: 12px;
		height: 12px;
		background: #ffa446;
		color: #fff;
		text-align: center;
		line-height: 12px;
		font-size: 12px;
		margin-left: 2px;
		margin-top: -14px;
		border-radius: 6px;
		cursor: pointer;
	}

	.baseInformation .companyRevenue label .explainContent {
		display: none;
		position: absolute;
		width: 230px;
		height: 86px;
		left: 130px;
		bottom: 30px;
		z-index: 20;
		color: #282828;
		font-size: 14px;
		padding: 15px 20px 25px 23px;
		line-height: 2;
		background: url("../../assets/img/guzhi/explainContent.png") no-repeat;
	}

	.baseInformation li .companyRevenueLeft {
		display: inline-block;
		width: 478px
	}

	.baseInformation li .companyRevenueLeft input {
		text-align: center;
		background: #f1f1f1;
	}

	.baseInformation li .companyRevenueRight {
		display: inline-block;
		width: 100%;
		float: right;
	}

	.baseInformation li.mainBusinessCompany,
	.baseInformation li.industry,
	.baseInformation li.industry .baseRight,
	.uploadingCompanyLogo,
	.stockholder {
		width: 100% !important;
	}

	.companyRevenueDate {
		font-size: 14px;
	}


	/* 价值演示股东样式 */

	.baseInformation li .HolderRatio {
		width: 478px;
	}

	.baseInformation li .HolderName input {
		background: #ffff;
		text-align: left;
	}

	.baseInformation #bussinessDesc,
	.baseInformation #trade,
	.baseInformation #applicationArea {
		width: calc(100% - 40px);
		padding: 20px;
		min-height: 68px;
		font-size: 14px;
		/*去除非IE右下角三角黑点*/
		resize: none;
		/*去除IE浏览器的滚动条*/
		overflow: hidden;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		box-sizing: content-box;
	}

	.baseInformation #trade {
		border: 1px solid #CACACA;
	}

	.maxWords {
		display: inline-block;
		position: absolute;
		right: 2%;
		bottom: 10%;
		color: #868686;
	}

	.baseInformation .desc-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.baseInformation .desc-container>span {
		width: 12px;
		height: 12px;
		background: url(../../assets/img/guzhi/desc-tip@2x.png);
		display: inline-block;
		border-radius: 50%;
		position: relative;
		top: -12px;
		left: 5px;
	}

	.baseInformation .desc-main {
		position: absolute;
		top: 0;
		line-height: 1;
		width: 275px;
		z-index: 2;
	}

	.baseInformation .desc-container .desc-main>span:before {
		content: "";
		position: absolute;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
		border-bottom: 9px solid #ECC641;
		border-top: 9px solid transparent;
		top: 5px;
		left: 4px;
		z-index: 7;
		display: none;
	}

	.baseInformation .desc-container .desc-main>span:after {
		content: "";
		position: absolute;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-bottom: 8px solid #FEEEB4;
		border-top: 6px solid transparent;
		top: 10px;
		left: 5px;
		z-index: 45;
		display: none;
	}

	.baseInformation .desc-container .desc-main>p {
		background: #FEEEB4;
		padding: 13px 10px 13px 15px;
		position: absolute;
		top: 23px;
		left: 4px;
		border: 1px solid #ECC641;
		line-height: 1.5;
		border-radius: 4px;
		border-top-left-radius: 0;
		display: none;
	}

	.baseInformation .desc-container:hover .desc-main>span:after {
		display: block;
	}

	.baseInformation .desc-container:hover .desc-main>span:before {
		display: block;
	}

	.baseInformation .desc-container:hover .desc-main>p {
		display: block;
	}

	.baseInformation .bussinessDescBox,
	.baseInformation .applicationArea {
		border: 1px solid #CACACA;
	}

	.baseInformation textarea {
		border: none;
	}

	.baseInformation textarea:focus {
		outline-offset: 0;
		outline: none;
	}

	#jedate .jedate-footbtn .btnscon span {
		background-color: #ffc600;
	}

	#jedate .jedate-content .daystable td.action,
	#jedate .jedate-content .daystable td.action:hover,
	#jedate .jedate-content .daystable td.action .lunar {
		background-color: #ffc600;
	}

	#jedate .jedate-content .daystable td.other,
	#jedate .jedate-content .daystable td.other .nolunar,
	#jedate .jedate-content .daystable td.other .lunar {
		color: #ffc600;
	}

	.jedate .jedate-header .ymbtn {
		padding: 0 8px;
		border-radius: 4px;
		cursor: pointer;
		font-size: 14px;
		line-height: 20px;
	}

	.myModa .myAlertBox>div.alertBox>p {
		float: left;
		width: 168px;
		font-size: 14px;
		line-height: 40px;
		color: #000;
	}

	.jedate .jedate-content .yeartable td,
	.jedate .jedate-content .monthtable td {
		width: 73px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		position: relative;
		overflow: hidden;
		font-size: 14px;
	}

	.information>div.saveBox span.save {
		margin-left: 124px;
	}

	.purpose .combo-dropdown {
		height: 200px;
	}


	/* 价值演示导航 */

	.demonstration-nav {
		background: #fff;
	}

	.demonstration-nav>ul {
		margin: 0 auto 59px;
		overflow: hidden;
		/* width: 950px; */
		display: flex;
		justify-content: center;
	}

	.demonstration-nav>ul>li {
		/* width: 20%; */
		width: 199px;
		text-align: center;
		cursor: pointer;
	}

	.demonstration-nav>ul>li p embed svg:hover {
		cursor: pointer;
	}

	.demonstration-nav>ul.fast {
		display: none;
	}

	.demonstration-nav>ul.major {
		display: block;
	}

	.demonstration-nav>ul>li>div,
	.online-finance-list>ul>li>div {
		display: block;
		position: relative;
	}

	.demonstration-nav>ul>li>div>p>img {
		display: block;
		width: 100%;
	}

	.demonstration-nav>ul>li>div>p {
		width: 52px;
		height: 52px;
		margin: 0 auto;
	}

	.online-finance-list>ul>li>div>span {
		height: 1px;
		width: 70px;
		position: absolute;
		border-bottom: 1px solid #1459F4;
	}

	.demonstration-nav>ul>li>div>span {
		height: 1px;
		width: 38%;
		position: absolute;
		border-bottom: 2px solid #1459F4;
	}

	.demonstration-nav>ul>li>div>span:first-of-type,
	.online-finance-list>ul>li>div>span:first-of-type {
		top: 50%;
		left: 0;
	}

	.demonstration-nav>ul>li>div>span:last-of-type,
	.online-finance-list>ul>li>div>span:last-of-type {
		top: 50%;
		right: 0;
	}

	.demonstration-nav>ul>li:first-of-type>div>span:first-of-type,
	.online-finance-list>ul>li:first-of-type>div>span:first-of-type {
		display: none;
	}

	.demonstration-nav>ul>li:last-of-type>div>span:last-of-type,
	.online-finance-list>ul>li:last-of-type>div>span:last-of-type {
		display: none;
	}

	.demonstration-nav>ul>li>span,
	.online-finance-list>ul>li>span {
		font-size: 14px;
		margin-top: 18px;
		line-height: 1;
	}

	.demonstration-nav>ul>li.active>span {
		color: #1459F4;
	}


	/* 主要事迹 */

	.story,
	.honor,
	.coreTeam {
		width: 818px;
		margin: 0 auto;
	}

	.story .storyItem,
	.honor .honorItem,
	.coreTeam .coreTeamItem {
		/* border-bottom: 1px #F1F1F1 solid; */
		border-bottom: 1px #bbbbbb solid;
	}

	.story .storyItem:first-child {
		padding-top: 0;
	}

	.story .storyItem:first-child .delete {
		display: none;
	}

	.story .storyItem>div {
		width: auto;
		margin: 0 auto;
		padding: 39px 0;
	}

	.story .storyItem>div>p {
		width: 648px;
	}

	.story .storyItem>div:first-child {
		margin-left: 77px;
	}

	.story .storyItem p:nth-of-type(2) {
		position: relative;
	}

	.story>p {
		width: 161px;
		height: 38px;
		border: 1px solid #0478FC;
		font-size: 14px;
		text-align: center;
		line-height: 38px;
		color: #0478FC;
		margin-top: 31px;
		margin-left: 192px;
		cursor: pointer;
	}

	.story .storyItem>div span:first-child {
		width: 85px;
		margin-right: 29px;
		font-size: 14px;
		color: #282828;
	}

	.story .storyItem>div p:nth-of-type(2) span {
		margin-top: -163px
	}


	/* .story .storyItem>div>p:first-child span:nth-of-type(2), */

	.honorItem p .delete,
	.coreTeamItem p .delete,
	.storyItem p .delete {
		width: 85px;
		height: 38px;
		color: #0478FC;
		border: 1px solid #0478FC;
		font-size: 14px;
		text-align: center;
		line-height: 38px;
		margin-left: 30px;
		cursor: pointer;
	}

	.story .storyItem>div>p:first-child input {
		width: 344px;
		height: 38px;
		border: 1px solid #BFBFBF;
		padding-left: 19px;
		font-size: 14px;
	}

	.story .storyItem>div>p:last-child textarea {
		width: 324px;
		min-height: 91px;
		border: 1px solid #CACACA;
		padding: 5px 19px;
		resize: none;
		overflow: hidden;
		margin-top: 29px;
		line-height: 20px;
		font-size: 14px;
	}


	/* 荣誉资质 */

	.honorItem>div {
		width: 648px;
		margin: 0 auto;
		padding: 38px 0;
	}

	.honorItem p span:first-child {
		width: 57px;
		font-size: 14px;
		color: #272727;
		margin-right: 38px;
	}

	.honorItem p:last-child span:first-child {
		position: relative;
		top: -55px;
	}

	.honorItem p:last-child span:nth-of-type(2) {
		width: 143px;
		height: 143px;
	}

	.honorItem p .honorName {
		width: 345px;
		height: 38px;
		border: 1px solid #BFBFBF;
		padding-left: 19px;
		margin-bottom: 29px;
		font-size: 14px;
	}

	.honorItem p img {
		width: 143px;
		height: 143px;
	}

	.honorItem i.pictureFormat {
		position: relative;
		top: -29px;
		width: 250px;
		left: 151px;
		color: #666;
	}


	/* .honorItem p:last-child span:first-child {
    margin-top: -244px;
} */

	.honorItem .fileImg,
	.baseRight .fileImg {
		width: 121px;
		height: 38px;
		position: relative;
		display: inline-block;
		border: 1px solid #0377FB;
		overflow: hidden;
		color: #1E88C7;
		text-decoration: none;
		text-indent: 0;
		line-height: 38px;
		text-align: center;
		font-size: 14px;
		color: #0477FB;
		top: -10px;
		left: 39px;
	}

	.baseRight .fileImg {
		top: -73px;
		left: 210px;
	}

	.companylogo {
		width: 176px;
		height: 85px;
	}

	.companylogo+i {
		position: absolute;
		bottom: 18px;
		left: 200px;
		width: 234px;
	}

	.honorItem .fileImg input,
	.baseRight .fileImg input {
		position: absolute;
		font-size: 100px;
		right: 0;
		top: 0;
		opacity: 0;
	}

	.honor>p {
		width: 161px;
		height: 38px;
		border: 1px solid #0377FB;
		color: #0377FB;
		line-height: 38px;
		text-align: center;
		font-size: 14px;
		margin-top: 31px;
		margin-left: 192px;
		cursor: pointer;
	}

	.honorItem:first-child .delete {
		display: none;
	}


	/* 核心团队 */

	.coreTeamItem i.pictureFormat {
		position: relative;
		top: -29px;
		width: 250px;
		left: 151px;
		color: #666;
	}

	.coreTeamItem>div {
		width: 648px;
		margin: 39px auto 39px;
		/* margin-top: 48px; */
		/* padding: 39px 0; */
		/* padding-top: 48px; */
	}

	.coreTeamItem:first-child>div {
		padding: 0;
	}

	.coreTeamItem p span:first-child {
		width: 95px;
		font-size: 14px;
		color: #272727;
		/* margin-right: 38px; */
		/* float: left; */
	}

	.coreTeamItem p .honorName {
		width: 345px;
		height: 38px;
		border: 1px solid #BFBFBF;
		padding-left: 19px;
		margin-bottom: 29px;
		font-size: 14px;
	}

	.coreTeamItem p img {
		width: 143px;
		height: 143px;
		border-radius: 50%;
	}

	.coreTeamItem p:nth-child(3) {
		height: 143px;
		margin-bottom: 20px;
	}

	.coreTeamItem p .imgTitle {
		/* margin-top: -240px; */
		margin-top: 11px;
	}

	.coreTeamItem p:nth-child(3) span {
		float: left;
	}

	.coreTeamItem .imgBox {
		width: 143px;
		height: 143px;
		/* float:left; */
	}

	.coreTeamItem p:last-child span:first-child {
		/* margin-top: -244px; */
		float: left;
	}

	.coreTeamItem .fileImg {
		width: 121px;
		height: 38px;
		position: relative;
		display: inline-block;
		border: 1px solid #0377FB;
		overflow: hidden;
		color: #1E88C7;
		text-decoration: none;
		text-indent: 0;
		line-height: 38px;
		text-align: center;
		font-size: 14px;
		color: #0477FB;
		top: 50px;
		left: 39px;
	}

	.coreTeamItem .fileImg input {
		position: absolute;
		font-size: 100px;
		right: 0;
		top: 0;
		opacity: 0;
	}

	.coreTeam>.addGoon>p {
		float: left;
		width: 161px;
		height: 38px;
		border: 1px solid #0377FB;
		color: #0377FB;
		line-height: 38px;
		text-align: center;
		font-size: 14px;
		margin-top: 31px;
		margin-left: 184px;
		cursor: pointer;
	}

	.coreTeam>.addGoon>span {
		font-size: 14px;
		color: #bbb;
		line-height: 100%;
		margin: 43px 0 0 0;
	}

	.coreTeamItem:first-child .delete {
		display: none;
	}

	.coreTeamContent .coreTeamItem>div>p:last-child>textarea {
		width: 329px;
		min-height: 124px;
		border: 1px solid #CACACA;
		padding: 15px;
		resize: none;
		overflow: hidden;
		margin: 0 0 0px 4px;
		line-height: 20px;
		font-size: 14px;
	}

	.coreTeamItem>div>p:last-child i {
		margin-top: -39%;
		color: red;
		margin-right: 6px;
	}

	.storyDescLength {
		position: absolute;
		right: 192px;
		bottom: 8px;
		font-size: 14px;
		color: #282828
	}


	/* 项目介绍 */

	.introduce {
		width: 745px;
		margin: 0 auto;
	}

	.introduce li {
		margin-bottom: 31px;
	}

	.introduce li>span {
		width: 104px;
		font-size: 14px;
		color: #282828;
		margin-top: -17%;
	}

	.introduce p {
		display: inline-block;
		position: relative;
		left: 33px;
	}

	.introduce p>span {
		position: absolute;
		right: 3%;
		bottom: 15px;
		font-size: 14px;
		color: #282828
	}

	.introduce textarea {
		width: 573px;
		height: 75px;
		color: #282828;
		padding: 16px 32px 0 16px;
		font-size: 14px;
		border: 1px solid #CACACA;
		resize: none;
		/*去除非IE右下角三角黑点*/
		overflow: hidden;
		/*去除IE浏览器的滚动条*/
	}


	/* 基金 */

	.fundEvaluation-nav>span,
	.demonstration-nav>span {
		width: 100%;
		display: inline-block;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		color: #282828;
		margin-bottom: 40px;
	}

	.fundFinanceNav>span {
		margin-bottom: 0;
	}

	.organizationalForm .baseRight {
		width: 69%;
	}

	.organizationalForm .baseRight>div,
	.financingFlag .baseRight>div {
		display: inline-block;
		margin-right: 25px;
	}

	.organizationalForm input[type="radio"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		cursor: pointer;
	}

	.organizationalForm input[type="radio"]+label::before {
		content: "\a0";
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		width: 16px;
		height: 16px;
		margin-right: 11px;
		margin-top: -2px;
		border-radius: 50%;
		border: 1px solid #CACACA;
		line-height: 14px;
	}

	.organizationalForm input[type="radio"]:checked+label::before {
		border: 6px solid #0478fc;
		background-clip: content-box;
		padding: 3px;
		width: 1px;
		height: 1px;
	}

	#investmentField {
		width: calc(100% - 40px);
		padding: 20px;
		min-height: 60px;
		font-size: 14px;
		resize: none;
		/*去除非IE右下角三角黑点*/
		overflow: hidden;
		/*去除IE浏览器的滚动条*/
		border: 1px solid #CACACA;
		box-shadow: none;
		outline: none;
	}

	input.deadline[disabled] {
		background-color: #d7d7d7;
	}

	.manager .hint {
		width: 100%;
		margin: 0 auto 29px;
		font-size: 14px;
		color: #9A9A9A;
	}

	/* 基本信息 */
	/deep/ #main .ivu-date-picker {
		width: 100%;
		height: 38px !important;
		border-color: #CACACA;
	}

	/deep/ .baseInformation .ivu-input-small,
	/deep/ .baseInformation .ivu-select-single .ivu-select-selection,
	/deep/ .baseInformation .ivu-select-selection .ivu-select-placeholder,
	/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
	/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-selected-value,
	/deep/ .baseInformation .ivu-select-selection>div {
		height: 38px;
		line-height: 38px;
		color: #000;
	}

	/deep/ .baseInformation .ivu-input-suffix i {
		margin-top: 6px;
	}

	/deep/ .baseInformation .ivu-input-with-suffix,
	/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
	/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
		padding-left: 4%;
	}

	/deep/ .baseInformation .ivu-select-selection,
	/deep/ .baseInformation .ivu-input {
		border-color: #CACACA;
	}

	#firstIndustry {
		border-radius: 3px;
	}

	#secondIndustry {
		border-radius: 3px;
	}

	/* 基本信息 */

	@media screen and (max-width: 750px) {
		.baseInformation .baseContent {
			min-width: 100%;
			margin: 0;
			display: block;
		}

		.baseInformation ul>p {
			margin-bottom: 55px;
		}

		.baseInformation ul>p,
		.baseInformation .baseContent>li {
			margin-right: 0;
		}

		.baseInformation ul.baseContent li .baseRight {
			width: 58% !important;
			display: inline-block;
		}

		.baseInformation .baseContent>li label {
			width: 34%;
			height: auto;
			margin-right: 35px;
			position: relative;
			display: inline-block;
		}


		.baseInformation ul.baseContent li .baseRight input {
			height: 50px;
		}

		.reRed {
			position: absolute;
			top: 0;
			right: 0;
		}

		/deep/ .baseInformation .ivu-input-small,
		/deep/ .baseInformation .ivu-select-single .ivu-select-selection,
		/deep/ .baseInformation .ivu-select-selection .ivu-select-placeholder,
		/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
		/deep/ .baseInformation .ivu-select-single .ivu-select-selection .ivu-select-selected-value,
		/deep/ .baseInformation .ivu-select-selection>div {
			height: 50px;
			line-height: 50px;
			color: #000;
			font-size: 14PX;
		}

		/deep/ #main .ivu-date-picker {
			height: 50px !important;
		}

		/deep/ .ivu-select-item {
			font-size: 14PX !important;
		}

		/deep/ .baseInformation .ivu-input-suffix i {
			margin-top: 15px;
		}

		// /deep/ .ivu-date-picker .ivu-select-dropdown{
		// 	width: 69%;
		// }
		// /deep/ .ivu-picker-panel-body{
		// 	width: 100%;
		// }
		// /deep/ .ivu-date-picker-cells{
		// 	width: 92%;
		// 	font-size: 14PX;
		// }
		.baseInformation .save {
			display: block;
			margin: 0 auto;
		}

		.baseRight div.showInput {
			width: 58%;
			top: 11px;
			left: 2px;
		}


		.information>p span.tooltipState {
			width: 100%;
			height: auto;
			position: relative;
			margin: 24% 0 0;
			padding: 20px 0 10px;
			line-height: 30px;
			text-indent: 0;
		}

		.information>p span.tooltipState i {
			width: 86%;
			margin: 0 auto;
			display: block;
			text-align: justify;
		}

		.information>p span.tooltipState img.cuowu {
			position: absolute;
			top: -11%;
			right: -2%;
		}

		.baseInformation .firstIndustry,
		.baseInformation .secondIndustry {
			width: 46%;
		}

	}
</style>
