<template>
	<div id="">
		<Header />
		<uploadData />
		<navigation />
		<div class="information major-profit">
			<div>
				<p class="moduleName">
					<img src="../../assets/img/navIcon/financeSele.png" alt="心流">
					<span>{{this.$route.query.type==5?this.$route.query.year+'年财务指标':'财务指标'}}</span>
					<span v-if="this.$route.query.type==2" class="division">|</span>
					<span v-if="this.$route.query.type==2" class="">专业估值</span>
				</p>
				<!-- <div v-if="this.$route.query.type==2" class="down-up-container">
					<a href="/data/财务报表模板.xlsx" class="down-container">下载模板</a>
					<span class="up-container" @click="uploadInformation">
						<font>上传信息表</font>
					</span>
				</div> -->
			</div>

			<Modal v-model="myAlert" class-name="vertical-center-modal uploadFiles" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<span class="up-container">
						<input type="file" name="file" id="fileupload" ref="file" @change="uploadFiles($event)">
						<font>上传信息表</font>
					</span>
					<p class="up-file">支持excel格式文件，填写财务数据时，<span class="red">请勿使用剪切（Ctrl+X）功能</span>，会导致表格公式出现错误。</p>
				</div>
				<div slot="footer"></div>
			</Modal>
			<Modal v-model="success" class-name="vertical-center-modal file_uccess" :mask-closable="false">
				<p slot="header">
					<em></em>
					<span>上传文件</span>
				</p>
				<div class="file-container">
					<img src="../../assets/img/up-success@2x.png">
					<p>报表上传成功，请再次确定财务信息！</p>
				</div>
				<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
			</Modal>

			<Modal v-model="error" class-name="vertical-center-modal" :mask-closable="false">
				<img class='warning' src="../../assets/img/remind-01@2x.png">
				<span>您选择的模板有误，请上传心流网提供的专业模板</span>
				<div slot="footer" class="ivu-sure" @click="wrongBtn">确定</div>
			</Modal>

			<div class="moduleMain">
				<financeNav />
				<div v-if="this.$route.query.type!=7" class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="财务指标模块之填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul>
						<li>
							<span>1、利润表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等。</span>
						</li>
						<li>
							<span>2、如企业2016年或2017年成立，则从该年填起；灰色为自动算出项，不可填写。</span>
						</li>
						<li>
							<span>3、选填项中，如果该科目在对应的会计年度存在发生额，请如实填报。</span>
						</li>
					</ul>
				</div>
				<div v-if="this.$route.query.type==7" class="inputTabDesc">
					<p class="table-desc-tit">
						<img src="../../assets/img/guzhi/desc@2x.png" alt="财务指标模块之填表说明-心流" />
						<span>填表说明</span>
					</p>
					<ul>
						<li>
							<span>1、利润表请务必按实际情况填报完整，各明细科目合计应与该一级科目填报数相等。</span>
						</li>
						<li>
							<span>2、灰色为自动算出项，不可填写。</span>
						</li>
						<li>
							<span>3、财务数据请如实、完整填报。</span>
						</li>
					</ul>
				</div>
				<div class="table-main">
					<!-- 表名-->
					<div class="table-name">
						<span>利润表</span>
					</div>
					<span class="unit">单位：元</span>
					<div :class="['table-container',this.$route.query.type==7?'diagnoseProfit':'']">
						<table class="table" border="0" style="border-collapse: collapse;">
							<thead>
								<tr class="noInput">
									<th>项目</th>
									<th v-for="(item, index) in yearList">{{item}}</th>
								</tr>
							</thead>
							<tbody>
								<tr class="trOne tipTerm" data-class="income">
									<td>一、营业收入</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.income[i]"
											@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('income',i)?formatCurrency.formateNum(profit.income[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo tipTerm" data-class="cost">
									<td>减:营业成本</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.cost[i]"
											@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('cost',i)?formatCurrency.formateNum(profit.cost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree tipTerm" data-class="taxAdditional">
									<td>税金及附加</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.taxAdditional[i]"
											@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('taxAdditional',i)?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree tipTerm" data-class="saleCost">
									<td>销售费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.saleCost[i]"
											@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleCost',i)?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree tipTerm" data-class="manageCost">
									<td>管理费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.manageCost[i]"
											@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('manageCost',i)?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="researchCost">
									<td>研发费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.researchCost[i]"
											@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('researchCost',i)?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree tipTerm" data-class="financeCost">
									<td>财务费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financeCost[i]"
											@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financeCost',i)?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour tipTerm" data-class="interestCost">
									<td>其中:利息支出</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.interestCost[i]"
											@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('interestCost',i)?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="tipTerm" data-class="interestIncome">
									<td class="interestIncome">利息收入</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.interestIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('interestIncome',i)?formatCurrency.formateNum(profit.interestIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="otherBenefits">
									<td>加:其他收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherBenefits[i]"
											@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherBenefits',i)?formatCurrency.formateNum(profit.otherBenefits[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="investmentIncome">
									<td>投资收益(损失以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.investmentIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('investmentIncome',i)?formatCurrency.formateNum(profit.investmentIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="enterpriseIncome">
									<td>其中:对联营企业和合营企业的投资收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('enterpriseIncome',i)?formatCurrency.formateNum(profit.enterpriseIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr data-class="financialAssetsBenefits">
									<td class="financialAssetsBenefits">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsBenefits[i]"
											@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financialAssetsBenefits',i)?formatCurrency.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="netExposureHedgeIncome">
									<td>净敞口套期收益（损失以"-"号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.netExposureHedgeIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('netExposureHedgeIncome',i)?formatCurrency.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="fairValueIncome">
									<td>公允价值变动收益(损失以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.fairValueIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('fairValueIncome',i)?formatCurrency.formateNum(profit.fairValueIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="creditImpairmentLoss">
									<td>信用减值损失(损失以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.creditImpairmentLoss[i]"
											@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('creditImpairmentLoss',i)?formatCurrency.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
										</span>
									</td>
								</tr>

								<tr class="trThree" data-class="assetLoss">
									<td>资产减值损失(损失以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.assetLoss[i]"
											@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('assetLoss',i)?formatCurrency.formateNum(profit.assetLoss[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="assetDisposalIncome">
									<td>资产处置收益（损失以“-”号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.assetDisposalIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('assetDisposalIncome',i)?formatCurrency.formateNum(profit.assetDisposalIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="businessProfit">
									<td>二、营业利润(亏损以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.businessProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('businessProfit',i)?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="outsideIncome">
									<td>加:营业外收入</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.outsideIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('outsideIncome',i)?formatCurrency.formateNum(profit.outsideIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="outsideExpenditure">
									<td>减:营业外支出</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.outsideExpenditure[i]"
											@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('outsideExpenditure',i)?formatCurrency.formateNum(profit.outsideExpenditure[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalProfit">
									<td>三、利润总额（亏损总额以“-”号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.totalProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalProfit',i)?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo tipTerm" data-class="incomeTax">
									<td>减:所得税费用</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.incomeTax[i]"
											@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('incomeTax',i)?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="netProfit">
									<td>四、净利润(净亏损以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.netProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('netProfit',i)?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo even noInput">
									<td>(一)按经营持续性分类</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trThree" data-class="goingConcernNetProfit">
									<td>1.持续经营净利润（净亏损以"-"号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.goingConcernNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('goingConcernNetProfit',i)?formatCurrency.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="discontinuedOperationsNetProfit">
									<td>2.终止经营净利润（净亏损以"-"号填列)</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid"
											v-model="profit.discontinuedOperationsNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'discontinuedOperationsNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('discontinuedOperationsNetProfit',i)?formatCurrency.formateNum(profit.discontinuedOperationsNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo even noInput">
									<td>(二)按所有权归属分类</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trThree" data-class="parentCompanyNetProfit">
									<td>1.归属于母公司股东的净利润（净亏损以“"-"号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.parentCompanyNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('parentCompanyNetProfit',i)?formatCurrency.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="minorityStockLegalRight">
									<td>2.少数股东损益（净亏损以“"-"号填列）</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockLegalRight[i]"
											@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityStockLegalRight',i)?formatCurrency.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="ociNetTax">
									<td>五、其他综合收益的税后净额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.ociNetTax[i]" disabled>
										<span class="showInput">
											{{judgingCondition('ociNetTax',i)?formatCurrency.formateNum(profit.ociNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="parentCompanyOciNetTax">
									<td>(一)归属于母公司所有者的其他综合收益的税后净额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
										<span class="showInput">
											{{judgingCondition('parentCompanyOciNetTax',i)?formatCurrency.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="notReclassifiedOci">
									<td>1.不能重分类进损益的其他综合收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.notReclassifiedOci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('notReclassifiedOci',i)?formatCurrency.formateNum(profit.notReclassifiedOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="remeasureBenefitAmount">
									<td>(1)重新计量设定受益计划变动额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.remeasureBenefitAmount[i]"
											@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('remeasureBenefitAmount',i)?formatCurrency.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="notTransferOci">
									<td>(2)权益法下不能转损益的其他综合收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.notTransferOci[i]"
											@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('notTransferOci',i)?formatCurrency.formateNum(profit.notTransferOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="otherEquityFairValue">
									<td>(3)其他权益工具投资公允价值变动</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherEquityFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquityFairValue',i)?formatCurrency.formateNum(profit.otherEquityFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="enterpriseCreditRiskFairValue">
									<td>(4)企业自身信用风险公允价值变动</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid"
											v-model="profit.enterpriseCreditRiskFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('enterpriseCreditRiskFairValue',i)?formatCurrency.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trThree" data-class="reclassifiedOci">
									<td>2.将重分类进损益的其他综合收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.reclassifiedOci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('reclassifiedOci',i)?formatCurrency.formateNum(profit.reclassifiedOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="transferOci">
									<td>(1)权益法下可转损益的其他综合收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.transferOci[i]"
											@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('transferOci',i)?formatCurrency.formateNum(profit.transferOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="otherClaimsFairValue">
									<td>(2)其他债权投资公允价值变动</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherClaimsFairValue',i)?formatCurrency.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="financialAssetsOciAmount">
									<td>(3)金融资产重分类计入其他综合收益的金额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsOciAmount[i]"
											@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financialAssetsOciAmount',i)?formatCurrency.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="otherClaimsCreditImpairment">
									<td>(4)其他债权投资信用减值准备</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsCreditImpairment[i]"
											@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherClaimsCreditImpairment',i)?formatCurrency.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="cashFlowHedgingReserve">
									<td>(5)现金流量套期储备</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.cashFlowHedgingReserve[i]"
											@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('cashFlowHedgingReserve',i)?formatCurrency.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trFour" data-class="foreignCurrencyBalance">
									<td>(6)外币财务报表折算差额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.foreignCurrencyBalance[i]"
											@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('foreignCurrencyBalance',i)?formatCurrency.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="minorityStockOciNetTax">
									<td>(二)归属于少数股东的其他综合收益的税后净额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockOciNetTax[i]"
											@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityStockOciNetTax',i)?formatCurrency.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="totalComprehensiveIncome">
									<td>六、综合收益总额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalComprehensiveIncome',i)?formatCurrency.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="parentCompanyTci">
									<td>(一)归属于母公司所有者的综合收益总额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.parentCompanyTci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('parentCompanyTci',i)?formatCurrency.formateNum(profit.parentCompanyTci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="minorityStockTci">
									<td>(二)归属于少数股东的综合收益总额</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.minorityStockTci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('minorityStockTci',i)?formatCurrency.formateNum(profit.minorityStockTci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trOne" data-class="earningsPerShare">
									<td>七、每股收益</td>
									<td v-for="(item, i) in yearList"></td>
								</tr>
								<tr class="trTwo" data-class="basicEps">
									<td>(一)基本每股收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.basicEps[i]"
											@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('basicEps',i)?formatCurrency.formateNum(profit.basicEps[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr class="trTwo" data-class="dilutedEps">
									<td>(二)稀释每股收益</td>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.dilutedEps[i]"
											@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('dilutedEps',i)?formatCurrency.formateNum(profit.dilutedEps[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="mobileProfit">
						<ul>
							<li v-for="(item, index) in yearList">{{item}}</li>
							<!-- <li v-if="this.$route.query.type==7"></li> -->
						</ul>
						<table border="0" style="border-collapse: collapse;">
							<tbody>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">一、营业收入</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.income[i]"
											@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('income',i)?formatCurrency.formateNum(profit.income[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">减:营业成本</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.cost[i]"
											@blur="((ev)=>{blur(ev,profit,'cost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('cost',i)?formatCurrency.formateNum(profit.cost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">税金及附加</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.taxAdditional[i]"
											@blur="((ev)=>{blur(ev,profit,'taxAdditional',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('taxAdditional',i)?formatCurrency.formateNum(profit.taxAdditional[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">销售费用</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.saleCost[i]"
											@blur="((ev)=>{blur(ev,profit,'saleCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('saleCost',i)?formatCurrency.formateNum(profit.saleCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">管理费用</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.manageCost[i]"
											@blur="((ev)=>{blur(ev,profit,'manageCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('manageCost',i)?formatCurrency.formateNum(profit.manageCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">研发费用</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.researchCost[i]"
											@blur="((ev)=>{blur(ev,profit,'researchCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('researchCost',i)?formatCurrency.formateNum(profit.researchCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">财务费用</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financeCost[i]"
											@blur="((ev)=>{blur(ev,profit,'financeCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financeCost',i)?formatCurrency.formateNum(profit.financeCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其中:利息支出</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.interestCost[i]"
											@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('interestCost',i)?formatCurrency.formateNum(profit.interestCost[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">利息收入</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.interestIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'interestIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('interestIncome',i)?formatCurrency.formateNum(profit.interestIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">加:其他收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherBenefits[i]"
											@blur="((ev)=>{blur(ev,profit,'otherBenefits',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherBenefits',i)?formatCurrency.formateNum(profit.otherBenefits[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">投资收益(损失以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.investmentIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'investmentIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('investmentIncome',i)?formatCurrency.formateNum(profit.investmentIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">其中:对联营企业和合营企业的投资收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.enterpriseIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'enterpriseIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('enterpriseIncome',i)?formatCurrency.formateNum(profit.enterpriseIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsBenefits[i]"
											@blur="((ev)=>{blur(ev,profit,'financialAssetsBenefits',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financialAssetsBenefits',i)?formatCurrency.formateNum(profit.financialAssetsBenefits[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">净敞口套期收益（损失以"-"号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.netExposureHedgeIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'netExposureHedgeIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('netExposureHedgeIncome',i)?formatCurrency.formateNum(profit.netExposureHedgeIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">公允价值变动收益(损失以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.fairValueIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'fairValueIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('fairValueIncome',i)?formatCurrency.formateNum(profit.fairValueIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">信用减值损失(损失以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.creditImpairmentLoss[i]"
											@blur="((ev)=>{blur(ev,profit,'creditImpairmentLoss',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('creditImpairmentLoss',i)?formatCurrency.formateNum(profit.creditImpairmentLoss[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">资产减值损失(损失以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.assetLoss[i]"
											@blur="((ev)=>{blur(ev,profit,'assetLoss',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('assetLoss',i)?formatCurrency.formateNum(profit.assetLoss[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">资产处置收益（损失以“-”号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.assetDisposalIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'assetDisposalIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('assetDisposalIncome',i)?formatCurrency.formateNum(profit.assetDisposalIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">二、营业利润(亏损以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.businessProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('businessProfit',i)?formatCurrency.formateNum(profit.businessProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">加:营业外收入</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.outsideIncome[i]"
											@blur="((ev)=>{blur(ev,profit,'outsideIncome',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('outsideIncome',i)?formatCurrency.formateNum(profit.outsideIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">减:营业外支出</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.outsideExpenditure[i]"
											@blur="((ev)=>{blur(ev,profit,'outsideExpenditure',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('outsideExpenditure',i)?formatCurrency.formateNum(profit.outsideExpenditure[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">三、利润总额（亏损总额以“-”号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.totalProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalProfit',i)?formatCurrency.formateNum(profit.totalProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">减:所得税费用</td>
								</tr>
								<tr class="tipTerm">
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.incomeTax[i]"
											@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('incomeTax',i)?formatCurrency.formateNum(profit.incomeTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">四、净利润(净亏损以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.netProfit[i]" disabled>
										<span class="showInput">
											{{judgingCondition('netProfit',i)?formatCurrency.formateNum(profit.netProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(一)按经营持续性分类</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">1.持续经营净利润（净亏损以"-"号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.goingConcernNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'goingConcernNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('goingConcernNetProfit',i)?formatCurrency.formateNum(profit.goingConcernNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">2.终止经营净利润（净亏损以"-"号填列)</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid"
											v-model="profit.discontinuedOperationsNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'discontinuedOperationsNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('discontinuedOperationsNetProfit',i)?formatCurrency.formateNum(profit.discontinuedOperationsNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(二)按所有权归属分类</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">1.归属于母公司股东的净利润（净亏损以“"-"号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.parentCompanyNetProfit[i]"
											@blur="((ev)=>{blur(ev,profit,'parentCompanyNetProfit',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('parentCompanyNetProfit',i)?formatCurrency.formateNum(profit.parentCompanyNetProfit[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">2.少数股东损益（净亏损以“"-"号填列）</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockLegalRight[i]"
											@blur="((ev)=>{blur(ev,profit,'minorityStockLegalRight',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityStockLegalRight',i)?formatCurrency.formateNum(profit.minorityStockLegalRight[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">五、其他综合收益的税后净额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.ociNetTax[i]" disabled>
										<span class="showInput">
											{{judgingCondition('ociNetTax',i)?formatCurrency.formateNum(profit.ociNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(一)归属于母公司所有者的其他综合收益的税后净额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.parentCompanyOciNetTax[i]" disabled>
										<span class="showInput">
											{{judgingCondition('parentCompanyOciNetTax',i)?formatCurrency.formateNum(profit.parentCompanyOciNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">1.不能重分类进损益的其他综合收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.notReclassifiedOci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('notReclassifiedOci',i)?formatCurrency.formateNum(profit.notReclassifiedOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(1)重新计量设定受益计划变动额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.remeasureBenefitAmount[i]"
											@blur="((ev)=>{blur(ev,profit,'remeasureBenefitAmount',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('remeasureBenefitAmount',i)?formatCurrency.formateNum(profit.remeasureBenefitAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(2)权益法下不能转损益的其他综合收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.notTransferOci[i]"
											@blur="((ev)=>{blur(ev,profit,'notTransferOci',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('notTransferOci',i)?formatCurrency.formateNum(profit.notTransferOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(3)其他权益工具投资公允价值变动</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherEquityFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'otherEquityFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherEquityFairValue',i)?formatCurrency.formateNum(profit.otherEquityFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(4)企业自身信用风险公允价值变动</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid"
											v-model="profit.enterpriseCreditRiskFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'enterpriseCreditRiskFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('enterpriseCreditRiskFairValue',i)?formatCurrency.formateNum(profit.enterpriseCreditRiskFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">2.将重分类进损益的其他综合收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.reclassifiedOci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('reclassifiedOci',i)?formatCurrency.formateNum(profit.reclassifiedOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(1)权益法下可转损益的其他综合收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.transferOci[i]"
											@blur="((ev)=>{blur(ev,profit,'transferOci',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('transferOci',i)?formatCurrency.formateNum(profit.transferOci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(2)其他债权投资公允价值变动</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsFairValue[i]"
											@blur="((ev)=>{blur(ev,profit,'otherClaimsFairValue',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherClaimsFairValue',i)?formatCurrency.formateNum(profit.otherClaimsFairValue[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(3)金融资产重分类计入其他综合收益的金额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.financialAssetsOciAmount[i]"
											@blur="((ev)=>{blur(ev,profit,'financialAssetsOciAmount',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('financialAssetsOciAmount',i)?formatCurrency.formateNum(profit.financialAssetsOciAmount[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(4)其他债权投资信用减值准备</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.otherClaimsCreditImpairment[i]"
											@blur="((ev)=>{blur(ev,profit,'otherClaimsCreditImpairment',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('otherClaimsCreditImpairment',i)?formatCurrency.formateNum(profit.otherClaimsCreditImpairment[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(5)现金流量套期储备</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.cashFlowHedgingReserve[i]"
											@blur="((ev)=>{blur(ev,profit,'cashFlowHedgingReserve',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('cashFlowHedgingReserve',i)?formatCurrency.formateNum(profit.cashFlowHedgingReserve[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(6)外币财务报表折算差额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.foreignCurrencyBalance[i]"
											@blur="((ev)=>{blur(ev,profit,'foreignCurrencyBalance',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('foreignCurrencyBalance',i)?formatCurrency.formateNum(profit.foreignCurrencyBalance[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(二)归属于少数股东的其他综合收益的税后净额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.minorityStockOciNetTax[i]"
											@blur="((ev)=>{blur(ev,profit,'minorityStockOciNetTax',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('minorityStockOciNetTax',i)?formatCurrency.formateNum(profit.minorityStockOciNetTax[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">六、综合收益总额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.totalComprehensiveIncome[i]" disabled>
										<span class="showInput">
											{{judgingCondition('totalComprehensiveIncome',i)?formatCurrency.formateNum(profit.totalComprehensiveIncome[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(一)归属于母公司所有者的综合收益总额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.parentCompanyTci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('parentCompanyTci',i)?formatCurrency.formateNum(profit.parentCompanyTci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(二)归属于少数股东的综合收益总额</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" v-model="profit.minorityStockTci[i]" disabled>
										<span class="showInput">
											{{judgingCondition('minorityStockTci',i)?formatCurrency.formateNum(profit.minorityStockTci[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle courseBold">
									<td colspan="3">七、每股收益</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(一)基本每股收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.basicEps[i]"
											@blur="((ev)=>{blur(ev,profit,'basicEps',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('basicEps',i)?formatCurrency.formateNum(profit.basicEps[i],2):'-'}}
										</span>
									</td>
								</tr>
								<tr colspan="3" class="courseTitle">
									<td colspan="3">(二)稀释每股收益</td>
								</tr>
								<tr>
									<td v-for="(item, i) in yearList">
										<input type="number" data-type="number" ref="forbid" v-model="profit.dilutedEps[i]"
											@blur="((ev)=>{blur(ev,profit,'dilutedEps',i)})" @focus="focus($event)" />
										<span class="showInput" @click="inputFocus($event)">
											{{judgingCondition('dilutedEps',i)?formatCurrency.formateNum(profit.dilutedEps[i],2):'-'}}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>


				</div>
			</div>

			<div class="saveBox">
				<span class="last lastStep" id="last" @click="lastStep">上一步</span>
				<span class="save saveNext" id="saveButton" @click="saveButton">保 存</span>
			</div>
		</div>

		<Footer />
		<router-view />

		<Modal v-model="financialTips" class-name="vertical-center-modal financialTips" :mask-closable='false'>
			<p slot="header" class="examine-tip">
				<span>提示</span>
			</p>
			<img src="../../assets/img/remind-tip.png">
			<div>
				<p>请核对以下报表项目数据是否正确:</p>
				<p>营业收入、营业成本、税金及附加、销售费用、管理费用、财务费用、利息支出、利息收入、所得税费用</p>
			</div>
			<div slot="footer" class="financialBtn">
				<span @click="correction">返回修改</span>
				<span @click="sure">确认提交</span>
			</div>
		</Modal>

		<Modal v-model="dataDiscrepancy" class-name="vertical-center-modal" :mask-closable="false">
			<img class='warning' src="../../assets/img/remind-01@2x.png">
			<span>{{dataDiscrepancyMsg}}</span>
			<div slot="footer" class="ivu-sure" @click="determine">确定</div>
		</Modal>


		<!-- 缓存提示框 -->
		<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"
			@on-cancel="clickCacheMaskJump">
			<img class='warning-cache' src="../../assets/img/caution.png">
			<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
			<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
			<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
		</Modal>
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import financeNav from './financeNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				yearList: [],
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				id: '',
				lastProfit: '',
				url: '',
				financialTips: false,
				myAlert: false, //上传模板的弹框
				success: false, //上传模板成功的弹框
				error: false, //上传模板错误的弹框
				reportId: '',
				dataDiscrepancy: false,
				dataDiscrepancyMsg: '',
				isLoan: [], //当基准日资产负债表中短期借款或长期借款科目有数据时，利润表中财务费用-利息支出数据不可为零
				profit: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []
				},
				projectName: '',
				cacheAlert: false
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData,
			financeNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			if (this.$route.query.type == 5) {
				this.getTraceProfit()
			} else {
				this.getProfit()
			}

		},
		updated() {
			if (this.status == 1) {
				document.querySelector('.uploading').setAttribute("class", "forbidden"); //禁止上传模板
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "true";
						item.style.backgroundColor = '#fff';
						item.nextSibling.style.backgroundColor = '#fff';
						item.nextSibling.style.pointerEvents = 'none';
					})
				}
			} else {
				if (this.$refs.forbid) {
					this.$refs.forbid.forEach((item, i) => {
						item.disabled = "";
					})
				}
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：表的字段   name：具体的科目名称   num：科目的具体下标
				e.currentTarget.nextSibling.style.display = 'block';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');
				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.profit[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 15) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.profit[name], num, '')
						} else {
							this.$set(this.profit[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.profit[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 二、营业利润(亏损以"-"号填列)
					this.profit.businessProfit[i] = Number(this.profit.income[i]) - Number(this.profit.cost[i]) -
						Number(this.profit.taxAdditional[i]) - Number(this.profit.saleCost[i]) - Number(this.profit
							.manageCost[i]) - Number(this.profit.researchCost[i]) - Number(this.profit.financeCost[
							i]) + Number(this.profit.otherBenefits[i]) + Number(this.profit.investmentIncome[i]) +
						Number(this.profit.netExposureHedgeIncome[i]) + Number(this.profit.fairValueIncome[i]) +
						Number(this.profit.creditImpairmentLoss[i]) + Number(this.profit.assetLoss[i]) + Number(this
							.profit.assetDisposalIncome[i]);

					// 三、利润总额（亏损总额以“-”号填列)
					this.profit.totalProfit[i] = Number(this.profit.businessProfit[i]) + Number(this.profit
						.outsideIncome[i]) - Number(this.profit.outsideExpenditure[i]);

					// 四、净利润(净亏损以"-"号填列)
					this.profit.netProfit[i] = Number(this.profit.totalProfit[i]) - Number(this.profit.incomeTax[i]);

					// 1.不能重分类进损益的其他综合收益
					this.profit.notReclassifiedOci[i] = Number(this.profit.remeasureBenefitAmount[i]) + Number(this
						.profit.notTransferOci[i]) + Number(this.profit.otherEquityFairValue[i]) + Number(this
						.profit.enterpriseCreditRiskFairValue[i]);

					// 	// 2.将重分类进损益的其他综合收益
					this.profit.reclassifiedOci[i] = Number(this.profit.transferOci[i]) + Number(this.profit
							.otherClaimsFairValue[i]) + Number(this.profit.financialAssetsOciAmount[i]) + Number(this
							.profit.otherClaimsCreditImpairment[i]) + Number(this.profit.cashFlowHedgingReserve[i]) +
						Number(this.profit.foreignCurrencyBalance[i]);

					// (一)归属于母公司所有者的其他综合收益的税后净额
					this.profit.parentCompanyOciNetTax[i] = Number(this.profit.notReclassifiedOci[i]) +
						Number(this.profit.reclassifiedOci[i]);

					// 五、其他综合收益的税后净额
					this.profit.ociNetTax[i] = Number(this.profit.parentCompanyOciNetTax[i]) + Number(this.profit
						.minorityStockOciNetTax[i]);

					// 六、综合收益总额
					this.profit.totalComprehensiveIncome[i] = Number(this.profit.netProfit[i]) + Number(this.profit
						.ociNetTax[i]);

					// 	// (一)归属于母公司所有者的综合收益总额
					this.profit.parentCompanyTci[i] = Number(this.profit.parentCompanyNetProfit[i]);

					// 	// (二)归属于少数股东的综合收益总额
					this.profit.minorityStockTci[i] = Number(this.profit.minorityStockLegalRight[i]);

				}
				//调用缓存共用方法
				if (this.$route.query.type == 7 || this.$route.query.type == 2) {
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.contentProfit) || (this
							.$route.query.reportId && this.contentProfit && this.isCacheValue == '0')) {
						this.method.caching("profitInfo", JSON.stringify(this.profit), this.$route.query.type, this
							.$route.query.stage, this)
					}
				}
			},
			focus: function(e) {

			},
			inputFocus: function(e) {
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			lastStep: function() {
				console.log(this.$route.query.reportId)
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/balanceSheet',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/balanceSheet',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/balanceSheet',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			getProfit: function(close) {
				this.$Spin.show(); //全局加载中
				var this_ = this;
				var url = '';
				if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
					url = this.projectName + 'financeProfitInfo/qryProfitInfoV3';
				} else {
					url = this.projectName + 'financeProfitInfo/qryProfitInfo';
				}
				this.$post(url, qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.$route.query.type,
						versions: sessionStorage.versions,
						stage: this.$route.query.stage,
					}))
					.then((res) => {
						this.$Spin.hide();
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key
						if (this.$route.query.type != 7) {
							for (var i = 0; i < res.data.content.year.length; i++) {
								res.data.content.year[i] = res.data.content.year[i] + '度';
							}
						}

						if (res.data.content.isLoan) {
							this.isLoan = res.data.content.isLoan;
						}

						this.yearList = res.data.content.year;
						this.id = res.data.content.id;

						this.contentProfit = res.data.content.profit

						this.isCacheValue = res.data.content.isCache
						if (this.isCacheValue == '0' && close == '0') {
							this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this)
						}
						if (res.data.content.profit) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
								// 清除 0.00
								var dataKey = Object.keys(this.profit); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.profit[key].push("")
									})
								})

							} else { //正式数据
								var returnProfit = Object.keys(JSON.parse(res.data.content
									.profit)) //后台数据返回的利润表对象的 key
								profitKey.forEach((elem, index) => {
									returnProfit.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.profit[elem][y] = JSON.parse(res.data
													.content.profit)[elem][y];
											} else if (!this_.profit[elem]) {
												if (this_.yearList.length == 2) {
													this_.profit[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.profit[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
						} else {
							var dataKey = Object.keys(this.profit); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.profit[key].push("")
								})
							})
						}

					});

			
				// console.log(this.profit)


			},
			getTraceProfit: function() {
				var this_ = this;
				this.$post(this.projectName + 'financeProfitInfo/qryProfitInfo', qs.stringify({
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						console.log(res.data)
						for (var i = 0; i < res.data.content.financeProfitInfo.year.length; i++) {
							res.data.content.financeProfitInfo.year[i] = res.data.content
								.financeProfitInfo.year[i] + '度';
						}
						this.yearList = res.data.content.financeProfitInfo.year;
						this.status = res.data.content.traceRelation.status;
						this.id = res.data.content.financeProfitInfo.id;
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key

						if (res.data.content.financeProfitInfo.profit) {
							this.lastProfit = res.data.content.financeProfitInfo.lastProfit;
							var returnProfit = Object.keys(JSON.parse(res.data.content.financeProfitInfo
								.profit)) //后台数据返回的利润表对象的 key
							profitKey.forEach((elem, index) => {
								returnProfit.forEach((item, i) => {
									this_.yearList.forEach((yearItem, y) => {
										if (elem == item) {
											this_.profit[elem][y] = JSON.parse(res.data
												.content.financeProfitInfo.profit)[
												elem][y];
										} else if (!this_.profit[elem]) {
											if (this_.yearList.length == 2) {
												this_.profit[elem] = ['', '']
											} else if (this_.yearList.length == 3) {
												this_.profit[elem] = ['', '', '']
											}
										}
									})
								})
							})
						} else {
							var dataKey = Object.keys(this.profit); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.profit[key].push("")
								})
							})
						}
					});
			},
			determine: function() {
				this.dataDiscrepancy = false;
			},
			saveButton: function() {
				if (this.status == 1) {
					this.$router.push({
						path: '/majorIncomeAnalysis',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					if (this.$route.query.type == 2) {
						if (this.profit.interestCost[0] == 0 && this.isLoan[0] == true) {
							this.dataDiscrepancyMsg = this.yearList[0].substring(0, 4) + '年利息支出数据不可为零'
							this.dataDiscrepancy = true;
						} else if (this.profit.interestCost[1] == 0 && this.isLoan[1] == true) {
							this.dataDiscrepancyMsg = this.yearList[1].substring(0, 4) + '年利息支出数据不可为零'
							this.dataDiscrepancy = true;
						} else if (this.profit.interestCost[2] == 0 && this.isLoan[2] == true) {
							this.dataDiscrepancyMsg = this.yearList[2].substring(0, 4) + '年利息支出数据不可为零'
							this.dataDiscrepancy = true;
						} else {
							this.financialTips = true;
						}
					} else if (this.$route.query.type == 7) {
						if (this.profit.interestCost[0] == 0 && this.isLoan[0] == true) {
							this.dataDiscrepancyMsg = this.yearList[0].substring(0, 4) + '年利息支出数据不可为零'
							this.dataDiscrepancy = true;
						} else if (this.profit.interestCost[1] == 0 && this.isLoan[1] == true) {
							this.dataDiscrepancyMsg = this.yearList[1].substring(0, 4) + '年利息支出数据不可为零'
							this.dataDiscrepancy = true;
						} else if (!this.profit.income[1] && !this.profit.cost[1] && !this.profit.taxAdditional[1] && !this.profit
							.saleCost[1] && !this.profit.manageCost[1] && !this.profit.researchCost[1] && !this.profit.financeCost[
								1] && !this.profit.incomeTax[1] && !this.profit.businessProfit[1] && !this.profit.totalProfit[1] && !
							this.profit.netProfit[1]) {
							// 一、营业收入、减:营业成本、税金及附加、销售费用、管理费用、研发费用、财务费用、二、营业利润(亏损以"-"号填列)、三、利润总额（亏损总额以“-”号填列)、减:所得税费用、四、净利润(净亏损以"-"号填列) 数据不能同时为零
							
							this.dataDiscrepancyMsg = this.yearList[1].substring(0, 4) + '的数据不能全部为零'
							this.dataDiscrepancy = true;
						} else {
							this.financialTips = true;
						}

					} else {
						this.financialTips = true;
					}
				}
			},
			sure: function() {
				var this_ = this;
				var params = {
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					stage: this.$route.query.stage,
					id: this.id,
					profitInfo: JSON.stringify(this.profit),
					lastProfit: this.profit.netProfit[this.profit.netProfit.length - 1] //最后一年的净利润
				};

				if (this.$route.query.type == 5) {
					params.traceReportId = this.$route.query.traceReportId;
					params.year = this.$route.query.year;
					this.url = 'trace/financeProfitInfo/save';
				} else {
					params.reportType = this.$route.query.type;
					params.versions = sessionStorage.versions;
					if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
						this.url = this.projectName + 'financeProfitInfo/saveV3';
					} else {
						this.url = this.projectName + 'financeProfitInfo/save';
					}
				}

				this.$post(this.url, qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							this.financialTips = false;
							if (this.$route.query.type == 5) {
								setTimeout(function() {
									this_.$router.push({
										path: '/majorIncomeAnalysis',
										query: {
											stage: this_.$route.query.stage,
											type: this_.$route.query.type,
											reportId: this_.$route.query.reportId,
											traceReportId: this_.$route.query.traceReportId,
											year: this_.$route.query.year
										}
									})
								}, 500)
							} else {
								setTimeout(function() {
									this_.$router.push({
										path: this_.$route.query.type == 7 ? '/submitReport' : '/majorIncomeAnalysis',
										query: {
											stage: this_.$route.query.stage,
											type: this_.$route.query.type,
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)
							}
						}
					});
			},
			correction: function() {
				var tipTerm = document.querySelectorAll('.tipTerm input')
				var this_ = this;
				this.financialTips = false;
				tipTerm.forEach(function(item, i) {
					item.style.borderColor = 'red';
				})
				tipTerm[0].focus()
			},
			uploadInformation: function() {
				this.myAlert = true;
			},
			uploadFiles: function(e) {
				var formData = new FormData();
				formData.append("file", e.target.files[0]);
				formData.append('reportId', this.$route.query.reportId ? this.$route.query.reportId : '');
				formData.append("stage", sessionStorage.getItem("stage"));
				if (sessionStorage.reportType == 7) {
					formData.append("reportType", sessionStorage.getItem("reportType"));
				}
				formData.append("versions", sessionStorage.getItem("versions") ? sessionStorage.getItem("versions") :
					1);
				this.$post(this.projectName + 'upload/uploadFile', formData)
					.then((res) => {
						if (res.data.code == 200) {
							this.myAlert = false;
							this.reportId = res.data.content;
							if (res.data.content != "非法的excel" && res.data.content != "模板已更新，请重新下载最新模板再上传！") {
								this.success = true;
							} else if (res.data.content == "非法的excel") {
								this.error = true;
							}
						}
					})
			},
			submitDialog: function() {
				this.success = false;
				this.$router.push({
					path: '/profitStatement',
					query: {
						stage: sessionStorage.stage,
						type: sessionStorage.reportType,
						reportId: this.reportId
					}
				})
				this.$router.go(0)
			},
			wrongBtn: function() {
				this.error = false;
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getProfit();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type, this.$route.query.stage, this, 'getProfit')
			}
		}

	}
</script>

<style scoped>
	.mobileProfit {
		display: none;
	}

	.mobileProfit>ul li,
	.mobileProfit table td {
		font-size: 14PX;
	}

	.mobileProfit table {
		margin-top: 20px;
	}

	.mobileProfit .courseTitle td {
		/* width: 100%; */
		padding-left: 20px;
	}

	.mobileProfit .courseBold td {
		font-weight: bold;
	}

	.interestIncome,
	.financialAssetsBenefits {
		padding-left: 108px;
	}

	.table-container>table>tbody>tr>td:first-child {
		width: 420px;
	}

	@media screen and (max-width: 750px) {

		.table-container,
		.down-up-container {
			display: none;
		}

		.mobileProfit {
			display: block;
		}

		div.table-name>span {
			font-size: 16PX;
		}

		span.unit {
			font-size: 12PX;
		}

		.desc-container>span {
			width: 20px;
			height: 20px;
			background-size: 100%;
		}

		.desc-container .desc-main>p {
			font-size: 12PX;
		}

	}
</style>
