<template>
    <div>
        <div class="titleBox">
            <span>附件2</span>
            <div class="title">利润表</div>
            <span>单位：元</span>
        </div>
        <table class="table profitTableInfo" border="0" style="border-collapse: collapse;margin-bottom: 70px;" :class="versions == 1 ? 'active':''">
            <thead>
                <tr class="tableT">
                    <th style="width: 37%;">项目</th>
                    <th style="width: 21%;" v-for="(item,index) in year" :key="index">{{item + dateFormate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="bold">
                    <td>一、营业收入</td>
                    <td v-for="(item,index) in profitTableInfo.income" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">减：营业成本</td>
                    <td v-for="(item,index) in profitTableInfo.cost" :key="index + 2">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">税金及附加</td>
                    <td v-for="(item,index) in profitTableInfo.taxAdditional" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">销售费用</td>
                    <td v-for="(item,index) in profitTableInfo.saleCost" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">管理费用</td>
                    <td v-for="(item,index) in profitTableInfo.manageCost" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">研发费用</td>
                    <td v-for="(item,index) in (profitTableInfo.researchCost ? profitTableInfo.researchCost : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">财务费用</td>
                    <td v-for="(item,index) in profitTableInfo.financeCost" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">其中：利息支出</td>
                    <td v-for="(item,index) in profitTableInfo.interestCost" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="four">利息收入</td>
                    <td v-for="(item,index) in profitTableInfo.interestIncome" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">加：其他收益</td>
                    <td v-for="(item,index) in profitTableInfo.otherBenefits" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">投资收益（损失以“-”号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.investmentIncome" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">其中:对联营企业和合营企业的投资收益</td>
                    <td v-for="(item,index) in profitTableInfo.enterpriseIncome" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="four">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
                    <td v-for="(item,index) in (profitTableInfo.financialAssetsBenefits ? profitTableInfo.financialAssetsBenefits : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="trThree odd">
                    <td class="three">净敞口套期收益（损失以"-"号填列）</td>
                    <td v-for="(item,index) in (profitTableInfo.netExposureHedgeIncome ? profitTableInfo.netExposureHedgeIncome : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">公允价值变动收益（损失以“-”号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.fairValueIncome" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">信用减值损失(损失以"-"号填列)</td>
                    <td v-for="(item,index) in (profitTableInfo.creditImpairmentLoss ? profitTableInfo.creditImpairmentLoss : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <!--利润：调整：资产减值损失-->
                <tr>
                    <td class="three">资产减值损失(损失以"-"号填列)</td>
                    <td v-for="(item,index) in profitTableInfo.assetLoss" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">资产处置收益（损失以“-”号填列）</td>
                    <td v-for="(item,index) in (profitTableInfo.assetDisposalIncome ? profitTableInfo.assetDisposalIncome : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="bold">
                    <td>二、营业利润（亏损以“-”号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.businessProfit" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">加：营业外收入</td>
                    <td v-for="(item,index) in profitTableInfo.outsideIncome" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">减：营业外支出</td>
                    <td v-for="(item,index) in profitTableInfo.outsideExpenditure" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="bold">
                    <td>三、利润总额（亏损总额以“-”号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.totalProfit" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">减：所得税费用</td>
                    <td v-for="(item,index) in profitTableInfo.incomeTax" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="bold">
                    <td>四、净利润（净亏损以“-”号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.netProfit" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">(一)按经营持续性分类</td>
                    <td v-for="(item,index) in profitTableInfo.income" :key="index + 1"></td>
                </tr>
                <tr>
                    <td class="three">1.持续经营净利润（净亏损以"-"号填列）</td>
                    <td v-for="(item,index) in (profitTableInfo.goingConcernNetProfit ? profitTableInfo.goingConcernNetProfit : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="three">2.终止经营净利润（净亏损以"-"号填列）</td>
                    <td v-for="(item,index) in (profitTableInfo.discontinuedOperationsNetProfit ? profitTableInfo.discontinuedOperationsNetProfit : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td class="two">(二)按所有权归属分类</td>
                    <td v-for="(item,index) in profitTableInfo.income" :key="index + 1"></td>
                </tr>
                <tr class="parentCompanyNetProfit">
                    <td class="three">1.归属于母公司股东的净利润（净亏损以“"-"号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.parentCompanyNetProfit" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="minorityStockLegalRight" >
                    <td class="three">2.少数股东损益（净亏损以“"-"号填列）</td>
                    <td v-for="(item,index) in profitTableInfo.minorityStockLegalRight" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>

                <!--五：其他综合收益的税后净额-->
                <tr class="bold grey noInput ociNetTax">
                    <td>五、其他综合收益的税后净额</td>
                    <td v-for="(item,index) in (profitTableInfo.ociNetTax ? profitTableInfo.ociNetTax : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd grey noInput parentCompanyOciNetTax">
                    <td class="two">(一)归属于母公司所有者的其他综合收益的税后净额</td>
                    <td v-for="(item,index) in (profitTableInfo.parentCompanyOciNetTax ? profitTableInfo.parentCompanyOciNetTax : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd grey noInput notReclassifiedOci">
                    <td class="three">1.不能重分类进损益的其他综合收益</td>
                    <td v-for="(item,index) in (profitTableInfo.notReclassifiedOci ? profitTableInfo.notReclassifiedOci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(1)重新计量设定受益计划变动额</td>
                    <td v-for="(item,index) in (profitTableInfo.remeasureBenefitAmount ? profitTableInfo.remeasureBenefitAmount : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
            <tbody v-if="versions == 1">
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(2)权益法下不能转损益的其他综合收益</td>
                    <td v-for="(item,index) in profitTableInfo.notTransferOci" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(3)其他权益工具投资公允价值变动</td>
                    <td v-for="(item,index) in profitTableInfo.otherEquityFairValue" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(4)企业自身信用风险公允价值变动</td>
                    <td v-for="(item,index) in profitTableInfo.enterpriseCreditRiskFairValue" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd grey noInput reclassifiedOci">
                    <td class="three">2.将重分类进损益的其他综合收益</td>
                    <td v-for="(item,index) in profitTableInfo.reclassifiedOci" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'profitTableInfo', 'year', 'versions'
    ],
    data() {
        return {
            dateFormate: "度",
        }
    },
    mounted(){
        if(this.versions == 1){
            this.dateFormate = "";
        } else if(this.versions == 'trace'){
            this.dateFormate = "年度"
        }
    },
    methods: {

    }
})
</script>
<style scoped>
    .titleBox {
        margin: 130px 0 0 0;
        padding: 0px 110px;
        display: flex;
        justify-content: space-between;
    }
    .titleBox span {
        line-height: 100%;
        font-size: 20px;
    }
    
    .titleBox .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table {
        padding: 0 110px;
        width: calc(100% - 220px);
        text-align: center;
        border-collapse: collapse;
        margin: 10px auto 0;
        border: 1px solid #000;
    }
    table>tbody>tr>td:first-of-type {
        padding-left: 30px;
        text-align: left;
    }
    .title {
        margin: 0 !important;
    }
    table tr td,
    table tr th {
        border: 1px solid #333;
        height: auto;
    }
    table>tbody>tr:first-of-type>td {
        border-top: none;
    }
    table tr th {
        border-bottom-color:#4393ff;
    }
    table>thead>tr>th {
        font-size: 18px;
        color: #fff;
        line-height: 44px;
    }
    table.active>tbody>tr {
        height: 38px;
    }
</style>
