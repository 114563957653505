<template>
	<div id="policyService">
		<Header />
		<div style="padding-bottom:100px">
			<div class="banner">
				<p>政策服务</p>
				<p>汇聚精品内容，云集最新政策信息，关注专精特新企业成长</p>
			</div>
			<div class="content">
				<ul class="category">
					<li v-for="(item, index) in tab" :class="type == index+1 ?'active':''" @click="policy(item.type)">
						{{item.name}}
					</li>
				</ul>
				<ul class="policy_item">
					<li v-for="(item, index) in policyList" @click="details(item.id)">
						<img :src="item.imgPath?url+'flow/'+item.imgPath:require('../../assets/img/specialization/policy.png')"
							alt="政策服务">
						<div>
							<p>{{item.caseName}}</p>
							<p>{{item.caseSummary}}</p>
							<p>{{item.createTime}}</p>
						</div>
					</li>
				</ul>
				<!--分页-->
				<div class="pageBox">
					<Page :current="current" :total="total" @on-change="handleChange"></Page>
				</div>
			</div>
		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';
	import qs from 'qs';
	import axios from 'axios';
	import API_CONFIG from "../../../vue.config.js";
	export default {
		data() {
			return {
				url: '',
				policyList: [],
				tab: [{
					type: 1,
					name: '制度法规'
				}, {
					type: 2,
					name: '政策补贴'
				}],
				type: 1,
				current: 1,
				total: 0,
				pageSize: 5,
				projectName:''
			}
		},
		components: {
			Header,
			Footer
		},
		mounted() {
			this.projectName=this.domain.serviceTraining
			this.url = API_CONFIG.devServer.proxy['/api'].target;
			this.type=this.$route.query.serviceType;
			this.list(this.$route.query.current,this.$route.query.serviceType)
		},
		methods: {
			list: function(pageNum, type) {
				this.$post(this.projectName+'policyService/getPolicyService', qs.stringify({
						type: type, //1（制度法规）2（政策补贴）3（助企培训）
						pageNumSize: '5', //一页展示几条
						pageNum: pageNum //第几页
					}))
					.then((res) => {
						this.total = res.data.content.policyServicePageTotalPage * 10;
						this.policyList = res.data.content.policyServiceList;
					});
			},
			handleChange: function(page) {
				this.current = page;
				this.list(page, this.type)
			},
			policy: function(type) {
				this.type = type;
				this.current = 1;
				this.list(1, type)
			},
			details: function(id) {
				this.$router.push({
					path:'/policyDetails',
					query:{id:id,serviceType:this.type}
				})
			}
		}
	}
</script>

<style scoped>
	body {
		font-weight: 400;
	}

	.banner {
		min-width: 1200px;
		width: 100% !important;
		height: 400px;
		background: url(../../assets/img/specialization/policy_banner.jpg) center center no-repeat;
	}

	.banner p:first-child {
		font-size: 48px;
		font-weight: bold;
		color: #F0F2F5;
		text-align: center;
		padding: 138px 0 25px 0;
	}

	.banner p:last-child {
		font-size: 22px;
		color: #F0F2F5;
		text-align: center;
	}

	.category {
		width: 440px;
		overflow: hidden;
		margin: 0 auto;
		margin-top: 44px;
	}

	.category li {
		width: 200px;
		height: 48px;
		border-radius: 24px;
		text-align: center;
		float: left;
		border: 1px solid #282828;
		font-size: 16px;
		color: #282828;
		line-height: 48px;
		cursor: pointer;
		border-radius: 24px;
		-webkit-border-radius: 24px;
		-moz-border-radius: 24px;
		-ms-border-radius: 24px;
		-o-border-radius: 24px;
	}

	.category li:first-child {
		margin-right: 35px;
	}

	.category li.active {
		background: #0478FC;
		border: none;
		color: #fff;
	}

	.policy_item {
		width: 1200px;
		margin: 0 auto;
		margin-top: 42px;
	}

	.policy_item li {
		width: 100%;
		padding: 29px 0;
		overflow: hidden;
		border-bottom: 1px solid #D8D8D8;
		cursor: pointer;
	}

	.policy_item li img {
		width: 280px;
		height: 180px;
		margin-right: 21px;
		float: left;
	}

	.policy_item li div {
		width: 74%;
		height: 150px;
		padding: 17px 0 13px 0;
		float: left;
	}

	.policy_item li div p:first-child {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.policy_item li div p:nth-of-type(2) {
		width: 100%;
		font-size: 14px;
		color: #585858;
		line-height: 24px;
		margin-top: 31px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.policy_item li div p:nth-of-type(3) {
		font-size: 14px;
		color: #999999;
		margin-top: 27px;
	}

	.policyDetails {
		width: 1200px;
		margin: 0 auto;
	}

	.mainHeading {
		border-bottom: 1px solid #D8D8D8;
		padding-bottom: 25px;
	}

	.pageBox {
		text-align: center;
		margin-top: 40px;
	}
	@media screen and (max-width: 750px){
		#policyService{
			overflow: hidden;
		}
		.banner {
			min-width:auto;
			width: auto;
			height: 360px;
			background: url(../../assets/img/mobileClient/policy_banner.jpg) center center no-repeat;
			background-size:auto 100%;
		}
		.banner p:first-child {
			padding: 98px 0 25px 0;
		}
		.banner p:last-child{
			padding: 0 20px;
		}
		.policy_item{
			width: 94%;
			margin: 0 auto;
		}
		.policy_item li img{
			width: 37%;
			height: 150px;
		}
		.policy_item li div{
			width: 55%;
			padding: 0;
		}
		.policy_item li div p:nth-of-type(2),.policy_item li div p:nth-of-type(3){
			font-size: 14PX;
		}
		.policy_item li div p:nth-of-type(2){
			margin-top: 15px;
		}
	}
</style>
