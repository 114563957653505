import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store.js'
import iView from 'iview';
import 'iview/dist/styles/iview.css'; // 使用 CSS
import VueAwesomeSwiper from 'vue-awesome-swiper';
import PdfOnload from './utils/pdf'
import {
	post,
	get
} from './utils/http.js'
import preventReClick from './assets/js/preventReClick.js' //防多次点击，重复提交
import 'babel-polyfill'
import 'lib-flexible' //适配pc  移动端
import domain from './assets/js/domain.js'

import '@/assets/fonts/font.css'


// 配置全局，实现数字千分位格式
import formatCurrency from './assets/js/numberToCurrency.js'
Vue.prototype.formatCurrency = formatCurrency
Vue.prototype.PdfOnload = PdfOnload
Vue.prototype.domain=domain

import method from './assets/js/method.js'
Vue.prototype.method = method

Vue.use(VueAwesomeSwiper);
Vue.use(preventReClick);




// import './baidu_js_push.js'; 

// import MuseUI from 'muse-ui';
// import 'muse-ui/dist/muse-ui.css';
// Vue.use(MuseUI);





Vue.use(iView)



//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
		meta.content = to.meta.content;
		// console.log(meta,to.meta.content)
		head[0].appendChild(meta)
	}
	next()
})

router.afterEach((to, from, next) => { //控制路由跳转时滚动条在顶部
	window.scrollTo(0, 0);
});


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
