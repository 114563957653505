<template>
	<div v-if="dupontData">
		<div class="dupontTitle" :style="versions ? 'margin-top:35px;':''">
			<img src="../../assets/img/guzhi/dubangfenxi.png" alt="" class="icon">
			<h3>杜邦分析</h3>
		</div>
		<!--构成表-各种率-->
		<div class="return-on-assets">
			<p>单位：万元、%</p>
			<!--第一行 资产收益率-->
			<div class="box dubangNetAssetsRatio">
				<p>净资产收益率（ROE）</p>
				<p v-text="dubangNetAssetsRatio + '%'">XX%</p>
			</div>
			<div class="link-line first-link-line"></div>
			<div class="link-box first-link-box"></div>
			<!--第二行-->
			<div class="second-line">
				<div class="box totalNetProfitRatio" :style="totalNetProfitRatioStatus ? 'background:#f00;color:#fff;' : ''">
					<p>总资产净利率</p>
					<p v-text="totalNetProfitRatio + '%'">XX%</p>
					<span v-show="totalNetProfitRatioStatus">↓</span>
				</div>
				<span>X</span>
				<div class="box rightsMul" v-if="rightsMul" :style="rightsMulStatus ? 'background:#f00;color:#fff;' : ''">
					<p>权益乘数</p>
					<p v-text="Number(rightsMul).toFixed(2)">XX</p>
					<span v-show="rightsMulStatus">↓</span>
				</div>
			</div>
			<div class="link-line second-link-line"></div>
			<div class="link-box second-link-box"></div>
			<div class="link-line third-link-line"></div>
			<!--第三行-->
			<div class="third-line">
				<div class="box netProfitRatio" :style="netProfitRatioStatus ? 'background:#f00;color:#fff;' : ''">
					<p>净利润率</p>
					<p v-text="netProfitRatio + '%'">XX%</p>
					<span v-show="netProfitRatioStatus">↓</span>
				</div>
				<div class="box totalAssetsRatio" :style="totalAssetsRatioStatus ? 'background:#f00;color:#fff;' : ''">
					<p>总资产周转率</p>
					<p v-text="totalAssetsRatio + '%'">XX</p>
					<span v-show="totalAssetsRatioStatus">↓</span>
				</div>
				<div class="box liabilitiesRatio" :style="liabilitiesRatioStatus ? 'background:#f00;color:#fff;' : ''">
					<p>平均资产负债率</p>
					<p v-text="liabilitiesRatio + '%'">XX%</p>
					<span v-show="liabilitiesRatioStatus">↓</span>
				</div>
				<span>
					1&nbsp; ÷&nbsp; (&nbsp; 1&nbsp;-
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
				</span>
			</div>
			<div class="line-box-container">
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
			</div>
			<!--第四行-->
			<div class="fourth-line">
				<div>
					<div class="box netProfit">
						<p>净利润</p>
						<p v-text="netProfit.toFixed(2)">XX</p>
					</div>
					<span>÷</span>
					<div class="box income">
						<p>营业收入</p>
						<p v-text="income.toFixed(2)">XX</p>
					</div>
				</div>
				<div>
					<div class="box income">
						<p>营业收入</p>
						<p v-text="income.toFixed(2)">XX</p>
					</div>
					<span>÷</span>
					<div class="box avgTotalAssets">
						<p>平均资产总计</p>
						<p v-text="avgTotalAssets.toFixed(2)">XX</p>
					</div>
				</div>
				<div>
					<div class="box totalLiab">
						<p>平均负债合计</p>
						<p v-text="totalLiab.toFixed(2)">XX</p>
					</div>
					<span>÷</span>
					<div class="box avgTotalAssets">
						<p>平均资产总计</p>
						<p v-text="avgTotalAssets.toFixed(2)">XX</p>
					</div>
				</div>
			</div>
		</div>
		<!--杜邦分析解释-->
		<div class="dupon-explain">
			杜邦分析的核心是净资产收益率(ROE)，它反映了所有者投入资本的获利能力和企业 筹资、投资、资产运营等活动的效率。ROE 的高低取决于总资产利润率和权益总资产率的水平。主要因素有三个方面:销售净利率、总资产周转率和权益乘数。
		</div>
		<!--表格-->
		<table border="1" cellspacing="0" cellpadding="0">

			<tbody>
				<tr>
					<th>杜邦分析体系指标</th>
					<th v-text="year + '年'"></th>
					<th>行业平均</th>
				</tr>
				<tr>
					<td :style="netProfitRatioStatus?'color:#f00;':''">净利润率（%）</td>
					<td v-text="netProfitRatio"></td>
					<td v-text="dupontData.industryNetProfitRatio">Data</td>
				</tr>
				<tr>
					<td :style="totalAssetsRatioStatus?'color:#f00;':''">总资产周转率（%）</td>
					<td v-text="totalAssetsRatio">Data</td>
					<td v-text="dupontData.industryTotalAssetsRatio">Data</td>
				</tr>
				<tr>
					<td :style="rightsMulStatus?'color:#f00;':''">权益乘数</td>
					<td v-text="Number(rightsMul).toFixed(2)">Data</td>
					<td v-text="Number(dupontData.industryRightsMul).toFixed(2)">Data</td>
				</tr>
				<tr>
					<td :style="dubangNetAssetsRatioStatus?'color:#f00;':''">净资产收益率（%）</td>
					<td v-text="dubangNetAssetsRatio">Data</td>
					<td v-text="dupontData.industryNetAssetsRatio">Data</td>
				</tr>
			</tbody>
		</table>
		<!--净利润率、总资产周转率、权益乘数-->
		<div class="rate-container">
			<p>1. 净利润率</p>
			<span v-text="netProfitRatioDes">2017 年公司净利润率为 XX%，低于行业平均水平。</span>
			<span>净利润率可以直观的反映企业的盈利能力，提高净利润率能够提升净资产收益率，是公司最优运营模式。提高净利润率的途径有两种:提高公司议价权或降低成本费用。</span>
		</div>
		<div class="rate-container">
			<p>2. 总资产周转率</p>
			<span v-text="totalAssetsRatioDes">2017年公司总资产周转率为XX%， 高于行业平均水平。</span>
			<span>总资产周转率体现了公司对总资产的利用效率。在不能有效提高毛利率的情况下，企业可通过提高总资产周转速度实现较好的净资产收益率。</span>
		</div>
		<div class="rate-container">
			<p>3. 权益乘数</p>
			<span v-text="rightsMulDes">2017年公司权益乘数为XX，处于行业平均水平。</span>
			<span>权益乘数主要受资产负债率的影响。资产负债率越大，权益乘数越高，企业的杠杆利益越高，但同时也应考虑到相关风险因素，如：偿债风险、资金周转风险等。</span>
		</div>
	</div>
</template>
<script>
	export default ({
		props: [
			'dupontData', 'versions'
		],
		mounted() {
			this.initPage();
		},
		data() {
			return {
				// 净资产收益率
				dubangNetAssetsRatio: "",
				// 总资产净利率
				totalNetProfitRatio: "",
				// 权益乘数
				rightsMul: 0,
				// 净利润率
				netProfitRatio: "",
				// 总资产周转率
				totalAssetsRatio: "",
				// 平均资产负债率
				liabilitiesRatio: "",
				// 净利润
				netProfit: 0,
				// 营业收入
				income: 0,
				// 平均资产总计
				avgTotalAssets: 0,
				// 平均负债合计
				totalLiab: 0,
				year: "",
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				that.dubangNetAssetsRatio = that.ratioFormate(that.dupontData.dubangNetAssetsRatio);
				that.totalNetProfitRatio = that.ratioFormate(that.dupontData.totalNetProfitRatio);
				that.rightsMul = that.dupontData.rightsMul;
				that.netProfitRatio = that.ratioFormate(that.dupontData.netProfitRatio);
				that.totalAssetsRatio = that.ratioFormate(that.dupontData.totalAssetsRatio);
				that.liabilitiesRatio = that.ratioFormate(that.dupontData.liabilitiesRatio);
				that.netProfit = that.dupontData.netProfit;
				that.income = that.dupontData.income;
				that.avgTotalAssets = that.dupontData.avgTotalAssets;
				that.totalLiab = that.dupontData.totalLiab;
				that.year = that.dupontData.year;
			},
			ratioFormate: function(val) {
				return (Number(val) * 100).toFixed(2);
			}
		},
		computed: {
			// 权益乘数
			rightsMulStatus: function() {
				return (this.rightsMul - this.dupontData.industryRightsMul) / Math.abs(this.dupontData.industryRightsMul) < -
					0.6
			},
			// 总资产净利率
			totalNetProfitRatioStatus: function() {
				return (this.totalNetProfitRatio - this.dupontData.industryTotalNetProfitRatio) / Math.abs(this.dupontData
					.industryTotalNetProfitRatio) < -0.6
			},
			// 净利润率
			netProfitRatioStatus: function() {
				return (this.netProfitRatio - this.dupontData.industryNetProfitRatio) / Math.abs(this.dupontData
					.industryNetProfitRatio) < -0.6
			},
			// 总资产周转率
			totalAssetsRatioStatus: function() {
				return (this.totalAssetsRatio - this.dupontData.industryTotalAssetsRatio) / Math.abs(this.dupontData
					.industryTotalAssetsRatio) < -0.6
			},
			// 平均资产负债率
			liabilitiesRatioStatus: function() {
				return (this.liabilitiesRatio - this.dupontData.industryLiabilitiesRatio) / Math.abs(this.dupontData
					.industryLiabilitiesRatio) < -0.6
			},
			// 净资产收益率
			dubangNetAssetsRatioStatus: function() {
				return (this.dubangNetAssetsRatio - this.dupontData.industryNetAssetsRatio) / Math.abs(this.dupontData
					.industryNetAssetsRatio) < -0.6
			},
			// 净利润率语句
			netProfitRatioDes: function() {
				return this.year + '年公司净利润率为' + this.netProfitRatio + '%' + this.dupontData.netProfitDesc + '。'
			},
			// 总资产周转率语句
			totalAssetsRatioDes: function() {
				return this.year + '年公司总资产周转率为' + this.totalAssetsRatio + '%' + this.dupontData.totalAssetsDesc + '。'
			},
			// 权益乘数语句
			rightsMulDes: function() {
				return this.year + '年公司权益乘数为' + this.rightsMul.toFixed(2) + '%' + this.dupontData.rightsMulDesc + '。'
			},
		}

	})
</script>
<style scoped>
	table {
		width: 1009px;
		margin: 0 auto;
	}

	.dupontAnalyze {
		width: 100%;
		height: 1754px;
	}

	.dupontAnalyze img {
		float: left;
		margin: 0 26px 0 0;
	}

	.dupontAnalyze h3 {
		font-size: 30px;
		font-weight: bold;
		color: #121212;
		line-height: 48px;
	}

	.dupontTitle {
		margin: 115px 0 0 110px;
	}

	.dupontTitle .icon {
		width: 49px;
		height: 49px;
	}

	.dupontAnalyze table {
		font-size: 16px;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(2) {
		padding: 0 48px;
		border-color: #666;
		text-align: center;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(3) {
		padding: 0 48px;
		border-color: #666;
		text-align: center;
	}

	/* 杜邦分析 */
	.return-on-assets {
		position: relative;
		width: 1008px;
		margin: 0 auto;
		margin-top: 38px;
		margin-bottom: 40px;
	}

	.return-on-assets>p {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 16px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
	}

	.return-on-assets .box {
		width: 180px;
		height: 60px;
		background: #0478fc;
		/* border: 1px solid #666666; */
		overflow: hidden;
	}

	.return-on-assets .box {
		position: relative;
		box-sizing: border-box;
	}

	.return-on-assets .box>p {
		width: 100%;
		height: 16px;
		margin-top: 8px;
		font-size: 16px;
		text-align: center;
	}

	.return-on-assets .box>span {
		position: absolute;
		right: 5px;
		top: 7px;
		font-size: 30px;
		text-align: center;
		color: #fff;
	}

	.box.dubangNetAssetsRatio {
		margin-left: 440px;
		display: inline-table;
	}

	.second-line {
		width: 538px;
		margin: 0 auto;
		margin-left: 260px;
		overflow: hidden;
	}

	.second-line>.box {
		float: left;
		width: 146px;
		background: #76B0FF;
	}

	.second-line>span {
		width: 20px;
		margin-left: 112px;
		margin-top: 20px;
		text-align: center;
		vertical-align: middle;
	}

	.second-line>.box:last-child {
		float: right;
	}

	.third-line {
		position: relative;
		width: 810px;
		margin-left: 100px;
		overflow: hidden;
	}

	.third-line>.box {
		float: left;
		width: 146px;
		background: #A4CBFF;
	}

	.third-line>.box:first-of-type {
		margin-right: 170px;
	}

	.third-line>.box:last-of-type {
		margin-left: 174px;
	}

	.third-line>span {
		position: absolute;
		right: 0;
		top: 19px;
		font-size: 22px;
		color: #282828;
		line-height: 22px;
	}

	.fourth-line {
		margin-left: 32px;
		overflow: hidden;
	}

	.fourth-line>div {
		float: left;
		margin-right: 43px;
		overflow: hidden;
	}

	.fourth-line>div>.box {
		float: left;
		width: 120px;
		background: #E1EEFF;
	}

	.fourth-line>div>span {
		float: left;
		width: 24px;
		height: 30px;
		margin-left: 8px;
		margin-right: 8px;
		margin-top: 15px;
		font-size: 22px;
		text-align: center;
	}

	.link-line {
		width: 2px;
		background: #666666;
	}

	.first-link-line {
		height: 29px;
		margin-left: 526px;
	}

	.second-link-line {
		height: 29px;
		margin-left: 332px;
	}

	.third-link-line {
		height: 57px;
		margin-left: 766px;
		margin-top: -58px;
	}

	.line-box-container {
		width: 100%;
		height: 57px;
		margin-left: 90px;
		overflow: hidden;
	}

	.line-box-container>div {
		float: left;
	}

	.line-box-container>div:first-of-type {
		margin-right: 155px;
	}

	.line-box-container>div:last-of-type {
		margin-left: 166px;
	}

	.line-box-container>div>.link-line {
		height: 29px;
	}

	.fourth-link-line {
		margin-left: 82px;
	}

	.link-box {
		width: 160px;
		box-sizing: border-box;
		border: 2px solid rgba(102, 102, 102, 1);
		border-bottom: none;
	}

	.first-link-box {
		width: 397px;
		height: 38px;
		margin-left: 332px;
	}

	.second-link-box {
		width: 316px;
		height: 29px;
		margin-left: 173px;
	}

	.line-box-container>div>.link-box {
		width: 161px;
		height: 29px;
	}

	/*杜邦分析解释*/

	.dupon-explain {
		width: 1004px;
		padding: 25px 33px 25px 22px;
		background: rgba(247, 247, 247, 1);
		margin: 0 auto;
		margin-bottom: 36px;
		font-size: 16px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-indent: 2em;
	}

	.page-dupon table {
		border-collapse: collapse;
		border-color: #666;
		margin-bottom: 40px;
	}

	.page-dupon table>tbody>tr {
		border-color: #666;
		height: 40px;
	}

	.page-dupon table>tbody>tr>th {
		font-size: 16px;
		color: #282828;
		font-weight: bold;
	}

	.page-dupon table>tbody>tr>td {
		width: 33%;
		border-color: #666;
		text-align: center;
		font-size: 16px;
		color: #282828;
	}

	.page-dupon table>tbody>tr>td:nth-of-type(2) {
		border-color: #666;
		text-align: center;
	}

	.page-dupon table>tbody>tr>td:nth-of-type(3) {
		border-color: #666;
		text-align: center;
	}

	.page-dupon table>tbody>tr:nth-of-type(5)>td {
		font-weight: bold;
	}

	.rate-container {
		width: 1008px;
		margin: 0 auto;
		margin-top: 25px;
	}

	.rate-container>p {
		font-size: 20px;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
		line-height: 20px;
		margin-bottom: 16px;
	}

	.rate-container>span {
		padding: 0 24px;
		font-size: 18px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 34px;
		text-indent: 2em;
	}

	.dupontAnalyze table {
		width: 1008px;
		margin: 0 auto;
	}

	.dupontAnalyze table tr {
		height: 43px;
	}

	.dupontAnalyze table>tbody>tr {
		font-size: 16px;
	}

	.dupontAnalyze table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(2),
	.dupontAnalyze table>tbody>tr>td:nth-of-type(3) {
		padding-left: 48px;
		border-color: #666;
		text-align: center;
	}
</style>