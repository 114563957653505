<template>
	<div id="aboutUs">
		<Header />
		<div class="contactPage">
			<div class="banner">
				<p>关于我们</p>
				<p>为专精特新企业保“价”护航</p>
			</div>
			<div class="introduce">
				<p class="headline">什么是专精特新中小企业</p>
				<p class="subtitle">
					“专精特新”中小企业是指具有“专业化、精细化、特色化、新颖化”特征的中小企业，<br> 企业规模必须符合国家 《中小企业划型标准》（ 工信部联企业〔2011〕300号 ）的规定。
				</p>
				<ul>
					<li>
						<img src="../../assets/img/specialization/trophy-full.svg" alt="专业化">
						<p>专业化</p>
						<span>专注核心业务，拥有很高的专业化生产、服务和协作配套能力，为大企业和产业链提供零部件和配套产品及服务。</span>
					</li>
					<li>
						<img src="../../assets/img/specialization/zidianmuluguanli.svg" alt="精细化">
						<p>精细化</p>
						<span>精细化生产、精细化管理、精细化服务，以美誉度高、性价比好、品质精良的产品和服务在细分市场中占据优势。</span>
					</li>
					<li>
						<img src="../../assets/img/specialization/zuanshi.svg" alt="特色化">
						<p>特色化</p>
						<span>利用特色资源，弘扬传统技艺和地域文化，采用独特工艺、技术、配方或原料，研制生产具有地方或者企业特色的产品。</span>
					</li>
					<li>
						<img src="../../assets/img/specialization/huangguan.svg" alt="新颖化">
						<p>新颖化</p>
						<span>开展技术创新、管理创新和商业模式创新，培育新的增长点，形成新的竞争优势。</span>
					</li>
				</ul>
			</div>
			<div class="companyProfile">
				<div>
					<p class="caption">关于我们</p>
					<p>北京心流慧估科技有限公司（简称“心流”、“心流网”或“心流慧估”）成立于2016年12月，由来自美银美林、PwC、AIG、阿里巴巴和京东等国内外知名机构以及政府机关的精英人士共同设立。经过5年多技术攻关和实践，心流自主研发了首套国际领先的人工智能软件系统，用于企业的资本和股权运营。目前，心流已发展成为知名的企服科技公司和AIaaS厂商，服务的企业、政府、银行和园区等客户达10余万家，取得了良好的经济和社会效益。
					</p>
					<img src="../../assets/img/specialization/introduce.png" alt="关于我们">
				</div>
			</div>
			<div class="relation">
				<p class="caption">联系方式</p>
				<ul>
					<li>
						<p><img src="../../assets/img/specialization/dianhuatianchong.svg" alt="联系方式"></p>
						<p>
							<span>客服电话：17610076778</span>
							<span>微信号：flcccc888</span>
						</p>
					</li>
					<li>
						<p><img src="../../assets/img/specialization/zuobiao.svg" alt="联系方式"></p>
						<p>
							<span>北京市朝阳区酒仙桥路甲12号电子城科技大厦15层1505</span>
						</p>
					</li>
				</ul>
			</div>

		</div>

		<Footer />
		<router-view />
	</div>
</template>


<script>
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';
	export default {
		components: {
			Header,
			Footer
		}
	}
</script>

<style scoped>
	body {
		font-weight: 400;
	}

	.banner {
		min-width: 1200px;
		width: 100% !important;
		height: 400px;
		background: url(../../assets/img/specialization/us_banner.jpg) center center no-repeat;
	}

	.banner p:first-child {
		font-size: 48px;
		font-weight: bold;
		color: #F0F2F5;
		text-align: center;
		padding: 138px 0 25px 0;
	}

	.banner p:last-child {
		font-size: 22px;
		color: #F0F2F5;
		text-align: center;
	}

	.headline {
		font-size: 28px;
		font-weight: bold;
		color: #000;
		text-align: center;
		margin-top: 59px;
		margin-bottom: 20px;
	}

	.subtitle {
		font-size: 16px;
		color: #000;
		text-align: center;
		line-height: 30px;
	}

	.introduce ul {
		width: 1200px;
		margin: 0 auto;
		margin-top: 37px;
		overflow: hidden;
	}

	.introduce ul li {
		width: 212px;
		height: 210px;
		padding: 45px 29px;
		background: #F5F7FA;
		float: left;
		margin-right: 40px;
		box-sizing: content-box;
	}

	.introduce ul li:last-child {
		margin: 0;
	}

	.introduce ul li img {
		width: 50px;
		display: block;
		margin: 0 auto;
	}

	.introduce ul li p {
		font-size: 22px;
		font-weight: bold;
		color: #0478FC;
		line-height: 19px;
		text-align: center;
		margin: 25px 0 22px 0;
	}

	.introduce ul li span {
		font-size: 14px;
		color: #282828;
		line-height: 24px;
		text-align: justify;
	}

	.companyProfile {
		width: 1200px;
		height: 420px;
		margin: 92px auto;
		position: relative;
	}

	.caption {
		width: 116px;
		font-size: 28px;
		font-weight: bold;
		color: #000;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 6px solid #0478FC;
		text-align: left;
	}

	.companyProfile>div p:nth-of-type(2) {
		width: 797px;
		height: 111px;
		background: #FFFFFF;
		border: 1px solid #E6E8E9;
		font-size: 14px;
		color: #000000;
		line-height: 30px;
		padding: 44px 36px;
		margin-top: 39px;
		position: relative;
		z-index: 22;
		text-align: justify;
		box-sizing: content-box;
	}

	.companyProfile>div img {
		position: absolute;
		top: 2px;
		right: 0;
	}

	.relation {
		width: 1200px;
		margin: 42px auto;
	}

	.relation ul {
		overflow: hidden;
		margin-top: 33px;
	}

	.relation ul li {
		width: 580px;
		height: 89px;
		background: #FFFFFF;
		border: 1px solid #D8D8D8;
		overflow: hidden;
	}

	.relation ul li:first-child {
		float: left;
	}

	.relation ul li:last-child {
		float: right;
	}

	.relation ul li p {
		float: left;
	}

	.relation ul li p:first-child {
		width: 99px;
		height: 51px;
		margin-top: 11px;
		border-right: 1px solid #D8D8D8;
		padding-top: 19px;
		box-sizing: content-box;
	}

	.relation ul li img {
		width: 28px;
		display: block;
		margin: 0 auto;
	}

	.relation ul li p:last-child {
		line-height: 89px;
		font-size: 14px;
		color: #000000;
	}

	.relation ul li p:last-child span:first-child {
		margin: 0 50px 0 42px;
	}
	@media screen and (max-width: 750px){
		#aboutUs{
			overflow: hidden;
		}
		.banner {
			min-width:auto;
			width: auto;
			height: 360px;
			background: url(../../assets/img/mobileClient/us_banner.jpg) center center no-repeat;
			background-size:auto 100%;
		}
		.banner p:first-child {
			padding: 98px 0 25px 0;
		}
		.introduce,.companyProfile,.relation{
			width: 100%;
			height: auto;
			padding: 0 20px;
			margin:0 0 20px 0;
		}
		.subtitle{
			font-size: 16PX;
			line-height: 40px;
		}
		.introduce ul{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}
		.introduce ul li{
			width: 36%;
			height: 320px;
			float: none;
			margin-right: 30px;
			margin-bottom: 20px;
		}
		.introduce ul li:nth-child(2n+2){
			margin-right: 0;
		}
		.introduce ul li span{
			font-size: 14PX;
			line-height: 30px;
		}
		.companyProfile>div img{
			position: inherit;
			width: 100%;
		}
		.companyProfile>div p:nth-of-type(2){
			width: 92%;
			height: auto;
			line-height: 35px;
			font-size: 14PX;
			padding: 20px;
			position: inherit;
			margin:  44px auto;
		}
		.relation ul li,.relation ul li:last-child{
			width: 100%;
			float: none;
			margin-bottom: 15px;
		}
		.relation ul li p:first-child{
			width: 15%;
			margin-right: 10px;
		}
		.relation ul li p:last-child{
			width: 80%;
			font-size: 13PX;
			line-height: 30px;
			margin-top: 15px;
		}
		.relation ul li p:last-child span:first-child{
			margin: 0;
			width: 100%;
		}
		
	}
</style>
