<template>
    <div>
        <!-- 咨询服务 -->
        <Header />
        <div id="banner">
            <div class="content">
                <h2>专家咨询服务</h2>
                <p>为专精特新企业走进资本市场提供定制化解决方案</p>
            </div>
        </div>
        <div class="productLists">
            <div class="content">
                <div class="productItem itemL">
                    <img src="../../assets/img/specialization/standardFinancePic.png" alt="">
                    <div>
                        <h3>财务规范</h3>
                        <p>合规框架下的财务体系建设及流程优化</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>根据企业发展战略及业务实质设计企业财务管理体系；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>实现企业不同核算口径下的数据完整勾稽对应；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>在日常核算、预算管理等多方面提升财务管理的规范性，搭建完整的财务体系，使企业的财务合规性达到预定要求；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>为企业财务管理优化和人员能力提升提供包括架构重组、流程梳理、制度设计、人员专项培训在内的系统解决方案。</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="productItem itemR">
                    <img src="../../assets/img/specialization/financialAdsiorPic.png" alt="">
                    <div>
                        <h3>融资顾问</h3>
                        <p>融资方案总体规划及解决方案</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>立专项融资小组，全程配合企业融资；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>精心挑选适合的投资人；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>组织投资人对企业尽职调查，并对相关问题进行解答；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>协助企业与投资人和尽调机构进行谈判；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>协助企业安排签约、交割并协助其资金及时到位。</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="productItem itemL">
                    <img src="../../assets/img/specialization/prelistingTutoringPic.png" alt="">
                    <div>
                        <h3>上市辅导</h3>
                        <p>提供上市前辅导服务及相关资本运作服务</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>辅助企业依据相关法规建立符合上市管理规范要求的治理结构、规范运作，完善股权架构、实施股权激励；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>协助企业建立健全组织机构、财务会计制度、企业决策制度和内部控制制度，以及符合上市企业要求的信息披露制度，并保证持续有效运行；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>梳理企业商标、专利、土地和房屋等资产的法律权属处置是否妥善、产权关系是否明晰；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>规范企业与自身股东及其他关联方的关系，建立规范的关联交易决策制度。</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="productItem itemR">
                    <img src="../../assets/img/specialization/designOfQuityPic.png" alt="">
                    <div>
                        <h3>股权设计</h3>
                        <p>VIE结构搭建及拆分、股权结构与股权激励</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>创始阶段股权结构设计</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>发展阶段股权结构分析，问题解析及股权结构规划</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>员工激励计划方案设计与实施</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>融资时的股权结构设计</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>融资并购中的控制权保护</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="productItem itemL">
                    <img src="../../assets/img/specialization/valuationPic.png" alt="">
                    <div>
                        <h3>交易估值</h3>
                        <p>标的股权估值，交易合理定价</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>合理的未来财务预测、行业分析、可比企业分析；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>结合企业所处行业、历史数据、未来预测、可比企业进行估值；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>运用多种专业估值方法，综合进行企业价值评估；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>专业估值分析和建议。</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="productItem itemR">
                    <img src="../../assets/img/specialization/mergerAndAcquisitionPic.png" alt="">
                    <div>
                        <h3>并购重组</h3>
                        <p>提供并购重组业务全方位的资本运作咨询服务</p>
                        <ul>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>帮助企业设计合理、合规的并购交易方案；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>协助企业寻找适合的并购交易对象和机会；</p>
                            </li>
                            <li>
                                <img src="../../assets/img/specialization/duigou.svg" alt="">
                                <p>协助企业完成并购方的尽职调查、股权估值、交易路径规划及交割相关事项。</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <router-view/>
        <Footer /> 
    </div>
</template>
<script>
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';

export default {
    name:"consultationDes",
    components: {
        Header,
        Footer
    },
    data(){
        return {

        }
    },
    methods: {

    }
}
</script>
<style scoped>
#banner {
    width: 100%;
    height: 400px;
    background: url(../../assets/img/specialization/consultationServiceBanner.jpg)no-repeat center center;
    background-size: 100% 100%;
    margin: 0;
}
#banner h2 {
    font-size: 48px;
    font-weight: bold;
    color: #F0F2F5;
    text-align: center;
    line-height: 100%;
    margin:138px 0 25px 0;
}
#banner p {
    font-size: 22px;
    font-weight: 400;
    color: #F0F2F5;
}
.productItem {
    overflow: hidden;
    margin: 70px 0;
}
.productItem>img{
    width: 460px;
    height: 320px;
}
.itemL>img {
    float: right;
    margin: 0 55px 0 0;
}
.itemR>img {
    float: left;
    margin: 0 220px 0 0;
}
.itemR>div {
    float: left;
}
.productItem h3 {
    font-size: 28px;
    font-weight: bold;
    color: #0D1631;
    line-height: 100%;
    margin: 0 0 23px 0;
}
.productItem div>p {
    font-size: 16px;
    font-weight: 400;
    color: #282828;
    line-height: 100%;
    margin: 0 0 34px 0;
}
.productItem ul li {
    margin: 0 0 15px 0;
}
.productItem ul li img {
    width: 22px;
    height: 21px;
    margin: 0 14px 0 0;
    float: left;
}
.productItem ul li p {
    width: 480px;
    font-size: 14px;
    font-weight: 400;
    color: #2E1414;
    line-height: 21px;
    margin: 0 0 0 36px;
}
@media screen and (max-width:765px) {
    .content {
        width: 100%;
        padding: 0 20px;
    }
    .itemR>img,
    .itemL>img {
        margin: 0;
        float: inherit;
        width: 100%;
        height: auto;
        margin: 0 0 20px 0;
    }
}
</style>